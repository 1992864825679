import { useNavigate } from 'react-router-dom';
import { Drawer, DrawerContent, DrawerHeader, DrawerTitle } from '../ui/drawer';
import { Button } from '../ui/button';
import { X } from 'lucide-react';
import { cn } from '../../utils';
import { PurchaseItemData } from '@carsayo/types';
import { PurchaseType } from 'pages/v2/purchase';

export const ChargeInformationFee = ({
  isOpen,
  setIsOpen,
  orderType,
  purchaseType,
}: {
  isOpen: boolean;
  setIsOpen: (value: boolean) => void;
  orderType: 'purchasing' | 'selling';
  purchaseType?: PurchaseType;
}) => {
  const navigate = useNavigate();
  const targetItem = PurchaseItemData.find((el) => {
    return el.id === (orderType === 'purchasing' ? 1000100 : 1000200);
  });

  const handleCloseDrawer = () => {
    setIsOpen(false);
    navigate('/main');
  };
  const handlePurchaseClick = () => {
    setIsOpen(false);
    if (targetItem) {
      navigate(
        `/payment/${targetItem.id}${purchaseType && `?purchaseType=${purchaseType}`}`,
      );
    }
  };

  return (
    <Drawer open={isOpen}>
      <DrawerContent className='mx-auto flex max-h-[90vh] w-full max-w-sm flex-col'>
        <DrawerHeader className='relative'>
          <DrawerTitle className='text-left text-center text-lg font-semibold'>
            정보 이용료 결제
          </DrawerTitle>
          <Button
            onClick={handleCloseDrawer}
            className='absolute right-5 top-4 h-7 w-7 border-0 bg-transparent p-0 text-[#111111]'
          >
            <X className='h-7 w-7' />
          </Button>
        </DrawerHeader>

        <div className='px-4 pb-6'>
          <div className='px-4 py-6 border border-solid rounded-md border-[#E5E5EC]'>
            <h5 className='text-[1rem] font-semibold text-[#111111] pb-4'>
              견적 요청 안내 사항
            </h5>
            <div className='px-4 py-4 border-t border-solid border-[#F1F1F5]'>
              <ul className='flex flex-col gap-2 list-disc text-[0.938rem]'>
                <li>
                  무료 견적 요청은 신차 구매, 내 차 팔기 각 1건으로 제한됩니다.
                </li>
                <li>
                  2대 이상 견적 요청 시, 건당 1만원의 정보 이용료가 발생합니다.
                </li>
                <li>
                  최대 3대까지 견적요청이 가능하며{' '}
                  <em className='underline decoration-1 not-italic'>
                    카사요를 통해 계약을 진행한 경우 100% 환불해 드립니다.
                  </em>
                </li>
              </ul>
            </div>
            <div className='px-4 pt-4 border-t border-solid border-[#F1F1F5]'>
              <ul className='flex flex-col gap-2 list-disc text-[0.938rem] text-[#555]'>
                <li>
                  결제가 완료되면 추가 견적 신청이 가능하며{' '}
                  <span className='text-[#111111]'>
                    마이 페이지 &#62; 결제 내역 페이지에서 확인할 수 있습니다.
                  </span>
                </li>
                <li>
                  깨끗하고 투명한 자동차 문화를 위해 노력하는 카사요가
                  되겠습니다.
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className={cn('px-4 pb-8 grid grid-cols-2 bg-white')}>
          <Button
            variant='outline'
            className='rounded-r-none bg-white'
            onClick={handleCloseDrawer}
          >
            다음에 할게요
          </Button>
          <Button className='rounded-l-none' onClick={handlePurchaseClick}>
            {`${targetItem && targetItem.price.toLocaleString()}원 결제하기`}
          </Button>
        </div>
      </DrawerContent>
    </Drawer>
  );
};
