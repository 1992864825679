import { cn } from 'utils';
import React from 'react';
import { MainCardMenu } from '../ask';

interface AskMainCardLayoutInterface {
  bgColor: string;
  characterImg: string;
  redirectAction: () => void;
  title: string;
  description: string[];
  menuList: MainCardMenu[];
  listBg: string;
  rightPadding?: string;
  bottomPadding?: string;
}

const AskMainCardLayout = ({
  bgColor,
  characterImg,
  redirectAction,
  title,
  description,
  menuList,
  listBg,
  rightPadding,
  bottomPadding,
}: AskMainCardLayoutInterface) => {
  return (
    <div
      className={cn(
        `w-full min-h-[216px] rounded-[12px] py-[18px] px-[20px] min-w-[265px]`,
        bgColor ? `${bgColor}` : 'bg-white',
      )}
      onClick={redirectAction}
    >
      <div className='w-full flex gap-[6px] justify-start items-center mb-[20px]'>
        <h3 className='text-[20px] font-[600] text-white'>
          {title || '물어보기'}
        </h3>
        <span className=''>
          <img
            src='/assets/images/v2/arrowInCircle.svg'
            alt='원안의화살표이미지'
          />
        </span>
      </div>
      <p className='text-[16px] leading-[24px] min-h-[48px] text-white flex flex-col justify-start items-start mb-[14px]'>
        {description &&
          description.length > 0 &&
          description.map((item, index) => {
            return (
              <span key={`askCardDescription_${item}_${index}`}>{item}</span>
            );
          })}
      </p>
      <div className='w-full flex justify-between items-start relative'>
        <ul className='w-full flex flex-col justify-start items-start gap-[6px]'>
          {menuList &&
            menuList.length > 0 &&
            menuList.map((item, index) => {
              return (
                <li
                  key={`askMainCard_${item.text}_${index}`}
                  className={cn(
                    `${listBg}`,
                    'rounded-[4px] p-[6px] flex justify-start items-center gap-[6px]',
                  )}
                >
                  <img src={item.imgUrl} alt={`askMainCardImg_${item.text}`} />
                  <span className='text-[14px] leading-[14px] text-white'>
                    {item.text}
                  </span>
                </li>
              );
            })}
        </ul>
        <img
          src={characterImg}
          alt={`askMainCard_${title}_img`}
          className={`absolute bottom-[-24px] right-[-13px] ${rightPadding} ${bottomPadding}`}
        />
      </div>
    </div>
  );
};

export default AskMainCardLayout;
