import { CircularProgress } from '@mui/material';
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
const NotFoundPage: React.FC = () => {
  const navigate = useNavigate();
  console.info('에러 페이지 구성 필요합니다 !!!!!!!!!!!!!!!!!!!!!!!!');

  // 임시로 여기 들어오면 메인으로 보내버리는 중임
  // 추후 에러 페이지 구성 필요합니다 예쁘게
  useEffect(() => {
    navigate('/main');
  }, []);

  return (
    <div>
      <CircularProgress></CircularProgress>
    </div>
  );
};

export default NotFoundPage;
