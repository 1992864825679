/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from 'components/ui/accordion';
import {
  InquiryDetailInfo,
  InquiryIdDTO,
  InquirySearchList,
  InquiryShortInfo,
} from '@carsayo/types';
import { getInquiryDetail, getInquiryList } from 'apis/inquiry';
import { useEffect, useLayoutEffect, useState } from 'react';
import useSystemStore from 'store/useSystemStore';
import dayjs from 'dayjs';
import ReactHtmlParser from 'react-html-parser';
import { Button } from 'components/ui/button';

const MyInquiryList = () => {
  const { setTopTabbarState } = useSystemStore();
  /** 전체 문의 리스트 */
  const [inquiryList, setInquiryList] = useState<InquiryShortInfo[]>([]);
  /** 해당 페이지에 속하는 size만큼의 리스트 */
  const [pageData, setPageData] = useState<InquiryShortInfo[]>([]);
  /** 전체 리스트를 size만큼씩 잘라서 묶은 리스트 */
  const [chunkData, setChunkData] = useState<InquiryShortInfo[][]>([]);
  const [currentPage, setCurrentPage] = useState(1); // 현재 페이지
  const [inquiryDetailInfo, setInquiryDetailInfo] = useState<{
    [key: string]: InquiryDetailInfo;
  }>({});

  const chunkArray = (array: any[], size: number) => {
    const chunkedArray = [];
    for (let i = 0; i < array.length; i += size) {
      chunkedArray.push(array.slice(i, i + size));
    }
    return chunkedArray;
  };
  const handleVoidEvents = {
    getPostList: async () => {
      const searchedInquiryList: InquiryShortInfo[] = await getInquiryList();
      if (searchedInquiryList) {
        setInquiryList(searchedInquiryList);
        setChunkData(chunkArray(searchedInquiryList, 10));
        if (currentPage === 1) {
          setPageData(chunkArray(searchedInquiryList, 10)[0]);
        }
      } else {
        setInquiryList([]);
      }

      return;
    },
    getDetail: (id: string) => async () => {
      const reqData: InquiryIdDTO = {
        inquiryId: id,
      };
      const searchedDetail: InquiryDetailInfo = await getInquiryDetail(reqData);
      if (searchedDetail) {
        setInquiryDetailInfo({ ...inquiryDetailInfo, [id]: searchedDetail });
      } else {
        setInquiryDetailInfo({});
      }

      return;
    },
  };
  useLayoutEffect(() => {
    setTopTabbarState({
      state: 'fixed',
      mode: 'subpage',
      title: '1:1 문의',
      rightElement: 'home',
      leftElement: 'back',
    });
    handleVoidEvents.getPostList();
  }, []);

  useEffect(() => {
    if (inquiryList) {
      setPageData(chunkArray(inquiryList, 10)[currentPage - 1]);
    }
  }, [inquiryList]);
  return (
    <div className='px-4 pb-10'>
      {pageData && pageData.length > 0 ? (
        pageData.map((el, index) => {
          return (
            <Accordion
              key={`notice-search-${index}`}
              type='single'
              collapsible
              className='w-full'
            >
              <AccordionItem value={`inquiryList-${index}`}>
                <AccordionTrigger
                  className='flex items-center gap-4 border-b border-[#F1F1F5] py-4 text-left'
                  onClick={handleVoidEvents.getDetail(el.id)}
                >
                  <div>
                    <div className='w-full text-[13px] leading-[13px] font-[500]'>
                      {el.state === 'answered' ? (
                        <span className='text-[#7273F9]'>답변완료</span>
                      ) : (
                        <span className='text-[#555555]'>문의접수</span>
                      )}
                    </div>
                    <div className='mt-2 font-[500] text-[15px]'>
                      {el.title}
                    </div>
                    <div className='mt-2 text-[13px] leading-[13px] text-[#767676]'>
                      {`${dayjs(el.submitted_at).format('YYYY.MM.DD')} • ${dayjs(el.submitted_at).format('HH:mm')}`}
                    </div>
                  </div>
                </AccordionTrigger>
                <AccordionContent className='whitespace-pre-line py-5'>
                  <div className='py-[14px] bg-[#F7F7FB] rounded-[8px] overflow-hidden'>
                    {/* 문의내용 */}
                    <div className='px-4'>
                      <div className='w-full mb-[10px]'>
                        <span className='text-[12px] leading-[12px] font-[500] text-[#444444] py-[6px] px-[5px] rounded-[4px] bg-white inline-flex justify-center items-center'>
                          {inquiryDetailInfo[el.id]?.type ?? '타입 없음'}
                        </span>
                      </div>
                      <div className='w-full text-[14px] leading-[21px] font-[400] text-[#555555]'>
                        {ReactHtmlParser(
                          inquiryDetailInfo[el.id]?.content ?? '',
                        )}
                      </div>
                    </div>
                    {/* 답변내용 */}
                    {inquiryDetailInfo[el.id]?.state === 'answered' && (
                      <>
                        <div className='w-[calc(100%+4px)] h-[1.5px] mt-4'>
                          <img
                            src='/assets/images/v2/dashed-line.svg'
                            alt='대시 선 이미지'
                            className='w-full object-cover'
                          />
                        </div>
                        <div className='px-4 pt-4'>
                          <div className='w-full mb-[10px]'>
                            <span className='text-[12px] leading-[12px] font-[500] text-[#5455EA] py-[6px] px-[5px] rounded-[4px] bg-[#E6E6F6] inline-flex justify-center items-center'>
                              문의답변
                            </span>
                          </div>
                          <div className='w-full text-[14px] leading-[21px] font-[400] text-[#222222] mb-[10px]'>
                            {ReactHtmlParser(
                              inquiryDetailInfo[el.id]?.answerContent ?? '',
                            )}
                          </div>
                          <div className='text-[13px] leading-[13px] font-[400] text-[#555555]'>
                            {`${dayjs(inquiryDetailInfo[el.id]?.answered_at).format('YYYY.MM.DD')} • ${dayjs(inquiryDetailInfo[el.id]?.answered_at).format('HH:mm')}`}
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                </AccordionContent>
              </AccordionItem>
            </Accordion>
          );
        })
      ) : (
        <div className='font-base py-[60px] text-center font-semibold'>
          문의 내용이 없습니다.
        </div>
      )}

      {chunkData.length > currentPage && (
        <Button
          onClick={() => {
            setCurrentPage((prev) => prev + 1);
            const newArray = pageData.concat(chunkData[currentPage]);
            setPageData(newArray);
          }}
          variant='outline'
          className='mt-8'
        >
          더보기
        </Button>
      )}
    </div>
  );
};

export default MyInquiryList;
