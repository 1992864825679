/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { ChangeEvent, useEffect, useLayoutEffect, useRef } from 'react';
import { cn } from 'utils';
import { carGipsaMenuList, carGongMenuList } from '../data';
import { useState } from 'react';
import dayjs from 'dayjs';
import useSystemStore from 'store/useSystemStore';
import { useNavigate, useLocation } from 'react-router-dom';
import CargipsaMenuCard from './CargipsaMenu';
import { CustomerActionInterface } from '../ask';
import ChoiceConfirm from '../component/ChoiceConfirm';
import { CarsayoToast } from 'components/common/v2/CarsayoToast';
import InquiryCompleteCard from './inquiry/inquiryComplete';
import InsuranceMenuCard from './InsuranceMenu';
import useMemberStore from 'store/useMemberStore';
import AnswerBubble from '../component/AnswerBubble';
import EmergencyNoticeCard from './EmergencyNotice';
import InsuranceCompleteCard from './InsuranceComplete';
import EmergencyLinkCard from './EmergencyLink';
import AccidentCompleteCard from './accident/accidentComplete';
import CargongMenuCard from './CargongMenu';

const AskCargongMain = () => {
  const { setTopTabbarState, setIsBottomTabbar } = useSystemStore();
  const { loginMember, refreshLoginMember } = useMemberStore();
  const location = useLocation();
  const navigate = useNavigate();
  const nowDate = new Date();
  const nowTime = dayjs(nowDate).format('YYYY년 MM월 DD일');
  const [chatTimeLine, setChatTimeLine] = useState<React.ReactNode[]>([]);
  const [chatTimeLineTitle, setChatTimeLineTitle] = useState<string[]>(
    location.state?.key ? location.state.key : [],
  );
  const chatRef = useRef<HTMLDivElement>(null);
  const [customerAction, setCustomerAction] =
    useState<CustomerActionInterface | null>(null);
  const [isInquiryConfirmOpen, setInquiryConfirmOpen] = useState(false);
  const [insuranceDrawerOpen, setInsuranceDrawerOpen] = useState(false);
  // datepicker 관련
  const minDate = dayjs().subtract(20, 'year').startOf('day').toDate();
  const maxDate = dayjs().add(20, 'year').endOf('day').toDate();
  const [isDatePickerOpen, setIsDatePickerOpen] = useState(false);
  // 보험만기일 등록 관련
  const [isInsuranceCompany, setIsInsuranceCompany] = useState(false);
  const [insuranceDate, setInsuranceDate] = useState<Date>(new Date());

  const handleDateChange = (date: Date) => {
    setInsuranceDate(date);
  };

  const scrollToTop = () => {
    if (chatRef.current) {
      chatRef.current.scrollTo({
        top: 0,
        behavior: 'auto',
      });
    }
  };

  const actionHandlers: { [key: string]: () => void } = {
    callInquiry: () => {
      setInquiryConfirmOpen(!isInquiryConfirmOpen);
    },
    inquiryAddOpen: () => {
      navigate('/ask/theme/cargipsa/inquiry');
    },
    accidentOpen: () => {
      navigate('/ask/theme/cargipsa/accident');
    },
    accidentComplete: () => {
      setChatTimeLine((prevChatTimeLine) => [
        ...prevChatTimeLine,
        <AnswerBubble
          time={new Date()}
          bgColor='bg-[#3A5ECB]'
          title='사고접수'
        />,
        <AccidentCompleteCard
          customerAction={customerAction}
          setCustomerAction={setCustomerAction}
          data={location.state.data}
          time={location.state.time}
        />,
      ]);
    },
    inquiryComplete: () => {
      setChatTimeLine((prevChatTimeLine) => [
        ...prevChatTimeLine,
        <AnswerBubble
          time={new Date()}
          bgColor='bg-[#3A5ECB]'
          title='1:1문의'
        />,
        <InquiryCompleteCard
          customerAction={customerAction}
          setCustomerAction={setCustomerAction}
        />,
      ]);
    },
    insuranceAddOpen: () => {
      setInsuranceDrawerOpen(!insuranceDrawerOpen);
    },
    insuranceAddComplete: () => {
      setChatTimeLine((prevChatTimeLine) => [
        ...prevChatTimeLine,
        <InsuranceCompleteCard
          customerAction={customerAction}
          setCustomerAction={setCustomerAction}
        />,
      ]);
    },
    emergencyClick: () => {
      if (
        loginMember?.customerInfo?.registerCar.list &&
        loginMember?.customerInfo?.registerCar.list?.length > 0
      ) {
        setChatTimeLine((prevChatTimeLine) => [
          ...prevChatTimeLine,
          <>
            <AnswerBubble
              title='고장(긴급) 출동'
              bgColor='bg-[#3A5ECB]'
              time={new Date()}
            />
            <EmergencyLinkCard
              customerAction={customerAction}
              setCustomerAction={setCustomerAction}
              nowTime={new Date()}
              emergencyData={loginMember.customerInfo?.registerCar.list ?? []}
            />
          </>,
        ]);
      } else {
        setChatTimeLine((prevChatTimeLine) => [
          ...prevChatTimeLine,
          <>
            <AnswerBubble
              title='고장(긴급) 출동'
              bgColor='bg-[#3A5ECB]'
              time={new Date()}
            />
            <EmergencyNoticeCard nowTime={new Date()} />
            <InsuranceMenuCard
              customerAction={customerAction}
              setCustomerAction={setCustomerAction}
              nowTime={new Date()}
              addHello={true}
            />
          </>,
        ]);
      }
    },
    insuranceMenu: () => {
      setChatTimeLine((prevChatTimeLine) => [
        ...prevChatTimeLine,
        <>
          <AnswerBubble
            title='고장(긴급) 출동'
            bgColor='bg-[#3A5ECB]'
            time={new Date()}
          />
          <InsuranceMenuCard
            customerAction={customerAction}
            setCustomerAction={setCustomerAction}
            nowTime={new Date()}
            addHello={true}
          />
        </>,
      ]);
    },
    reset: () => {
      scrollToTop();
      setCustomerAction(null);
      setChatTimeLine([
        <CargipsaMenuCard
          customerAction={customerAction}
          setCustomerAction={setCustomerAction}
          nowTime={nowDate}
        />,
      ]);
    },
  };

  useLayoutEffect(() => {
    setTopTabbarState({
      state: 'fixed',
      className: 'fixed bg-transparent text-white z-[200]',
      mode: 'subpage',
      leftElement: (
        <div
          onClick={() => {
            navigate('/ask/main');
          }}
        >
          <img src='/assets/images/v2/arrow-left-white.svg' alt='arrow left' />
        </div>
      ),
      title: '카공이에게 물어보기',
    });
    setIsBottomTabbar(false);
    // 첫 선택 메뉴 chatTimeLine에 추가
    setChatTimeLine((prevChatTimeLine) => [
      ...prevChatTimeLine,
      <CargongMenuCard
        customerAction={customerAction}
        setCustomerAction={setCustomerAction}
        nowTime={nowDate}
      />,
    ]);

    return () => {
      setCustomerAction(null);
      setChatTimeLine([]);
    };
  }, []);

  useEffect(() => {
    if (chatTimeLine.length === 0 && chatTimeLineTitle.length > 0) {
      chatTimeLineTitle.map((item) => actionHandlers[item]());
    }
  }, [chatTimeLine, chatTimeLineTitle]);

  useEffect(() => {
    console.info(customerAction);
    if (customerAction?.id) {
      actionHandlers[customerAction?.id]();
    }
  }, [customerAction, setChatTimeLine]);

  useEffect(() => {
    if (chatRef.current && chatTimeLine.length > 1) {
      chatRef.current.scrollTo({
        top: chatRef.current.scrollTop + 150,
        behavior: 'smooth',
      });
    }
  }, [chatTimeLine]);

  return (
    <section className='w-full'>
      {/* 카공이 상단 영역 */}
      <div className='bg-gradient-to-r from-[#8B755E] to-[#605041] min-h-[200px] pt-[56px] flex justify-center items-end'>
        <div className='w-full flex justify-between items-center relative min-h-[120px]'>
          <ul className='relative z-10 w-[calc(100%-40px)] mx-auto  flex flex-col justify-start items-start gap-[6px]'>
            {carGongMenuList &&
              carGongMenuList.length > 0 &&
              carGongMenuList.map((item, index) => {
                return (
                  <li
                    key={`askMainCard_${item.text}_${index}`}
                    className={cn(
                      'bg-[#64503E] shadow-[0px_4px_16px_#0000001A] rounded-[4px] p-[6px] flex justify-start items-center gap-[6px]',
                    )}
                  >
                    <img
                      src={item.imgUrl}
                      alt={`askMainCardImg_${item.text}`}
                    />
                    <span className='text-[14px] leading-[14px] text-white'>
                      {item.text}
                    </span>
                  </li>
                );
              })}
          </ul>
          <img
            src={'/assets/images/v2/ask-carGong-main.svg'}
            alt={`ask-carGong-img`}
            className={`absolute bottom-0 right-0 z-0 h-[111px] object-contain`}
          />
        </div>
      </div>
      {/* 실제 카공이 컨텐츠 영역 */}
      <div
        className='w-full  pb-6 bg-[#E5DFD8] h-[calc(100vh-200px)] overflow-y-auto'
        ref={chatRef}
      >
        {/* 이 페이지에 들어온 시간 */}
        <div className='w-[calc(100%-40px)] mx-auto py-6 relative after:content-[""] after:absolute after:left-0 after:top-0 after:bottom-0 after:my-auto after:w-full after:h-[1px] after:bg-[#1111114D] after:z-0'>
          <span className='flex justify-center items-center mx-auto relative z-10 w-[131px] bg-[#E5DFD8] leading-[20px] text-[#111]'>
            {nowTime}
          </span>
        </div>
        {/* 채팅영역 */}
        <div className='w-[calc(100%-40px)] mx-auto flex flex-col justify-start items-start gap-[20px]'>
          {chatTimeLine &&
            chatTimeLine.length > 0 &&
            chatTimeLine.map((item) => {
              return item;
            })}
        </div>
      </div>
    </section>
  );
};

export default AskCargongMain;
