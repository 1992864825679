import AskCardLayout from '../component/AskCardLayout';

const EmergencyNoticeCard = ({ nowTime }: { nowTime: Date }) => {
  return (
    <div className='w-full flex flex-col justify-start items-start'>
      <AskCardLayout
        chatIcon='/assets/images/v2/chat-cargipsa-icon.svg'
        chatName='카집사'
        time={nowTime}
        title='고장(긴급) 출동 시 필요한 보험사 등록을 위해 카키를 연결해 드리겠습니다.'
      >
        <div className='w-full flex flex-col'>
          <p className='text-[15px] leading-[22.5px] text-[#555555] flex flex-col justify-start items-start'>
            <span>가입하신 보험사를 등록해 주시면 됩니다. </span>
            <span>미가입 상태이시면 카키의 도움을 받아보세요.</span>
          </p>
        </div>
      </AskCardLayout>
    </div>
  );
};
export default EmergencyNoticeCard;
