/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState } from 'react';
import { motion, useAnimation } from 'framer-motion';
import { cn } from 'utils';

interface BottomSheetInterface {
  children: React.ReactNode;
  title?: string;
  isOpen: boolean;
  setIsOpen: (state: boolean) => void;
  className?: string;
}
/** 바텀 시트를 사용하는 상위 요소에 해당 css 추가해야함
 * position: relative
 * h-[calc(100vh-56px)] : 보여질 화면 꽉차도록(예시는 header높이 제외)
 * overflow-hidden
 */
const BottomSheet = ({
  isOpen,
  setIsOpen,
  title,
  children,
  className,
}: BottomSheetInterface) => {
  const controls = useAnimation();
  const sheetVariants = {
    open: {
      y: 0,
      transition: { type: 'spring', stiffness: 300, damping: 30 },
    },
    closed: {
      y: title ? 130 : 180,
      transition: { type: 'spring', stiffness: 300, damping: 30 },
    },
  };

  const handleDragEnd = (
    event: any,
    info: { offset: { y: number }; delta: { y: number } },
  ) => {
    if (isOpen) {
      // 시트가 열려 있을 때: 아래로 드래그하면 닫고
      if (info.delta.y >= 0) {
        setIsOpen(false);
      } else {
        setIsOpen(true);
      }
    } else {
      // 시트가 닫혀 있을 때: 위로 드래그한 경우에만 열림
      if (info.delta.y <= 0) {
        setIsOpen(true);
      } else {
        setIsOpen(false);
      }
    }
  };

  return (
    <motion.div
      drag='y'
      className={cn(
        'absolute z-10 min-h-[200px] w-full items-start bottom-0 left-0 right-0 bg-white rounded-t-[16px] shadow-[0px_0px_15px_#1111111A]',
        className && className,
      )}
      initial='closed'
      transition={{ type: 'spring', stiffness: 300, damping: 30 }}
      animate={isOpen ? 'open' : 'closed'}
      variants={sheetVariants}
      dragConstraints={{ top: 0, bottom: title ? 140 : 180 }}
      dragElastic={0.2}
      onDragEnd={handleDragEnd}
    >
      <div
        className={`${title ? 'h-[60px]' : 'h-[40px]'} w-full flex flex-col justify-between items-center cursor-pointer py-2 gap-2`}
        onClick={() => {
          isOpen ? setIsOpen(false) : setIsOpen(true);
        }}
      >
        <div className='w-[50px] h-[5px] bg-[#E5E5EC] rounded-[100px]'></div>
        {title && (
          <div className='w-full text-left px-4 text-[16px] leading-[20.8px] font-[600]'>
            {title}
          </div>
        )}
      </div>
      {isOpen && <div className='w-full'>{children}</div>}
    </motion.div>
  );
};

export default BottomSheet;
