/* eslint-disable @typescript-eslint/no-unused-vars */
import { TermCategoryId } from '@carsayo/types';
import { updateMessageReceiveSetting, updateTerm } from 'apis/setting';
import { CarsayoToast } from 'components/common/v2/CarsayoToast';
import useMemberStore from 'store/useMemberStore';
import useSystemStore from 'store/useSystemStore';
import getTermToastMessage from 'utils/getTermToastMessage';

type Target = 'push' | 'sms' | 'email';
type TargetInfo = {
  termCategoryId: TermCategoryId;
  termTitle: string;
  setting: 'marketing_push' | 'marketing_email' | 'marketing_sms';
};
interface UpdateInfo {
  target: Target;
}

const TargetData: Record<Target, TargetInfo> = {
  push: {
    termCategoryId: 95100,
    termTitle: '광고성 앱 푸시 수신',
    setting: 'marketing_push',
  },
  sms: {
    termCategoryId: 95200,
    termTitle: '광고성 문자 메시지 수신',
    setting: 'marketing_sms',
  },
  email: {
    termCategoryId: 95300,
    termTitle: '광고성 이메일 수신',
    setting: 'marketing_email',
  },
} as const;

/** 광고성 푸시, 문지, 이메일 수신 동의/철회
 * @notice 광고성 메시지 수신 동의는 약관 동의가 선행되어야 합니다.
 * @description 현재 회원 상태에 따라 동의 상태를 변경합니다.
 * @case 수신 동의 시 약관에 동의시키며, 동의하였다는 알림을 출력합니다.
 * @case 수신 거절 시 약관 동의를 해제시키며, 동의 철회하였다는 알림을 출력합니다.
 */
const useUpdateMarketingAlert = () => {
  const { loginMember, setLoginMember } = useMemberStore();
  const { setLoadingDim } = useSystemStore();

  const updateMarketingAlert = async (params: UpdateInfo) => {
    const changeAgreeState =
      !loginMember?.setting[TargetData[params.target].setting];

    try {
      setLoadingDim(true);

      /** 약관 동의 내역 변경 */
      const data = await updateTerm({
        updateTerms: [
          {
            termCategoryId: TargetData[params.target].termCategoryId,
            isAgree: changeAgreeState,
          },
        ],
      });

      const updatedMember = await updateMessageReceiveSetting({
        [TargetData[params.target].setting]: changeAgreeState,
      });

      if (
        data.success.filter((el) => {
          return (
            el.termCategoryId === TargetData[params.target].termCategoryId &&
            el.isAgree === changeAgreeState
          );
        }).length > 0
      ) {
        CarsayoToast.success(
          getTermToastMessage({
            state: changeAgreeState,
            termTitle: TargetData[params.target].termTitle,
          }),
        );
      }

      setLoginMember(updatedMember);
      setLoadingDim(false);
    } catch (e) {
      setLoadingDim(false);
    }

    return;
  };

  return { updateMarketingAlert } as const;
};

export default useUpdateMarketingAlert;
