import classNames from 'classnames';
import { InputHTMLAttributes, useId } from 'react';

interface CarsayoRadioInterface
  extends Omit<
    InputHTMLAttributes<HTMLInputElement>,
    'type' | 'className' | 'id' | 'checked'
  > {
  label: string;
  checked: boolean;
  className?: string;
}

const CarsayoCheckRadio = ({
  label,
  checked,
  className,
  ...rest
}: CarsayoRadioInterface) => {
  const id = useId();
  return (
    <label
      className='flex items-center flex-row gap-2 text-[16px] leading-[16px] font-[400] text-[#111]'
      htmlFor={id}
    >
      <input
        id={id}
        type='radio'
        name='progressRadio'
        checked={checked}
        className={classNames(
          className && className,
          `appearance-none hover:cursor-pointer w-[22px] h-[22px] shrink-0 !border-[#E5E5EC] rounded-full !text-[10px] text-[#fff] focus:ring-transparent disabled:opacity-50 disabled:pointer-events-none`,
          'checked:!bg-radio-check-active checked:!bg-[length:22px_22px]',
          '!bg-radio-check-default !bg-[length:22px_22px] !ml-0',
        )}
        {...rest}
      />
      {label}
    </label>
  );
};

export default CarsayoCheckRadio;
