import { Button } from 'components/ui/button';
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from '../ui/dialog';
import { ChoiceListInterface } from 'pages/v2/ask/component/ChoiceConfirm';

type Props = {
  title: string;
  desc: string;
  isOpen: boolean;
  isCloseButton?: boolean;
  setIsOpen: (isOpen: boolean) => void;
  onConfirm?: () => void;
  confirmText?: string;
  cancelText?: string;
  isChoice?: boolean;
  choiceList?: ChoiceListInterface[];
  align?: 'center' | 'left';
};

export default function Confirm({
  title,
  desc,
  isOpen,
  isCloseButton,
  setIsOpen,
  onConfirm,
  confirmText,
  cancelText,
  isChoice,
  choiceList,
  align,
}: Props) {
  return (
    <Dialog open={isOpen} onOpenChange={(value) => setIsOpen(value)}>
      <DialogContent className='max-w-[311px] gap-0 border-none p-0'>
        <DialogHeader className='px-2 flex min-h-[164px] flex-col items-center justify-center space-y-2 text-center'>
          <DialogTitle
            className={`w-full text-sm font-semibold ${align === 'left' ? 'pl-[18px]' : 'text-center'}`}
          >
            {title}
          </DialogTitle>
          {desc.length > 0 && (
            <div className='text-center text-[#767676] whitespace-pre'>
              {desc}
            </div>
          )}
          {choiceList && choiceList.length > 0 && (
            <ul className='w-full flex flex-col justify-start items-start px-[8px]'>
              {choiceList.map((item, index) => {
                return (
                  <li key={`${item.text}_${index}`} className='p-[12px]'>
                    <button onClick={item.action}> {item.text}</button>
                  </li>
                );
              })}
            </ul>
          )}
        </DialogHeader>
        <DialogFooter className='h-[48px]'>
          {isCloseButton && (
            <Button
              className={`h-[48px] rounded-none border-none ${isChoice ? 'bg-[#111111] text-[#fff]' : 'bg-[#F7F7FB] text-[#111111]'} `}
              onClick={() => setIsOpen(false)}
            >
              {cancelText ?? '취소'}
            </Button>
          )}
          {!isChoice && (
            <Button
              className='h-[48px] rounded-none border-none bg-[#111111]'
              onClick={() => {
                setIsOpen(false);
                if (onConfirm) onConfirm();
              }}
            >
              {confirmText ?? '확인'}
            </Button>
          )}
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
}
