import { create } from 'zustand';
import { devtools, persist } from 'zustand/middleware';
import { immer } from 'zustand/middleware/immer';

import { InsuranceCompany } from '@carsayo/types';

export interface InsuranceOriginData {
  originInsuranceData: InsuranceCompany[];
}
export interface InsuranceOriginDataAction {
  setOriginInsuranceData: (data: InsuranceCompany[]) => void;
}
const useInsuranceOriginDataStore = create<
  InsuranceOriginDataAction & InsuranceOriginData
>()(
  devtools(
    persist(
      immer((set, get) => ({
        originInsuranceData: [],
        setOriginInsuranceData: (data: InsuranceCompany[]) => {
          set((state) => {
            state.originInsuranceData = data;
          });
        },
      })),
      {
        name: 'insuranceDataStore',
      },
    ),
  ),
);

export default useInsuranceOriginDataStore;
