/* eslint-disable @typescript-eslint/no-unused-vars */
import * as React from 'react';
import * as SelectPrimitive from '@radix-ui/react-select';
import { ChevronDown, ChevronUp } from 'lucide-react';

import { cn } from 'utils';
import { RegisterCar } from '@carsayo/types';

const CarsayoSelectBox = ({
  value,
  onValueChange,
  disabled,
  fullData,
  selectedData,
}: {
  value?: string | undefined;
  onValueChange?: (value: string) => void | Promise<void>;
  placeholder?: string;
  disabled?: boolean;
  fullData: RegisterCar[];
  selectedData: RegisterCar;
}) => {
  const [isOpen, setIsOpen] = React.useState(false);
  /** select 정의 */
  const Select = SelectPrimitive.Root;
  const SelectValue = SelectPrimitive.Value;
  const SelectTrigger = React.forwardRef<
    React.ElementRef<typeof SelectPrimitive.Trigger>,
    React.ComponentPropsWithoutRef<typeof SelectPrimitive.Trigger>
  >(({ className, children, ...props }, ref) => {
    return (
      <SelectPrimitive.Trigger
        ref={ref}
        className={cn(
          'relative flex min-h-[54px] w-full items-center justify-between border border-input bg-background px-[18px] py-4 text-sm ring-offset-background placeholder:text-muted-foreground focus:border-[#111111] focus:outline-none disabled:cursor-not-allowed disabled:opacity-50 [&>span]:line-clamp-1',
          className,
        )}
        {...props}
      >
        {children}
        <SelectPrimitive.Icon
          asChild
          className='absolute right-[10px] top-0 bottom-0 my-auto'
        >
          <img src='/assets/images/v2/chevronBottom.svg' alt='' />
        </SelectPrimitive.Icon>
      </SelectPrimitive.Trigger>
    );
  });
  SelectTrigger.displayName = SelectPrimitive.Trigger.displayName;

  const SelectScrollUpButton = React.forwardRef<
    React.ElementRef<typeof SelectPrimitive.ScrollUpButton>,
    React.ComponentPropsWithoutRef<typeof SelectPrimitive.ScrollUpButton>
  >(({ className, ...props }, ref) => (
    <SelectPrimitive.ScrollUpButton
      ref={ref}
      className={cn(
        'flex cursor-default items-center justify-center py-1',
        className,
      )}
      {...props}
    >
      <ChevronUp className='h-4 w-4' />
    </SelectPrimitive.ScrollUpButton>
  ));
  SelectScrollUpButton.displayName = SelectPrimitive.ScrollUpButton.displayName;

  const SelectScrollDownButton = React.forwardRef<
    React.ElementRef<typeof SelectPrimitive.ScrollDownButton>,
    React.ComponentPropsWithoutRef<typeof SelectPrimitive.ScrollDownButton>
  >(({ className, ...props }, ref) => (
    <SelectPrimitive.ScrollDownButton
      ref={ref}
      className={cn(
        'flex cursor-default items-center justify-center py-1',
        className,
      )}
      {...props}
    >
      <ChevronDown className='h-4 w-4' />
    </SelectPrimitive.ScrollDownButton>
  ));
  SelectScrollDownButton.displayName =
    SelectPrimitive.ScrollDownButton.displayName;

  const SelectContent = React.forwardRef<
    React.ElementRef<typeof SelectPrimitive.Content>,
    React.ComponentPropsWithoutRef<typeof SelectPrimitive.Content>
  >(({ className, children, position = 'popper', ...props }, ref) => (
    <SelectPrimitive.Portal>
      <SelectPrimitive.Content
        ref={ref}
        className={cn(
          'relative z-50 max-h-96 min-w-[8rem] overflow-hidden border border-[#111111] bg-popover text-popover-foreground data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0 data-[state=closed]:zoom-out-95 data-[state=open]:zoom-in-95 data-[side=left]:slide-in-from-right-2 data-[side=right]:slide-in-from-left-2 data-[side=top]:slide-in-from-bottom-2',
          position === 'popper' &&
            'data-[side=left]:-translate-x-1 data-[side=right]:translate-x-1 data-[side=top]:-translate-y-1',
          className,
        )}
        position={position}
        {...props}
      >
        <SelectScrollUpButton />
        <SelectPrimitive.Viewport
          className={cn(
            position === 'popper' &&
              'h-[var(--radix-select-trigger-height)] w-full min-w-[var(--radix-select-trigger-width)]',
          )}
        >
          {children}
        </SelectPrimitive.Viewport>
        <SelectScrollDownButton />
      </SelectPrimitive.Content>
    </SelectPrimitive.Portal>
  ));
  SelectContent.displayName = SelectPrimitive.Content.displayName;

  const SelectLabel = React.forwardRef<
    React.ElementRef<typeof SelectPrimitive.Label>,
    React.ComponentPropsWithoutRef<typeof SelectPrimitive.Label>
  >(({ className, ...props }, ref) => (
    <SelectPrimitive.Label
      ref={ref}
      className={cn('py-1.5 pl-8 pr-2 text-sm font-semibold', className)}
      {...props}
    />
  ));
  SelectLabel.displayName = SelectPrimitive.Label.displayName;

  const SelectItem = React.forwardRef<
    React.ElementRef<typeof SelectPrimitive.Item>,
    React.ComponentPropsWithoutRef<typeof SelectPrimitive.Item>
  >(({ className, children, ...props }, ref) => (
    <SelectPrimitive.Item
      ref={ref}
      className={cn(
        'relative flex min-h-[54px] w-full cursor-default select-none items-center rounded-sm p-4 text-[16px] leading-[16px] outline-none focus:font-semibold focus:text-accent-foreground data-[disabled]:pointer-events-none data-[disabled]:opacity-50',
        className,
      )}
      {...props}
    >
      <SelectPrimitive.ItemText>{children}</SelectPrimitive.ItemText>
      <span className='flex justify-start items-center ml-[8px]'>
        <SelectPrimitive.ItemIndicator></SelectPrimitive.ItemIndicator>
      </span>
    </SelectPrimitive.Item>
  ));
  SelectItem.displayName = SelectPrimitive.Item.displayName;

  const SelectSeparator = React.forwardRef<
    React.ElementRef<typeof SelectPrimitive.Separator>,
    React.ComponentPropsWithoutRef<typeof SelectPrimitive.Separator>
  >(({ className, ...props }, ref) => (
    <SelectPrimitive.Separator
      ref={ref}
      className={cn('-mx-1 my-1 h-px bg-muted', className)}
      {...props}
    />
  ));
  SelectSeparator.displayName = SelectPrimitive.Separator.displayName;
  /** // select 정의 */
  return (
    <Select
      open={isOpen}
      onOpenChange={(open) => {
        setTimeout(() => {
          setIsOpen(open);
        }, 50);
      }}
      value={value}
      onValueChange={onValueChange}
    >
      <SelectTrigger
        className={`w-full bg-[#F6F6FC] ${isOpen ? 'border-[#333]' : 'border-[#E5E5EC]'} border ${isOpen ? 'rounded-t-[10px]' : 'rounded-[10px]'} flex justify-start items-center gap-[10px] text-[22px] font-[600] leading-[22px]`}
        disabled={disabled}
        onPointerDown={(e) => e.preventDefault()}
        onClick={() =>
          setIsOpen((prev) => {
            return !prev;
          })
        }
      >
        <SelectValue placeholder={value} />
      </SelectTrigger>
      <SelectContent className='w-[calc(100%-2px)] rounded-b-[10px] border-t-0'>
        {fullData &&
          fullData.length > 0 &&
          fullData.map((item, index) => {
            return (
              <SelectItem
                key={`select_${item}_${index}`}
                value={item.carName}
                className='flex justify-start items-center relative'
              >
                <span>{item.carName}</span>
                {item.isRepresentative && (
                  <span className='absolute bottom-0 top-0 h-[22px] text-[#5455EA] bg-[#E6E6F6] my-auto py-[4px] px-[6px] rounded-[4px] text-[14px] leading-[14px] ml-2'>
                    대표
                  </span>
                )}
              </SelectItem>
            );
          })}
      </SelectContent>
    </Select>
  );
};

export default CarsayoSelectBox;
