/* eslint-disable @typescript-eslint/no-unused-vars */
import { useEffect, useLayoutEffect, useState } from 'react';
import useMemberStore from 'store/useMemberStore';
import useSystemStore from 'store/useSystemStore';
import CustomerMainPage from './customer';
import DealerMainPage from './dealer';
import { isLogin } from 'apis/authentication';
import { Outlet, useNavigate } from 'react-router-dom';

export default function MainPage() {
  const { setIsBottomTabbar, setTopTabbarState } = useSystemStore();
  const navigate = useNavigate();

  const { loginMember } = useMemberStore();

  const handleVoidEvents = {
    checkLogin: async () => {
      if (!loginMember) {
        const {
          data: { isLogined },
        } = await isLogin();
        if (!isLogined) {
          navigate('/auth/signup', { replace: true });
          return;
        }
      }
    },
  };

  useEffect(() => {
    if (!loginMember?.type) return;

    if (loginMember.type === 'dealer') {
      setTopTabbarState({
        mode: 'mainType_2',
        state: 'fixed',
      });
    } else {
      setTopTabbarState({
        mode: 'main',
        state: 'fixed',
      });
    }
  }, [loginMember?.type]);

  useLayoutEffect(() => {
    setIsBottomTabbar(true);
    handleVoidEvents.checkLogin();
  }, []);

  if (!loginMember) return <></>;

  return loginMember.type === 'customer' ? (
    <CustomerMainPage />
  ) : (
    <DealerMainPage />
  );
}
