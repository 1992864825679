/* eslint-disable @typescript-eslint/no-unused-vars */
import BottomSheet from 'components/common/v2/BottomSheet';
import useKakaoMap from 'hooks/useKakaoMap';
import { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import useCurrentLocationStore from 'store/useCurrentLocation';
import useSystemStore from 'store/useSystemStore';

const LocationSettingPage = () => {
  const { setTopTabbarState, setIsBottomTabbar } = useSystemStore();
  const { originLocation, currentLocation, setCurrentLocation } =
    useCurrentLocationStore();
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(true);
  const mapRef = useRef<HTMLDivElement>(null);

  const [targetLocation, setTargetLocation] = useState<{
    lat: number;
    lng: number;
  }>({
    lat: currentLocation.lat !== null ? currentLocation.lat : 37.5461181757326,
    lng: currentLocation.lng !== null ? currentLocation.lng : 127.050040830902,
  });
  const { map, markerPosition, targetAddress, moveCenter } = useKakaoMap(
    'basic',
    mapRef,
    targetLocation.lat,
    targetLocation.lng,
  );

  useLayoutEffect(() => {
    setTopTabbarState({
      state: 'fixed',
      mode: 'subpage',
      leftElement: (
        <div
          onClick={() => {
            navigate(-1);
          }}
        >
          <img src='/assets/images/v2/arrow-left.svg' alt='arrow left' />
        </div>
      ),
      title: '현재 위치 설정',
    });
    setIsBottomTabbar(false);
    console.info(map);

    if (
      currentLocation.lat === null &&
      originLocation.lat &&
      originLocation.lng
    ) {
      setCurrentLocation({
        lat: originLocation.lat,
        lng: originLocation.lng,
      });
    }
  }, []);

  useEffect(() => {
    if (map) {
      console.info('좌표', markerPosition);
    }
  }, [markerPosition]);

  return (
    <div className='relative h-[calc(100vh-56px)] overflow-hidden'>
      <div className=' w-full h-full overflow-hidden z-0'>
        <div
          className='kakaoMapContainer w-full h-full  pointer-events-auto'
          ref={mapRef}
        ></div>
      </div>
      <BottomSheet
        title={`${targetAddress ? (targetAddress.road_address ? targetAddress.road_address.address_name : `${targetAddress.address.address_name ?? '주소 없음'}`) : ''}`}
        isOpen={isOpen}
        setIsOpen={(state: boolean) => setIsOpen(state)}
      >
        <div className='w-full p-4 pt-0'>
          <div className='w-full mb-[30px] h-full'>
            <span className='text-[14px] leading-[18.2px] text-[#111]'>
              {targetAddress
                ? targetAddress.road_address
                  ? targetAddress.road_address.building_name ?? ''
                  : `${targetAddress.address.main_address_no}-${targetAddress.address.sub_address_no}`
                : ''}
            </span>
          </div>
          <button
            onClick={() => {
              setTargetLocation({
                lat: markerPosition.lat,
                lng: markerPosition.lng,
              });
              setCurrentLocation({
                lat: markerPosition.lat,
                lng: markerPosition.lng,
              });
              navigate(-1);
            }}
            className='flex justify-center items-center w-full py-[14px] px-4 bg-[#7273F9] text-white text-[16px] leading-[24px] font-[600] rounded-[6px]'
          >
            확인
          </button>
          <div
            onClick={() => {
              if (originLocation.lat && originLocation.lng) {
                moveCenter(originLocation.lat, originLocation.lng);
              } else {
                moveCenter(37.5461181757326, 127.050040830902);
              }
            }}
            className='absolute w-[36px] h-[36px] flex justify-center items-center right-4 top-[-54px] bg-white rounded-full shadow-[0px_4.8px_7.2px_#1111111A]'
          >
            <img
              src='/assets/images/v2/gps.svg'
              alt='gps아이콘'
              className='w-[24px] object-contain'
            />
          </div>
        </div>
      </BottomSheet>
    </div>
  );
};
export default LocationSettingPage;
