import { AnimatePresence } from 'framer-motion';
import React from 'react';

import CarsayoButton from './CarsayoButton';
import CarsayoModalContainer from './CarsayoModalContainer';
import { Button } from 'components/ui/button';

interface CarsayoConfirmPopupInterface {
  isShown: boolean;
  children: React.ReactNode;
  textAlign?: 'center' | 'left' | 'right';
  cancelButtonValue: string;
  confirmButtonValue: string;
  isCancel?: boolean;
  isRounded?: boolean;
  onCancel: () => void;
  onConfirm: (event: React.MouseEvent<HTMLButtonElement>) => void;
}

const CarsayoConfirmPopup = ({
  isShown,
  children,
  textAlign,
  cancelButtonValue = '취소',
  confirmButtonValue = '확인',
  isCancel,
  isRounded,
  onCancel,
  onConfirm,
}: CarsayoConfirmPopupInterface) => {
  return (
    <>
      <AnimatePresence>
        {isShown && (
          <>
            <CarsayoModalContainer isShown={isShown} onClose={onCancel}>
              <div
                className={`flex flex-col justify-between w-full min-h-[170px] items-baseline bg-white ${isRounded ? 'rounded-[30px] p-[30px]' : 'rounded'}`}
              >
                <div className='flex p-[30px] flex-col items-center justify-center flex-1 w-full'>
                  {children}
                </div>
                <div
                  className={`flex justify-between w-full gap-4 ${isRounded && 'mt-[30px]'}`}
                >
                  {isCancel && (
                    <CarsayoButton
                      color='secondary'
                      className={'py-3 w-[100px] min-h-[29px] '}
                      onClick={onCancel}
                    >
                      <p className='text-[#333]'>{cancelButtonValue}</p>
                    </CarsayoButton>
                  )}
                  {isRounded ? (
                    <CarsayoButton
                      className={`py-3 w-[100px] min-h-[29px]`}
                      onClick={onConfirm}
                    >
                      <p>{confirmButtonValue}</p>
                    </CarsayoButton>
                  ) : (
                    <Button
                      className='bg-[#111] text-[#fff] flex justify-center items-center rounded-none'
                      onClick={onConfirm}
                    >
                      {confirmButtonValue}
                    </Button>
                  )}
                </div>
              </div>
            </CarsayoModalContainer>
          </>
        )}
      </AnimatePresence>
    </>
  );
};

export default CarsayoConfirmPopup;
