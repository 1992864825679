import classNames from 'classnames';
import React from 'react';

// header component추가

interface CarsayoBottomSheetInterface {
  title?: string;
  content?: string;
  children: React.ReactNode;
  isOpen: boolean;
  onClose: () => void;
}

// TODO: 드래그 이벤트 추가
const CarsayoBottomSheet = ({
  isOpen,
  title,
  content,
  children,
  onClose,
}: CarsayoBottomSheetInterface) => {
  return (
    <div
      className={classNames(
        ' wrapper fixed w-full h-full !mt-0 inset-0 bg-black/70 z-[9000] !overflow-y-hidden border-none border-r-0',
        {
          fixed: isOpen,
          hidden: !isOpen,
        },
      )}
      onClick={onClose}
    >
      <div
        className={classNames(
          'flex flex-col fixed overflow-hidden',
          'pb-[3.5vh] px-[6.97vw] h-auto my-0 w-full mx-auto',
          'min-h-[35.6vh] !bottom-[0px] z-[602] left-0 right-0',
          'rounded-t-[20px] bg-[#fff]',
          {
            'animate-sheet-up': isOpen,
            'animate-sheet-down': !isOpen,
          },
        )}
        onClick={(e) => e.stopPropagation()}
      >
        <div className='wrapper relative rounded-t-[20px] h-[3.5vh] p-[0.625rem]'>
          {/* <div className='handle-bar w-[8.2vw] h-[4px] rounded-[2px] bg-[#ddd] mx-auto my-auto'></div> */}
        </div>
        <button
          className={classNames(
            'relative mb-[7px] flex self-end -indent-view-out text-right w-[1rem] h-[1rem] text-transparent',
            'px-0 py-0 after:content-[""] after:block after:w-[100%] after:h-[100%] after:absolute after:right-0 after:top-0',
            'after:bg-close-icon after:bg-center after:bg-no-repeat after:bg-content-fit after:box-content',
          )}
          onClick={onClose}
          type='button'
        >
          닫기
        </button>
        <h3 className='inline-block font-NotoSansKR font-[500] text-left text-[1.125rem] text-[#333]'>
          {title}
        </h3>
        <h3 className='inline-block font-NotoSansKR font-[400] text-left text-[14px] text-[#787878]'>
          {content}
        </h3>
        <div
          className={classNames(
            'content-box flex gap-[10px] mt-[20px] min-h-[calc(28vh+40px)] overflow-y-auto w-full transition-all',
            {
              'translate-y-0 h-auto': isOpen,
              'translate-y-[-300px] h-0': !isOpen,
            },
          )}
        >
          {children}
        </div>
      </div>
    </div>
  );
};
export default CarsayoBottomSheet;
