/* eslint-disable @typescript-eslint/no-unused-vars */
import { useLayoutEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import useSystemStore from 'store/useSystemStore';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore from 'swiper';
import SwiperClass from 'swiper/types/swiper-class';
import { CarsayoToast } from 'components/common/v2/CarsayoToast';
import useKakaoMap from 'hooks/useKakaoMap';

const RepairDetailPage = () => {
  const { setTopTabbarState, setIsBottomTabbar } = useSystemStore();
  const navigate = useNavigate();
  const [swiper, setSwiper] = useState<SwiperCore | null>(null);
  const [page, setPage] = useState(1);
  const mapRef = useRef<any>();
  const { map, moveCenter, markerPosition } = useKakaoMap(
    'basic',
    mapRef,
    37.5085517849896,
    127.065386486679,
  );

  useLayoutEffect(() => {
    setTopTabbarState({
      state: 'fixed',
      mode: 'subpage',
      leftElement: (
        <div
          onClick={() => {
            navigate(-1);
          }}
        >
          <img src='/assets/images/v2/arrow-left.svg' alt='arrow left' />
        </div>
      ),
      title: '공업사 정보',
    });
    setIsBottomTabbar(false);
  }, []);

  return (
    <div className='w-full h-full overflow-y-auto'>
      <div className='relative w-full mb-[30px]'>
        <Swiper
          slidesPerView={1}
          onRealIndexChange={(swiper: SwiperClass) =>
            setPage(swiper.realIndex + 1)
          }
          onSwiper={(swiper: SwiperClass) => setSwiper(swiper)}
          loop={true}
        >
          <SwiperSlide className='w-full'>
            <img
              src='/assets/images/png/repair-ex-img01.png'
              alt='공업사 예시 이미지'
              className='w-full h-full object-cover'
            />
          </SwiperSlide>
          <SwiperSlide className='w-full'>
            <img
              src='/assets/images/png/repair-ex-img01.png'
              alt='공업사 예시 이미지'
              className='w-full h-full object-cover'
            />
          </SwiperSlide>
          <SwiperSlide className='w-full'>
            <img
              src='/assets/images/png/repair-ex-img01.png'
              alt='공업사 예시 이미지'
              className='w-full h-full object-cover'
            />
          </SwiperSlide>
          <SwiperSlide className='w-full'>
            <img
              src='/assets/images/png/repair-ex-img01.png'
              alt='공업사 예시 이미지'
              className='w-full h-full object-cover'
            />
          </SwiperSlide>
          <SwiperSlide className='w-full'>
            <img
              src='/assets/images/png/repair-ex-img01.png'
              alt='공업사 예시 이미지'
              className='w-full h-full object-cover'
            />
          </SwiperSlide>
          <SwiperSlide className='w-full'>
            <img
              src='/assets/images/png/repair-ex-img01.png'
              alt='공업사 예시 이미지'
              className='w-full h-full object-cover'
            />
          </SwiperSlide>
        </Swiper>
        <div className='absolute z-10 bottom-[10px] right-[10px] bg-[#00000080] py-[5px] px-[8px] rounded-[100px] text-[12px] leading-[12px] text-white'>
          <span>{page}</span>/6
        </div>
        <ul className='absolute bottom-[10px] z-10 left-[10px] flex justify-start items-center gap-[4px]'>
          <li className='inline-flex min-w-[60px] justify-center items-center gap-[4px] bg-[#00000080] py-[4px] px-[6px] border-[0.5px] border-[#E5E5EC33] rounded-[6px]'>
            <strong className='block w-[8px] h-[8px] bg-[#5DDF72] rounded-full'></strong>
            <span className='text-[13px] leading-[13px] font-[500] text-[#fff]'>
              영업중
            </span>
          </li>
          {/* <li className='inline-flex min-w-[60px] justify-center items-center gap-[4px] bg-[#00000080] py-[4px] px-[6px] border-[0.5px] border-[#E5E5EC33] rounded-[6px]'>
                <strong className='block w-[8px] h-[8px] bg-[#CCCCCC] rounded-full'></strong>
                <span className='text-[13px] leading-[13px] font-[500] text-[#fff]'>
                  영업마감
                </span>
              </li> */}
          <li className='inline-flex min-w-[60px] justify-center items-center gap-[4px] bg-[#00000080] py-[4px] pr-[6px] pl-[4px] border-[0.5px] border-[#E5E5EC33] rounded-[6px]'>
            <strong className='block w-[10px] h-[10px]'>
              <img
                src='/assets/images/v2/tick-circle.svg'
                alt='체크아이콘'
                className='w-full object-contain'
              />
            </strong>
            <span className='text-[13px] leading-[13px] font-[500] text-[#fff]'>
              견인가능
            </span>
          </li>
          {/* <li className='inline-flex min-w-[60px] justify-center items-center gap-[2px] bg-[#00000080] py-[4px] pr-[6px] pl-[4px] border-[0.5px] border-[#E5E5EC33] rounded-[6px]'>
            <strong className='block w-[ ] h-[14px]'>
              <img
                src='/assets/images/v2/close-circle.svg'
                alt='닫기아이콘'
                className='w-full object-contain'
              />
            </strong>
            <span className='text-[13px] leading-[13px] font-[500] text-[#fff]'>
              견인불가
            </span>
          </li> */}
        </ul>
      </div>
      <div className='pb-[50px]'>
        {/* 공업사 정보 */}
        <div className='border-b-[10px] border-[#F6F6FC] px-[20px]'>
          <h3 className='text-[18px] leading-[27px] font-[600] mb-[10px]'>
            카센터 정비소
          </h3>
          <p className='text-[#555555] text-[16px] leading-[20.8px] mb-4'>
            <span>6km • 서울 서초구 강남대로 309</span>
          </p>
          <p className='text-[#555555] text-[15px] leading-[22.5px]'>
            <span>
              공업사 소개 내용이 옵니다. 내용은 줄임없이 등록된 내용으로 좌악
              펼쳐집니다. 병원 소개 내용이 옵니다. 내용은 줄임없이 등록된
              내용으로 좌악 펼쳐집니다.
            </span>
          </p>
          <button
            onClick={() => {
              window.location.href = `tel:025840010`;
            }}
            className='mt-[30px] bg-[#fff] border border-[#E5E5EC] w-full flex justify-center items-center gap-[6px] rounded-[6px] py-4 px-[10px] mb-[20px]'
          >
            <img
              src='/assets/images/v2/call-blue.svg'
              alt='전화아이콘'
              className='w-4 object-contain'
            />
            <span className='text-[16px] font-[500] leading-[16px]'>
              02-584-0010
            </span>
          </button>
        </div>
        {/* 영업시간 */}
        <div className='pt-[30px] px-[20px]'>
          <h4 className='w-full text-[#111] text-[16px] leading-[16px] font-[500] mb-[20px]'>
            영업시간
          </h4>
          <ul className='flex flex-wrap gap-x-[6px] gap-y-[7px]'>
            <li className='w-[calc(50%-3px)] flex flex-col justify-start items-start gap-[6px] bg-[#F6F6FC] py-[10px] px-4 rounded-[10px]'>
              <span className='text-[#555] text-[15px] leading-[15px]'>
                월요일
              </span>
              <strong className='text-[15px] leading-[15px] font-[400] text-[#111]'>
                10:00 ~ 21:00
              </strong>
            </li>
            <li className='w-[calc(50%-3px)] flex flex-col justify-start items-start gap-[6px] bg-[#F6F6FC] py-[10px] px-4 rounded-[10px]'>
              <span className='text-[#555] text-[15px] leading-[15px]'>
                화요일
              </span>
              <strong className='text-[15px] leading-[15px] font-[400] text-[#111]'>
                10:00 ~ 21:00
              </strong>
            </li>
            <li className='w-[calc(50%-3px)] flex flex-col justify-start items-start gap-[6px] bg-[#F6F6FC] py-[10px] px-4 rounded-[10px]'>
              <span className='text-[#555] text-[15px] leading-[15px]'>
                수요일
              </span>
              <strong className='text-[15px] leading-[15px] font-[400] text-[#111]'>
                10:00 ~ 21:00
              </strong>
            </li>
            <li className='w-[calc(50%-3px)] flex flex-col justify-start items-start gap-[6px] bg-[#F6F6FC] py-[10px] px-4 rounded-[10px]'>
              <span className='text-[#555] text-[15px] leading-[15px]'>
                목요일
              </span>
              <strong className='text-[15px] leading-[15px] font-[400] text-[#111]'>
                10:00 ~ 21:00
              </strong>
            </li>
            <li className='w-[calc(50%-3px)] flex flex-col justify-start items-start gap-[6px] bg-[#F6F6FC] py-[10px] px-4 rounded-[10px]'>
              <span className='text-[#555] text-[15px] leading-[15px]'>
                금요일
              </span>
              <strong className='text-[15px] leading-[15px] font-[400] text-[#111]'>
                10:00 ~ 21:00
              </strong>
            </li>
            <li className='w-[calc(50%-3px)] flex flex-col justify-start items-start gap-[6px] bg-[#F6F6FC] py-[10px] px-4 rounded-[10px]'>
              <span className='text-[#555] text-[15px] leading-[15px]'>
                토요일
              </span>
              <strong className='text-[15px] leading-[15px] font-[400] text-[#111]'>
                10:00 ~ 21:00
              </strong>
            </li>
            <li className='w-[calc(50%-3px)] flex flex-col justify-start items-start gap-[6px] bg-[#F6F6FC] py-[10px] px-4 rounded-[10px]'>
              <span className='text-[#555] text-[15px] leading-[15px]'>
                일요일, 공휴일
              </span>
              <strong className='text-[15px] leading-[15px] font-[400] text-[#111]'>
                10:00 ~ 21:00
              </strong>
            </li>
            <li className='flex-1 w-full flex flex-col justify-start items-start gap-[6px] bg-[#F6F6FC] py-[10px] px-4 rounded-[10px]'>
              <span className='text-[#555] text-[15px] leading-[15px]'>
                휴게시간
              </span>
              <strong className='text-[15px] leading-[15px] font-[400] text-[#111]'>
                -
              </strong>
            </li>
          </ul>
        </div>
        {/* 위치 */}
        <div className='pt-[30px] px-[20px]'>
          <h4 className='w-full text-[#111] text-[16px] leading-[16px] font-[500] mb-[20px]'>
            위치
          </h4>
          <div className='flex justify-between items-start gap-[20px] mb-[20px]'>
            <p className='text-[#555555] text-[16px] leading-[22.4px]'>
              경기도 안산시 상록구 예술광장로 35 2층
              <br />
              203호, 204호, 5층
            </p>
            <button
              onClick={() => {
                navigator.clipboard
                  .writeText(
                    '경기도 안산시 상록구 예술광장로 35 2층 203호, 204호, 5층',
                  )
                  .then(() => {
                    CarsayoToast.success('복사했습니다.');
                  })
                  .catch((err) => {
                    console.error('Failed to copy: ', err);
                  });
              }}
              className='py-[10px] px-[14px] border border-[#E5E5EC] rounded-[6px] text-[14px] leading-[14px]'
            >
              복사
            </button>
          </div>
          <div className='relative w-full rounded-[10px] overflow-hidden'>
            <div
              className='w-full h-[200px] rounded-[10px] pointer-events-auto touch-auto'
              ref={mapRef}
            ></div>
            <button
              onClick={() => moveCenter(37.5085517849896, 127.065386486679)}
              className='absolute right-2 bottom-2 py-1 px-2 bg-white border border-[#ccc] z-10 text-[12px] rounded-[6px] shadow-sm'
            >
              원위치로
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RepairDetailPage;
