/* eslint-disable @typescript-eslint/no-unused-vars */
import { getNotificationList, readNotification } from 'apis/notification';
import { useEffect, useLayoutEffect, useMemo, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import useSystemStore from 'store/useSystemStore';
import EmailIcon from 'assets/images/v2/email.svg';
import GiftIcon from 'assets/images/v2/gift.svg';
import RightArrow from 'assets/images/v2/arrowRightGray.svg';
import BottomArrow from 'assets/images/v2/arrowBottom.svg';
import MegaphoneIcon from 'assets/images/v2/megaphone.svg';
import { checkDate } from 'utils/convert';
import useMemberStore from 'store/useMemberStore';
import { CarsayoNotification } from '@carsayo/types';
import { cn } from 'utils';
import { useQuery } from '@tanstack/react-query';
import { CircularProgress } from '@mui/material';

const pageLength = 10;

const AlarmListPage = () => {
  const navigate = useNavigate();
  const { setTopTabbarState } = useSystemStore();
  const { loginMember, refreshLoginMember } = useMemberStore();

  const [searchParams] = useSearchParams();

  const [currentShowingAlertNumber, setCurrentShowingAlertNumber] =
    useState<number>(
      searchParams.get('currentShowingNumber')
        ? Number(searchParams.get('currentShowingNumber'))
        : pageLength,
    );

  const useNotificationList = () => {
    return useQuery({
      queryKey: ['memberAlertList'],
      queryFn: async () => {
        const data: CarsayoNotification[] = await getNotificationList({
          take: 100,
        });
        return data;
      },
      gcTime: 5 * 60 * 1000, // 5 min
      staleTime: 5 * 1000, // 5 sec
    });
  };
  const { data: notificationList, refetch, isFetched } = useNotificationList();

  const showingNotificationList = useMemo(() => {
    if (!notificationList) return [];
    return notificationList.slice(0, currentShowingAlertNumber);
  }, [notificationList, currentShowingAlertNumber]);

  const handleClickEvents = {
    moveToAlarmPage: (el: CarsayoNotification) => async () => {
      await handleClickEvents.readNotification(el);
      if (el.path.app) {
        navigate(el.path.app);
      } else {
        return;
      }
    },
    readNotification: async (notification: CarsayoNotification) => {
      if (!loginMember) return;
      await readNotification({ notificationId: notification.id });
      await refetch();
      await refreshLoginMember();
    },
    moreNotification: async () => {
      if (!showingNotificationList) return;
      setCurrentShowingAlertNumber(currentShowingAlertNumber + pageLength);
    },
  };

  const setQueryString = (key: string, value: string) => {
    const params = new URLSearchParams();
    params.set(key, value);
    navigate(`?${params.toString()}`, { replace: true });
  };

  useLayoutEffect(() => {
    if (currentShowingAlertNumber > pageLength) {
      setQueryString('currentShowingNumber', String(currentShowingAlertNumber));
    }
  }, [currentShowingAlertNumber]);

  useLayoutEffect(() => {
    setTopTabbarState({
      state: 'fixed',
      mode: 'subpage',
      rightElement: 'home',
      leftElement: 'back',
      title: '알림',
    });
  }, []);

  return (
    <>
      {isFetched ? (
        <div>
          {showingNotificationList && showingNotificationList.length > 0 ? (
            <div className='w-full flex flex-col justify-between gap-[1.97vh] h-full pt-8 pb-12 px-4'>
              <ul className='w-full h-auto flex flex-col gap-[24px]'>
                {showingNotificationList.map((el, index) => {
                  return (
                    <li key={`nofi_${el.id}`} className='w-full h-full'>
                      <button
                        className={`w-full pb-[24px] ${index < showingNotificationList.length - 1 && 'border-b-[1px] border-[#F1F1F5]'} relative`}
                        onClick={handleClickEvents.moveToAlarmPage(el)}
                      >
                        <div
                          className={cn(
                            'flex flex-row gap-[16px] items-stretch h-full',
                            el.isReaded && 'opacity-25',
                          )}
                        >
                          <div className='min-w-[6.4vw]'>
                            <img
                              src={el.type === 'event' ? GiftIcon : EmailIcon}
                              alt='이메일 아이콘'
                              className='w-[6.4vw] h-auto object-contain'
                            />
                          </div>
                          <div className='text-[#11111] grow h-full flex flex-col gap-[8px] justify-between items-stretch'>
                            {el.title && (
                              <p className='max-w-[80%]  text-left text-[14px] font-[600] leading-[24px]'>
                                {el.title}
                              </p>
                            )}
                            {el.content && (
                              <p className='max-w-[80%]  text-left text-[13px] leading-[18px]'>
                                {el.content}
                              </p>
                            )}
                            {el.created_at && (
                              <p className='text-[#767676] text-left text-[13px] tracking-[-0.33px]'>
                                {checkDate(el.created_at)}
                              </p>
                            )}
                          </div>
                        </div>
                        {el.path.app && (
                          <span className='absolute inline-flex justify-center items-center top-0 bottom-[40%] my-auto right-0'>
                            <img
                              src={RightArrow}
                              alt='오른쪽 화살표'
                              className='w-[16px] h-[16px] object-contain'
                            />
                          </span>
                        )}
                      </button>
                    </li>
                  );
                })}
              </ul>
              {notificationList &&
                notificationList.length > currentShowingAlertNumber && (
                  <button
                    className='w-full py-[14px] px-[16px] flex justify-center items-center min-h-[52px] border border-[#E5E5EC]'
                    onClick={handleClickEvents.moreNotification}
                  >
                    더보기
                    <img
                      src={BottomArrow}
                      alt='아래 화살표'
                      className='ml-[5px]'
                    />
                  </button>
                )}
            </div>
          ) : (
            <div className='pt-[1.97vh] min-h-[26.6vh] w-full flex flex-col justify-center items-center gap-[0.98vh]'>
              <img
                src={MegaphoneIcon}
                alt='확성기아이콘'
                className='w-[26.6vw] h-auto object-contain mx-auto'
              />
              <p className='text-[#767676]'>
                최근 7일간 수신한 알림이 없습니다.
              </p>
            </div>
          )}
        </div>
      ) : (
        <div className='flex h-full justify-center items-center'>
          <CircularProgress></CircularProgress>
        </div>
      )}
    </>
  );
};

export default AlarmListPage;
