/* eslint-disable @typescript-eslint/no-unused-vars */
import { InquirySubmitDTO } from '@carsayo/types';
import CarsayoInput from 'components/common/CarsayoInput';
import { ChangeEvent, useEffect, useLayoutEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import useSystemStore from 'store/useSystemStore';
import { InquiryTypeList } from '../../data';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from 'components/ui/select';
import { Textarea } from 'components/ui/textarea';
import CarsayoButton from 'components/common/CarsayoButton';
import CarsayoTextArea from 'components/common/CarsayoTextArea';
import { inquirySubmit } from 'apis/inquiry';
import useMemberStore from 'store/useMemberStore';

const InquiryAddPage = () => {
  const { refreshLoginMember } = useMemberStore();
  const { setTopTabbarState, setIsBottomTabbar } = useSystemStore();
  const navigate = useNavigate();
  const [inquiryData, setInquiryData] = useState<InquirySubmitDTO>({
    type: InquiryTypeList[0] ?? '',
    title: '',
    content: '',
  });

  const addInquiry = async () => {
    console.info(inquiryData);
    try {
      const result = await inquirySubmit(inquiryData);
      if (result) {
        refreshLoginMember();
        navigate('/ask/theme/cargipsa', {
          state: {
            key: ['inquiryComplete'],
          },
        });
      } else {
        console.error('1:1 문의하기 등록 중 에러');
      }
    } catch (error: any) {
      console.error(error);
    }
  };

  useLayoutEffect(() => {
    setTopTabbarState({
      state: 'fixed',
      mode: 'subpage',
      leftElement: (
        <div
          onClick={() => {
            navigate(-1);
          }}
        >
          <img src='/assets/images/v2/arrow-left.svg' alt='arrow left' />
        </div>
      ),
      title: '1:1  문의',
    });
    setIsBottomTabbar(false);
  }, []);

  return (
    <div className='p-4 pt-0'>
      <div className='flex justify-between items-end pt-[10px] pb-[20px] border-b-[1px] border-[#F1F1F5]'>
        <div>
          <h4 className='text-[16px] leading-[24px] font-[600] mb-[8px]'>
            문의 내용을 입력해주세요
          </h4>
          <p className='text-[14px] leading-[20px]'>
            신속하게 답변 드리며
            <br /> 특별한 카 라이프에 카집사가 함께 하겠습니다.
          </p>
        </div>
        <img
          src='/assets/images/v2/ask-carGipsa-inquiry01.svg'
          alt='카집사듣는이미지'
        />
      </div>
      <div>
        <form className='pt-[20px]'>
          <fieldset className='flex flex-col justify-start items-start gap-[20px] pb-6'>
            <div className='w-full'>
              <label
                htmlFor='inquiryType'
                className='block mb-[8px] text-[15px] leading-[22px] text-[#111]'
              >
                문의 유형
              </label>
              <Select
                onValueChange={(value) =>
                  setInquiryData((prev) => ({ ...prev, type: value }))
                }
              >
                <SelectTrigger
                  style={{
                    fontSize: '16px',
                    fontWeight: '400',
                    color: '#111',
                  }}
                >
                  <SelectValue placeholder={InquiryTypeList[0]} />
                </SelectTrigger>
                <SelectContent>
                  {InquiryTypeList &&
                    InquiryTypeList.length > 0 &&
                    InquiryTypeList.map((item) => {
                      return (
                        <SelectItem key={`inquiryType_${item}`} value={item}>
                          {item}
                        </SelectItem>
                      );
                    })}
                </SelectContent>
              </Select>
            </div>
            <div className='w-full'>
              <label
                htmlFor='inquiryTitle'
                className='block mb-[8px] text-[15px] leading-[22px] text-[#111]'
              >
                문의 제목
              </label>
              <CarsayoInput
                id='inquiryTitle'
                className='!px-3 rounded-none text-[#111] text-[16px] leading-[24px] font-[400]'
                value={inquiryData.title}
                placeholder='제목을 입력해주세요.'
                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                  setInquiryData((prev) => ({
                    ...prev,
                    title: e.target.value,
                  }));
                }}
              />
            </div>
            <div className='w-full'>
              <label
                htmlFor='inquiryContent'
                className='block mb-[8px] text-[15px] leading-[22px] text-[#111]'
              >
                문의 내용
              </label>
              <div className='relative border border-[#E5E5EC] transition-all focus-within:border-[#333]'>
                <Textarea
                  className='mb-8 min-h-[100px] border-0 text-[#111] text-[16px] leading-[24px] font-[400]'
                  maxLength={500}
                  placeholder={`내용을 입력해주세요. \n문의와 관련 없는 개인정보는 작성하지 않도록 \n유의해 주시기 바랍니다.`}
                  value={inquiryData.content}
                  onChange={(e: ChangeEvent<HTMLTextAreaElement>) => {
                    if (e.target.value.length < 501) {
                      setInquiryData((prev) => ({
                        ...prev,
                        content: e.target.value,
                      }));
                    }
                  }}
                ></Textarea>
                <span className='absolute right-4 bottom-3 text-[#767676] text-[13px] leading-[18px]'>
                  {inquiryData.content.length ?? 0}/500
                </span>
              </div>
            </div>
            <div className='w-full noticeBox bg-[#F7F7FB] p-4 flex flex-col justify-start items-start gap-[12px]'>
              <h5 className='leading-[22px] text-[#111] font-[500]'>
                유의사항
              </h5>
              <ul className='text-[#767676] flex flex-col justify-start items-start gap-[4px]'>
                <li className='flex justify-start items-center relative text-[13px] leading-[18px] pl-3 after:content-["•"] after:absolute after:left-0 after:top-0 after:bottom-0 after:my-auto'>
                  문의 답변은 평일 오전 9시 ~ 오후 6시에 진행됩니다.
                </li>
                <li className='flex justify-start items-center relative text-[13px] leading-[18px] pl-3 after:content-["•"] after:absolute after:left-0 after:top-0 after:bottom-0 after:my-auto'>
                  접수 건이 많은 경우 순차적으로 처리됩니다.
                </li>
              </ul>
            </div>
          </fieldset>
          <CarsayoButton
            color='purpleBlue'
            disabled={
              inquiryData.title && inquiryData.type && inquiryData.content
                ? false
                : true
            }
            onClick={addInquiry}
          >
            문의하기
          </CarsayoButton>
        </form>
      </div>
    </div>
  );
};
export default InquiryAddPage;
