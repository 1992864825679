import dayjs from 'dayjs';
import React from 'react';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';

interface AnswerBubbleInterface {
  title: string;
  time: Date;
  bgColor: string;
}

const AnswerBubble = ({ title, time, bgColor }: AnswerBubbleInterface) => {
  dayjs.extend(utc);
  dayjs.extend(timezone);
  const nowTime = dayjs(time).tz('Asia/Seoul');
  const AMPM = nowTime.format('A');
  return (
    <div className='self-end w-full max-w-[79.2vw] flex flex-col justify-start items-end gap-2'>
      <div className='flex justify-start items-center gap-[10px]'>
        <div className='text-[#707070] leading-[18px]'>
          <span className='mr-[3px]'>{AMPM}</span>
          <span>{dayjs(time).format('hh:mm')}</span>
        </div>
      </div>
      <div
        className={`w-auto p-3 ${bgColor} rounded-t-[12px] rounded-bl-[12px] flex flex-col justify-start items-start`}
      >
        <div className='w-auto text-start text-[15px] font-[500] leading-[22.5px] text-white'>
          {title}
        </div>
      </div>
    </div>
  );
};
export default AnswerBubble;
