// 일반회원 가입은 이 페이지 내에서 모두 다 처리합니다
import { useLayoutEffect, useState } from 'react';
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from 'components/ui/form';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';
import { Input } from 'components/ui/input';
import { Button } from 'components/ui/button';
import Confirm from 'components/ui/confirm';
import { useNavigate } from 'react-router-dom';
import useSystemStore from 'store/useSystemStore';
import { isValidPhoneNumberFormat } from 'utils/telNumber';
import CarsayoNiceAuth, {
  CarsayoNiceAuthCallback,
} from 'components/common/v2/CarsayoNiceAuth';
import { checkExistPhoneNumber, SignupCustomer } from 'apis/authentication';
import signupStore from '../store';
import useMemberStore from 'store/useMemberStore';
import { ga4_event } from 'hooks/ga4';
import { ConsentClause } from '../component/ConsentClause';
import { TermCategoryId } from '@carsayo/types';

const formSchema = z.object({
  phone: z.string().min(1, {
    message: '휴대폰 번호를 입력해주세요',
  }),
});

export default function SignupCustomerPage() {
  const navigate = useNavigate();
  const { setTopTabbarState } = useSystemStore();
  const { setLoginMember } = useMemberStore();
  const { setLoadingDim, setTopTabbarTitle } = useSystemStore();
  const [isConfirmOpen, setIsConfirmOpen] = useState(false);
  const [confirmMessage, setConfirmMessage] = useState<{
    title: string;
    desc: string;
  }>({ title: '', desc: '' });

  const [isTermOpen, setIsTermOpen] = useState(false);
  const [isNiceAuthOpen, setIsNiceAuthOpen] = useState<boolean>(false);

  const { signupInfo, setSignupStore, resetSignupStore } = signupStore();

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      phone: '',
    },
  });

  const onSubmit = async (data: z.infer<typeof formSchema>) => {
    const inputPhoneNumber = form.getValues('phone');

    if (!isValidPhoneNumberFormat(inputPhoneNumber)) {
      form.setError('phone', {
        message: '휴대폰 번호를 확인해주세요.',
      });
      return;
    }

    const checkPhoneNumberData = await checkExistPhoneNumber({
      phoneNumber: inputPhoneNumber,
    });
    if (checkPhoneNumberData.isMember) {
      form.setError('phone', {
        message: '이미 사용중인 휴대전화 번호입니다. 다시 한번 확인해 주세요.',
      });

      return;
    }

    setSignupStore({
      phoneNumber: inputPhoneNumber,
    });

    // 약관 동의창 실행
    setIsTermOpen(true);
  };

  const termAgreeCallback = (data: TermCategoryId[]) => {
    setLoadingDim(true);

    // 나이스 본인인증 모듈을 실행하고, 이후 성공 혹은 실패시 콜백을 동작시킵니다.
    setIsNiceAuthOpen(true);

    // 이거 회원가입 스토어에 넣고 씁니다
    setSignupStore({
      ...signupInfo,
      agreeTermList: data,
    });
    return;
  };

  const niceApiCallback = async (params: CarsayoNiceAuthCallback) => {
    if (params.status === 'success' && params.token) {
      if (signupInfo && signupInfo.phoneNumber && signupInfo.agreeTermList) {
        await SignupCustomer({
          identityVerifyToken: params.token,
          phoneNumber: signupInfo.phoneNumber,
          '10100': signupInfo.agreeTermList.includes(10100),
          '10200': signupInfo.agreeTermList.includes(10200),
          '10500': signupInfo.agreeTermList.includes(10500),
          '10600': signupInfo.agreeTermList.includes(10600),
          '10700': signupInfo.agreeTermList.includes(10700),
          '94100': signupInfo.agreeTermList.includes(94100),
          '95100': signupInfo.agreeTermList.includes(95100),
          '95200': signupInfo.agreeTermList.includes(95200),
          '95300': signupInfo.agreeTermList.includes(95300),
        })
          .then((data) => {
            if (data.member) {
              ga4_event({
                category: 'signup_customer',
                action: '일반 회원 회원가입',
                label: data.member.id,
              });
              setLoginMember(data.member);
              setTimeout(() => {
                navigate('/main');
              }, 1000);
            } else {
              setConfirmMessage({
                title: '회원 가입 실패',
                desc: '회원가입에 실패했습니다.\n잠시 후 다시 시도해 주세요.',
              });
              setIsConfirmOpen(true);
              setLoadingDim(false);
            }
          })
          .catch((e: any) => {
            setConfirmMessage({
              title: '회원 가입 실패',
              desc: e?.response?.data?.message
                ? e?.response?.data?.message
                : '회원가입에 실패했습니다.\n잠시 후 다시 시도해 주세요.',
            });
            setIsConfirmOpen(true);
            setLoadingDim(false);
          });
      } else {
        console.error('Failed!!!!');
        setLoadingDim(false);
      }
    } else {
      setLoadingDim(false);
      setConfirmMessage({
        title: '본인 인증 실패',
        desc: '개인 정보를 다시 확인해주세요.',
      });
      setIsConfirmOpen(true);
      resetSignupStore();
    }
  };

  useLayoutEffect(() => {
    setTopTabbarState({
      state: 'fixed',
      mode: 'subpage',
      leftElement: 'back',
    });
    setTopTabbarTitle('회원 가입');
    return () => {
      setLoadingDim(false);
      resetSignupStore();
    };
  }, []);

  return (
    <div className='flex min-h-full grow flex-col p-4 pb-10'>
      <CarsayoNiceAuth
        isOpen={isNiceAuthOpen}
        setOpen={setIsNiceAuthOpen}
        callback={niceApiCallback}
      />

      <div className='text-lg font-semibold'>
        카사요 이용을 위해
        <br />
        <span className='text-primary'>본인 확인</span>을 진행합니다
      </div>
      <div className='mt-6'>
        <Form {...form}>
          <form
            id='signupForm'
            onSubmit={form.handleSubmit(onSubmit)}
            className='space-y-8'
          >
            <FormField
              control={form.control}
              name='phone'
              render={({ field }) => (
                <FormItem>
                  <FormLabel>휴대전화</FormLabel>
                  <FormControl>
                    <Input
                      type='number'
                      autoComplete='off'
                      placeholder='숫자만 입력'
                      maxLength={13}
                      {...field}
                    ></Input>
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
          </form>
        </Form>
      </div>

      <Button className='mt-4' type='submit' form='signupForm'>
        시작하기
      </Button>

      <div className='pt-6 text-[18px] font-medium leading-[22px] tracking-[-0.375px]'>
        꼭 읽어주세요!
      </div>
      <ul className='pt-2 text-[13px] list-disc pl-5 text-[#767676]'>
        <li className='mb-2'>
          미스터리 쇼퍼(고객으로 가장하여 정보를 캐거나 소속된 카 매니저들을
          색출하는 사람)는 회원 가입이 불가하며, 적발(계약 체결, 출고, 해지 포함
          5년간) 시 건 당 10억 원의 배상과 민형사상 책임을 져야 합니다.
        </li>
        <li className='mb-2'>
          실제 구매자·판매자가 아닐 경우 요청된 견적 1건 당 1만 원의 정보 제공
          이용료를 배상해야 합니다.
        </li>
        <li className='mb-2'>
          무분별한 구매·판매를 제한하기 위해 무료 견적 요청은 신차 구매, 내 차
          팔기 각 1건으로 제한됩니다. 추가 견적 요청 시 요청 견적 1건 당 1만
          원의 정보 이용료를 부과하고 있습니다.
        </li>
        <li className='mb-2'>
          카사요를 통해 계약을 진행한 경우, 납부한 정보 이용료는 100% 환불해
          드립니다.
        </li>
        <li className='mb-2'>
          로그아웃하거나 설정에서 푸시 알림을 꺼두시면 견적 도착 푸시 알림이
          발송되지 않으니, 이 점 유의해 주세요.
        </li>
      </ul>

      <ConsentClause
        termData={{
          category1: {
            title: '카사요 약관 및 동의사항',
            term: [10100, 10200],
            isEssential: true,
          },
          category2: {
            title: '추가 동의사항',
            term: [10500, 10600, 10700],
            isEssential: true,
          },
          category3:
            process.env.REACT_APP_NODE_ENV === 'production'
              ? undefined
              : {
                  title: '위치 기반 서비스 이용 동의',
                  term: [94100],
                  isEssential: false,
                },
          category4: {
            title: '광고 및 마케팅 수신 동의',
            term: [95100, 95200, 95300],
            isEssential: false,
          },
        }}
        isOpen={isTermOpen}
        setIsOpen={setIsTermOpen}
        onConfirm={termAgreeCallback}
      />

      <Confirm
        title={confirmMessage.title}
        desc={confirmMessage.desc}
        isOpen={isConfirmOpen}
        setIsOpen={setIsConfirmOpen}
      />
    </div>
  );
}
