// 내차팔기
import { ChevronLeft, X } from 'lucide-react';
import { useLayoutEffect } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import useSystemStore from 'store/useSystemStore';
import useSellingStore from './store';
import useMemberStore from 'store/useMemberStore';
import { getMember, isLogin } from 'apis/authentication';
import { CarsayoToast } from 'components/common/v2/CarsayoToast';
export default function SellingIndex() {
  const navigate = useNavigate();
  const { setTopTabbarState, setIsBottomTabbar } = useSystemStore();
  const { initSellingStore, resetSellingStore } = useSellingStore();
  const { loginMember } = useMemberStore();

  const setAndroidBar = () => {
    if (
      window?.android?.setNavigationBarDark &&
      window?.android?.setStatusBarDark
    ) {
      window.android.setNavigationBarDark('#181A20');
      window.android.setStatusBarDark('#181A20');
    }
  };

  const resetAndroidBar = () => {
    if (
      window?.android?.setNavigationBarDark &&
      window?.android?.setStatusBarDark
    ) {
      window.android.setNavigationBarWhite(null);
      window.android.setStatusBarWhite(null);
    }
  };

  const checkAcceptedManager = async () => {
    try {
      if (!loginMember) {
        const {
          data: { isLogined },
        } = await isLogin();
        if (isLogined === false) {
          navigate(-1);
          return;
        } else {
          const member = await getMember();
          if (member.type !== 'customer') {
            CarsayoToast.error('일반 회원만 주문이 가능합니다.');
            navigate(-1);
            return;
          } else {
            initSellingStore();
          }
        }
      } else {
        if (loginMember.type !== 'customer') {
          CarsayoToast.error('일반 회원만 주문이 가능합니다.');
          navigate(-1);
          return;
        } else {
          initSellingStore();
        }
      }
    } catch (error: any) {
      CarsayoToast.error(error);
      navigate(-1);
      return;
    }
  };

  useLayoutEffect(() => {
    checkAcceptedManager();
    setTopTabbarState({
      state: 'fixed',
      mode: 'subpage',
      title: '내 차 팔기',
      className: 'fixed bg-transparent text-white',
      leftElement: (
        <ChevronLeft
          onClick={() => {
            if (window.native.onBackPressed) window.native.onBackPressed();
          }}
          className='h-7 w-7'
        ></ChevronLeft>
      ),
      rightElement: (
        <X
          onClick={() => {
            navigate(-1);
          }}
          className='h-7 w-7'
        ></X>
      ),
    });
    setIsBottomTabbar(false);
    setAndroidBar();

    return () => {
      resetSellingStore();
      if (window.android) resetAndroidBar();
      window.native.onBackPressed = () => {};
    };
  }, []);

  return (
    <>
      <Outlet />
    </>
  );
}
