import {
  InquirySubmitDTO,
  InquiryDetailInfo,
  InquiryShortInfo,
  InquiryIdDTO,
  InquiryUpdateDTO,
} from '@carsayo/types';
import { api } from 'apis';

export const inquirySubmit = async (
  reqData: InquirySubmitDTO,
): Promise<InquiryDetailInfo> => {
  try {
    const response = await api.post('inquiry/submit', {
      ...reqData,
    });
    const data: InquiryDetailInfo = response.data;
    return data;
  } catch (error: any) {
    throw new Error(error);
  }
};

export const getInquiryList = async (): Promise<InquiryShortInfo[]> => {
  try {
    const response = await api.get('inquiry/list');
    const data: InquiryShortInfo[] = response.data;
    return data;
  } catch (error: any) {
    throw new Error(error);
  }
};

export const getInquiryDetail = async (
  reqData: InquiryIdDTO,
): Promise<InquiryDetailInfo> => {
  try {
    const response = await api.get(`inquiry?inquiryId=${reqData.inquiryId}`);
    const data: InquiryDetailInfo = response.data;
    return data;
  } catch (error: any) {
    throw new Error(error);
  }
};

export const updateInquiry = async (
  reqData: InquiryUpdateDTO,
): Promise<InquiryDetailInfo> => {
  try {
    const response = await api.post('inquiry/update', reqData);
    const data: InquiryDetailInfo = response.data;
    return data;
  } catch (error: any) {
    throw new Error(error);
  }
};

export const deleteInquiry = async (reqData: InquiryIdDTO): Promise<void> => {
  try {
    await api.post('inquiry/delete', reqData);
    return;
  } catch (error: any) {
    throw new Error(error);
  }
};
