import React from 'react';

interface MyComponentProps {
  fill?: string;
  stroke?: string;
  width?: string;
  height?: string;
}

const RightArrow: React.FC<MyComponentProps> = ({
  fill,
  stroke,
  width,
  height,
}) => {
  return (
    <svg
      width={width || '16'}
      height={height || '16'}
      viewBox={`0 0 ${width || 16} ${height || 16}`}
      fill={fill || 'none'}
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M5.33329 2.66666L10.6666 8L5.33329 13.3333'
        stroke={stroke || '#767676'}
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};
export default RightArrow;
