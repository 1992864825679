/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState } from 'react';
import CarsayoCheckRadio from 'components/common/v2/CarsayoCheckRadio';
import {
  RegisterCar,
  RegisterCarIdDTO,
  RegisterCarUpdateDTO,
} from '@carsayo/types';
import dayjs from 'dayjs';
import { deleteRegisterCarApi, updateRegisterCarApi } from '../api';
import useMemberStore from 'store/useMemberStore';
import { CarsayoToast } from 'components/common/v2/CarsayoToast';
import useInsuranceDataStore from '../store/store';
import Confirm from 'components/ui/confirm';

interface CarBoxLayoutInterface {
  carData: RegisterCar;
  title: string;
  active: boolean;
  setActive: () => void;
  changeOpen: (data: RegisterCar) => void;
}

const CarBoxLayout = ({
  carData,
  title,
  active,
  setActive,
  changeOpen,
}: CarBoxLayoutInterface) => {
  const {
    carNumber,
    company,
    subscriptionDate,
    setInsuranceData,
    resetInsuranceData,
  } = useInsuranceDataStore();
  const { refreshLoginMember } = useMemberStore();
  const [representativeConfirmOpen, setRepresentativeConfirmOpen] =
    useState(false);
  const [deleteConfirmOpen, setDeleteConfirmOpen] = useState(false);
  const [selectData, setSelectData] = useState<RegisterCar | null>(null);

  const representativeSelect = async () => {
    if (selectData && selectData.id && selectData.insuranceCompany) {
      const reqData: RegisterCarUpdateDTO = {
        id: selectData.id,
        insurance_company_id: selectData.insuranceCompany.id,
        isRepresentative: !selectData.isRepresentative,
      };
      try {
        const result = await updateRegisterCarApi(reqData);
        if (result) {
          refreshLoginMember();
          CarsayoToast.success(
            `${selectData.carName} 차량을 대표 차량으로 설정했습니다.`,
          );
        }
      } catch (error: any) {
        console.error(error);
        CarsayoToast.error(error);
      }
    }
  };
  const deleteSelect = async () => {
    console.info('타겟 정보', selectData);
    if (selectData && selectData.id) {
      const reqData: RegisterCarIdDTO = {
        id: selectData.id,
      };
      try {
        await deleteRegisterCarApi(reqData);
        refreshLoginMember();
        resetInsuranceData();
        CarsayoToast.success(`차량정보를 삭제했습니다.`);
      } catch (error: any) {
        console.error(error);
        CarsayoToast.error(error);
      }
    }
  };
  const representativeConfirm = (data: RegisterCar) => () => {
    setSelectData(data);
    setRepresentativeConfirmOpen(!representativeConfirmOpen);
  };
  return (
    <div className='w-full border border-[#E5E5EC] rounded-[12px]'>
      <button
        className='w-full flex justify-between gap-[10px] items-center'
        onClick={setActive}
      >
        <div className='p-4 flex justify-start items-center gap-[10px]'>
          <span className='w-8 h-8 rounded-[6px] py-[10px] px-[5px] flex justify-center items-center bg-[#EEEEFF]'>
            <img
              src='/assets/images/v2/blue-car.svg'
              alt='차량이미지'
              className='h-[12px] object-contain'
            />
          </span>
          <div className='flex justify-start items-center gap-[10px]'>
            <strong className='text-[22px] leading-[22px] font-[600]'>
              {title ?? '차량번호'}
            </strong>
            <span
              className={`${carData.isRepresentative ? 'block' : 'hidden'} font-[500] text-[#5455EA] leading-[14px] py-[4px] px-[6px] rounded-[4px] bg-[#E6E6F6]`}
            >
              대표
            </span>
          </div>
        </div>
        <img
          src='/assets/images/v2/arrow-left.svg'
          alt='위쪽화살표'
          className={`mr-4 w-4 h-4 object-contain transition-all ${active ? 'rotate-[90deg]' : 'rotate-[270deg]'} `}
        />
      </button>
      <div
        className={`transition-all ${active ? 'w-full h-[auto] opacity-1 relative z-0' : 'h-0 left-[0] top-[-9999px] opacity-0 absolute z-[-999]'}`}
      >
        <div className='w-[calc(100%-32px)] mx-auto pt-[4px] pb-[20px] border-b-[1px] border-[#E5E5EC]'>
          <CarsayoCheckRadio
            label='대표 차량'
            checked={carData.isRepresentative}
            onClick={
              carData.isRepresentative
                ? () => {}
                : representativeConfirm(carData)
            }
            onChange={() => {}}
          />
        </div>
        <div className='w-[calc(100%-32px)] mx-auto py-[20px] border-b-[1px] border-[#E5E5EC]'>
          <h5 className='text-[16px] font-[600] leading-[16px] mb-[20px]'>
            보험사 정보
          </h5>
          <ul className='w-full flex flex-col justify-start items-start gap-[14px] pl-[10px]'>
            <li className='w-full flex justify-between items-center gap-[20px]'>
              <span className='text-[16px] leading-[16px] font-[400] text-[#555]'>
                보험사
              </span>
              <strong className='text-[16px] leading-[16px] font-[500]'>
                {company
                  ? company
                  : carData.insuranceCompany?.name ?? (
                      <span className='text-[#CCCCCC]'>보험사 없음</span>
                    )}
              </strong>
            </li>
            <li className='w-full flex justify-between items-center gap-[20px]'>
              <span className='text-[16px] leading-[16px] font-[400] text-[#555]'>
                보험 가입일
              </span>
              <strong className='text-[16px] leading-[16px] font-[500]'>
                {carData.insurance_start_date ? (
                  `${dayjs(carData.insurance_start_date).format('YYYY.MM.DD')}
                  (${dayjs(carData.insurance_expiration_date).diff(new Date(), 'day')}일 남음)`
                ) : (
                  <span className='text-[#CCCCCC]'>가입일 없음</span>
                )}
              </strong>
            </li>
          </ul>
        </div>
        <div className='w-[calc(100%-32px)] mx-auto py-[20px] pb-[24px]'>
          <h5 className='text-[16px] font-[600] leading-[16px] mb-[20px]'>
            긴급 출동 서비스
          </h5>
          <ul className='w-full flex flex-col justify-start items-start gap-[14px] pl-[10px]'>
            <li className='w-full flex justify-between items-center gap-[20px]'>
              <span className='text-[16px] leading-[16px] font-[400] text-[#555]'>
                긴급출동번호
              </span>
              <strong className='text-[16px] leading-[16px] font-[500]'>
                {carData.insuranceCompany?.direct.emergency_contact ? (
                  carData.insuranceCompany?.direct.emergency_contact.replace(
                    /(\d{4})(\d+)/,
                    '$1-$2',
                  )
                ) : (
                  <span className='text-[#CCCCCC]'>번호 없음</span>
                )}
              </strong>
            </li>
          </ul>
        </div>
        <div className='w-full py-[13px] bg-[#F6F6FC] rounded-b-[12px] overflow-hidden border-t-[1px] border-[#E5E5EC]'>
          <ul className='w-full flex justify-center items-center'>
            <li className='w-full border-r-[1px] border-[#E5E5EC] flex justify-center items-center py-[2px] px-[16px]'>
              <button
                className='block w-full text-[14px] font-[600] text-[#555]'
                onClick={() => {
                  setInsuranceData({
                    carNumber: carData.carName,
                    company: carData.insuranceCompany?.name ?? '',
                    subscriptionDate:
                      carData.insurance_start_date ?? new Date(),
                  });
                  changeOpen(carData);
                }}
              >
                수정
              </button>
            </li>
            <li className='w-full flex justify-center items-center py-[2px] px-[16px]'>
              <button
                className='block w-full text-[14px] font-[600] text-[#555]'
                onClick={() => {
                  setInsuranceData({
                    carNumber: carData.carName,
                    company: carData.insuranceCompany?.name ?? '',
                    subscriptionDate:
                      carData.insurance_start_date ?? new Date(),
                  });
                  setSelectData(carData);
                  setDeleteConfirmOpen(true);
                }}
              >
                삭제
              </button>
            </li>
          </ul>
        </div>
      </div>
      <Confirm
        isOpen={representativeConfirmOpen}
        setIsOpen={setRepresentativeConfirmOpen}
        isCloseButton={true}
        onConfirm={representativeSelect}
        title='대표 차량 설정'
        desc={`선택하신 차량을 대표차량으로 설정하시겠습니까?`}
        confirmText='설정'
      ></Confirm>
      <Confirm
        isOpen={deleteConfirmOpen}
        setIsOpen={setDeleteConfirmOpen}
        isCloseButton={true}
        onConfirm={deleteSelect}
        title='차량 삭제'
        desc={`선택하신 차량을 삭제하시겠습니까?\n해당 차량의 부가정보(보험, 사고 접수 등)가\n포함된 경우 모든 데이터도 함께 삭제됩니다.`}
        confirmText='삭제'
      ></Confirm>
    </div>
  );
};

export default CarBoxLayout;
