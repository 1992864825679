import { create } from 'zustand';
import { devtools } from 'zustand/middleware';
import { immer } from 'zustand/middleware/immer';

interface LocationInterface {
  /**
   * 실제 위치정보를 기반으로 가지고 있는 현재 위치 정보
   * @lat 위도값
   * @lng 경도값
   */
  originLocation: {
    lat: number | null;
    lng: number | null;
  };
  /**
   * 현재 위치 설정에서 바뀐 현재 위치 정보
   * @lat 위도값
   * @lng 경도값
   */
  currentLocation: {
    lat: number | null;
    lng: number | null;
  };
}

interface LocationAction {
  /**
   * 위치 정보를 사용하는 페이지에 들어설 때 현재 위치에 대한 original 정보로 넣어주세요!
   * @param lat 바꿀 위도값
   * @param lng 바꿀 경도값
   */
  setOriginLocation: ({ lat, lng }: { lat: number; lng: number }) => void;
  /**
   * 현재 위치 설정 페이지에서 바꾼 위도, 경도값을 가지고 있습니다.
   * @param lat 바꿀 위도값
   * @param lng 바꿀 경도값
   */
  setCurrentLocation: ({ lat, lng }: { lat: number; lng: number }) => void;
}
const useCurrentLocationStore = create<LocationInterface & LocationAction>()(
  devtools(
    immer((set) => ({
      originLocation: {
        lat: null,
        lng: null,
      },
      currentLocation: {
        lat: null,
        lng: null,
      },
      setOriginLocation: ({ lat, lng }: { lat: number; lng: number }) => {
        set((state) => {
          state.originLocation.lat = lat;
          state.originLocation.lng = lng;
        });
      },
      setCurrentLocation: ({ lat, lng }: { lat: number; lng: number }) => {
        set((state) => {
          state.currentLocation.lat = lat;
          state.currentLocation.lng = lng;
        });
      },
    })),
    {
      name: 'myLocationStore',
    },
  ),
);

export default useCurrentLocationStore;
