import classNames from 'classnames';
import React, { ButtonHTMLAttributes } from 'react';
import Ripples from 'react-ripples';
import darkDotLoading from '../../lottie/darkDotLoading.json';
import Lottie from 'lottie-react';

interface CarsayoButtonInterface
  extends Omit<
    ButtonHTMLAttributes<HTMLButtonElement>,
    'value' | 'className' | 'type'
  > {
  color?: 'primary' | 'secondary' | 'purpleBlue';
  className?: string;
  isRipples?: boolean;
  isLoadingDim?: boolean;
  children?: React.ReactNode;
}

const CarsayoButton = ({
  className,
  color = 'primary',
  isRipples = true,
  isLoadingDim,
  children,
  ...rest
}: CarsayoButtonInterface) => {
  const { disabled, ...otherProps } = rest;
  const buttonElement = (
    <button
      type='button'
      className={classNames(
        'disabled:opacity-50 cursor-pointer',
        'justify-center items-center',
        'text-[13px] ',
        'w-full h-[50px] disabled:bg-[#CCC] rounded-lg',
        'flex justify-normal items-center text-[16px] disabled:cursor-not-allowed disabled:border-0',
        {
          'bg-[#333] text-white': color === 'primary',
          'bg-white  border border-[#333]': color === 'secondary',
          'bg-subColor-purpleBlue text-[#fff] border border-subColor-purpleBlue':
            color === 'purpleBlue',
        },
        className,
      )}
      disabled={disabled || isLoadingDim}
      {...otherProps}
    >
      {isLoadingDim ? (
        <Lottie
          animationData={darkDotLoading}
          loop={true}
          className='h-[180%]'
        />
      ) : (
        children
      )}
    </button>
  );

  return isRipples ? (
    <Ripples
      placeholder={undefined}
      className='w-full overflow-hidden rounded-lg'
      onPointerEnterCapture={undefined}
      onPointerLeaveCapture={undefined}
    >
      {buttonElement}
    </Ripples>
  ) : (
    buttonElement
  );
};

export default CarsayoButton;
