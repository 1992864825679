/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  carGipsaDescription,
  carGipsaMenuList,
  carGongDescription,
  carGongMenuList,
  carkeyDescription,
  carkeyMenuList,
} from './data';
import {
  ChangeEvent,
  useEffect,
  useLayoutEffect,
  useMemo,
  useState,
} from 'react';
import AskMainCardLayout from './component/AskMainCard';
import CarsayoSelectBox from './component/CarsayoSelectbox';
import { CarDataInterface } from './ask';
import { useNavigate } from 'react-router-dom';
import useSystemStore from 'store/useSystemStore';
import {
  InsuranceCompany,
  RegisterCar,
  RegisterCarCreateDTO,
} from '@carsayo/types';
import useMemberStore from 'store/useMemberStore';
import { addRegisterCarApi, getInsuranceDataApi } from './api';
import useInsuranceDataStore from './store/store';
import {
  Drawer,
  DrawerContent,
  DrawerHeader,
  DrawerTitle,
} from 'components/ui/drawer';
import { Button } from 'components/ui/button';
import dayjs from 'dayjs';
import { X } from 'lucide-react';
import CarsayoButton from 'components/common/CarsayoButton';
import { CarsayoToast } from 'components/common/v2/CarsayoToast';
import CarsayoBottomSheet from 'components/common/CarsayoBottomSheet';
import DatePicker from 'react-mobile-datepicker-ts';
import useInsuranceOriginDataStore from './store/dataStore';
import useCurrentLocationStore from 'store/useCurrentLocation';

const AskMain = () => {
  const { originInsuranceData, setOriginInsuranceData } =
    useInsuranceOriginDataStore();
  const { loginMember, refreshLoginMember } = useMemberStore();
  const { setTopTabbarState, setIsBottomTabbar } = useSystemStore();
  const { setOriginLocation, setCurrentLocation } = useCurrentLocationStore();
  const navigate = useNavigate();
  const [carData, setCarData] = useState<RegisterCar | null>(null);
  const carFullData: RegisterCar[] | undefined = useMemo(() => {
    return loginMember?.customerInfo?.registerCar.list;
  }, [loginMember]);

  const [insuranceDrawerOpen, setInsuranceDrawerOpen] = useState(false);
  // datepicker 관련
  const minDate = dayjs().subtract(20, 'year').startOf('day').toDate();
  const maxDate = dayjs().add(20, 'year').endOf('day').toDate();
  const [isDatePickerOpen, setIsDatePickerOpen] = useState(false);
  // 보험만기일 등록 관련
  const [isInsuranceCompany, setIsInsuranceCompany] = useState(false);
  const [insuranceDate, setInsuranceDate] = useState<Date>(new Date());

  const carDataChange = (value: string) => {
    if (value && carFullData) {
      const selectData = carFullData.find((item) => item.carName === value);
      if (selectData) {
        setCarData(selectData);
      } else {
        setCarData(null);
      }
    }
  };

  const getInsuranceData = async () => {
    const data = await getInsuranceDataApi();
    if (data) {
      setOriginInsuranceData(data);
    }
  };

  const handleDateChange = (date: Date) => {
    setInsuranceDate(date);
  };

  /** 보험 만기일 등록하는 drawer
   * 컴포넌트로 분리했다가 날짜선택때문에 이 페이지에 넣게 되었습니다. 추후 날짜 선택 컴포넌트 변경시 같이 변경해주면 좋을 것 같습니다.
   *  */
  const InsuranceAddBox = () => {
    const {
      carNumber,
      subscriptionDate,
      company,
      setCarNumber,
      setCompany,
      setInsuranceData,
      resetInsuranceData,
    } = useInsuranceDataStore();
    const [insuranceCompany, setInsuranceCompany] = useState<string | null>(
      company ? company : null,
    );
    const [insuranceCarNumber, setInsuranceCarNumber] = useState('');

    const insuranceCarNumberChange = (event: ChangeEvent<HTMLInputElement>) => {
      setInsuranceCarNumber(event.target.value);
    };
    const insuranceDataAdd = async () => {
      console.info('최종확인 : ', carNumber, insuranceDate, company);
      const companyId = originInsuranceData.find(
        (item) => item.name === company,
      )?.id;
      if (company && carNumber && insuranceDate && companyId) {
        const reqData: RegisterCarCreateDTO = {
          carName: carNumber,
          insurance_start_date: insuranceDate,
          insurance_company_id: companyId,
          isRepresentative: true,
        };
        try {
          const result = await addRegisterCarApi(reqData);
          if (result) {
            CarsayoToast.success('차량이 정상적으로 등록되었습니다.');
            resetInsuranceData();
            refreshLoginMember();
            setInsuranceDrawerOpen(false);
            resetInsuranceData();
            getInsuranceData();
          } else {
            setInsuranceDrawerOpen(false);
            resetInsuranceData();
            refreshLoginMember();
            resetInsuranceData();
          }
        } catch (error: any) {
          CarsayoToast.error(error);
        }
      }
    };

    return (
      <div>
        <Drawer open={insuranceDrawerOpen}>
          <DrawerContent className='mx-auto flex max-h-[90vh] w-full max-w-sm flex-col'>
            <DrawerHeader className='relative'>
              <DrawerTitle className='text-center text-lg font-semibold'>
                {isInsuranceCompany ? '보험사 선택' : '차량 등록 정보'}
              </DrawerTitle>
              <Button
                onClick={
                  isInsuranceCompany
                    ? () => setIsInsuranceCompany(!isInsuranceCompany)
                    : () => {
                        setInsuranceDrawerOpen(!insuranceDrawerOpen);

                        resetInsuranceData();
                      }
                }
                className='absolute right-5 top-4 h-7 w-7 border-0 bg-transparent p-0 text-[#111111]'
              >
                <X className='h-7 w-7' />
              </Button>
            </DrawerHeader>
            {isInsuranceCompany ? (
              // 보험사 선택
              <div className='p-4 pt-0'>
                <div className='flex justify-between items-end pt-[10px] pb-[20px] border-b-[1px] border-[#F1F1F5]'>
                  <div>
                    <h4 className='text-[16px] leading-[24px] font-[600] mb-[8px]'>
                      보험사를 선택해주세요
                    </h4>
                    <p className='text-[14px] leading-[20px]'>
                      고객님을 위한 보험 만기 알림 및 긴급출동 등 특별한 카
                      라이프에 카집사가 함께 하겠습니다.
                    </p>
                  </div>
                  <img
                    src='/assets/images/v2/ask-carGipsa02.svg'
                    alt='카집사 이미지02'
                  />
                </div>
                <div>
                  <ul className='brandAllCheck mt-4 grid grid-cols-3 gap-x-[21.5px] gap-y-4 mb-[20px]'>
                    {originInsuranceData.map((el, index) => {
                      const targetCompanyName = el.name;
                      const targetCompanyData = el;
                      return (
                        <li
                          key={`${targetCompanyName}_${index}`}
                          className={`group relative h-[88px] rounded-xl border bg-[#fff] py-4 ${targetCompanyName === insuranceCompany ? 'border-primary' : ''}`}
                        >
                          <label
                            htmlFor={`insuranceRadio_${targetCompanyName}`}
                          >
                            <input
                              type='radio'
                              id={`insuranceRadio_${targetCompanyName}`}
                              value={targetCompanyName}
                              className='absolute h-0 w-0 hidden'
                              checked={targetCompanyName === insuranceCompany}
                              onChange={(e: ChangeEvent<HTMLInputElement>) => {
                                setInsuranceCompany(e.target.value);
                              }}
                            />
                            <div className='mx-auto h-9 w-9'>
                              <img
                                src={targetCompanyData.logo_url}
                                alt={`보험사 로고_${targetCompanyName}`}
                              />
                            </div>
                            <div
                              className={`mt-2 text-[#111]  px-1 overflow-hidden text-ellipsis whitespace-nowrap text-center text-[13px] ${targetCompanyName === insuranceCompany ? 'font-[500]' : 'font-[400]'} `}
                            >
                              {targetCompanyName}
                            </div>
                          </label>
                        </li>
                      );
                    })}
                  </ul>
                </div>
                <CarsayoButton
                  disabled={!insuranceCompany}
                  color='purpleBlue'
                  onClick={() => {
                    setCompany(insuranceCompany ?? 'KB손해보험');
                    setIsInsuranceCompany(!isInsuranceCompany);
                  }}
                >
                  보험사 선택
                </CarsayoButton>
              </div>
            ) : (
              // 보험 만기일 등록
              <div className='p-4 pt-0'>
                <div className='flex justify-between items-end pt-[10px] pb-[20px] border-b-[1px] border-[#F1F1F5] mb-[20px]'>
                  <div>
                    <h4 className='text-[16px] leading-[24px] font-[600] mb-[8px]'>
                      가입한 보험을 입력해주세요
                    </h4>
                    <p className='text-[14px] leading-[20px]'>
                      고객님을 위한 보험 만기 알림 및 긴급출동 등 특별한 카
                      라이프에 카집사가 함께 하겠습니다.
                    </p>
                  </div>
                  <img
                    src='/assets/images/v2/ask-carGipsa.svg'
                    alt='카집사 이미지'
                  />
                </div>
                <div className='w-full flex flex-col justify-start items-start gap-6 mb-6'>
                  <div className='w-full'>
                    <label
                      htmlFor='insuranceCarNumber'
                      className='block text-[15px] leading-[22px] mb-[6px]'
                    >
                      차량번호
                    </label>
                    <input
                      type='text'
                      id='insuranceCarNumber'
                      className='w-full block py-[14px] px-[16px] border border-[#E5E5EC] rounded-[6px] text-[16px] leading-[24px]'
                      placeholder='차량 번호를 입력해주세요. (ex. 12가1234)'
                      onChange={insuranceCarNumberChange}
                      onBlur={() => {
                        setCarNumber(insuranceCarNumber);
                      }}
                      value={
                        insuranceCarNumber.length > 0
                          ? insuranceCarNumber
                          : carNumber
                      }
                    />
                  </div>
                  <div className='w-full'>
                    <label
                      htmlFor='insuranceCompany'
                      className='block text-[15px] leading-[22px] mb-[6px]'
                    >
                      보험사
                    </label>
                    <button
                      className='w-full block py-[14px] px-[16px] border border-[#E5E5EC] rounded-[6px] text-[16px] leading-[24px]'
                      onClick={() => setIsInsuranceCompany(!isInsuranceCompany)}
                    >
                      <div className='flex justify-between items-center'>
                        {company ? (
                          <div className='flex justify-start items-center gap-[10px]'>
                            <img
                              src={
                                originInsuranceData.find(
                                  (item) => item.name === company,
                                )?.logo_url
                              }
                              alt={`${company}_로고이미지`}
                              className='w-[20px] object-contain'
                            />
                            <span className='text-[#111] text-[16px] leading-[24px]'>
                              {company}
                            </span>
                          </div>
                        ) : (
                          <div className='flex justify-start items-center gap-[10px]'>
                            <img
                              src='/assets/images/png/symbol-default.png'
                              alt='카사요심볼회색'
                              className='w-[20px] object-contain'
                            />
                            <span className='text-[#767676] text-[16px] leading-[24px]'>
                              보험사를 선택해주세요
                            </span>
                          </div>
                        )}

                        <img
                          src='/assets/images/v2/arrow-left.svg'
                          alt='우측 화살표'
                          className='rotate-[180deg] w-4 h-4 object-contain'
                        />
                      </div>
                    </button>
                  </div>
                  <div className='w-full'>
                    <label
                      htmlFor='insuranceDate'
                      className='block text-[15px] leading-[22px] mb-[6px]'
                    >
                      보험 가입일
                    </label>
                    <div className='relative'>
                      <img
                        src='/assets/images/png/calendar.png'
                        alt='달력 아이콘'
                        className='absolute left-4 top-0 bottom-0 my-auto'
                      />
                      <input
                        onClick={() => {
                          setInsuranceDrawerOpen(!insuranceDrawerOpen);
                          setIsDatePickerOpen(!isDatePickerOpen);
                        }}
                        readOnly
                        type='text'
                        id='insuranceDate'
                        className='w-full block py-[14px] px-[16px] pl-[48px] border border-[#E5E5EC] rounded-[6px] text-[16px] leading-[24px]'
                        value={dayjs(insuranceDate).format('YYYY/MM/DD')}
                      />
                    </div>
                  </div>
                </div>

                <CarsayoButton
                  disabled={
                    carNumber && subscriptionDate && company ? false : true
                  }
                  color='purpleBlue'
                  onClick={insuranceDataAdd}
                >
                  등록
                </CarsayoButton>
              </div>
            )}
          </DrawerContent>
        </Drawer>
      </div>
    );
  };

  useLayoutEffect(() => {
    setTopTabbarState({
      state: 'fixed',
      mode: 'main',
      title: '물어보기',
      rightElement: 'home',
    });
    setIsBottomTabbar(true);
    getInsuranceData();

    setOriginLocation({
      lat: 37.5461181757326,
      lng: 127.050040830902,
    });
  }, []);

  useLayoutEffect(() => {
    if (carFullData && carFullData.length > 0) {
      carFullData.map((item) => {
        if (item.isRepresentative) {
          setCarData(item);
        } else {
          setCarData(carFullData[0]);
        }
      });
    }
  }, [carFullData]);

  useEffect(() => {
    console.info(
      '데이터 변경 확인 : ',
      loginMember?.customerInfo?.registerCar.list,
    );
  }, [loginMember]);

  return (
    <div className='bg-[#E5E5EC] pb-[84px]'>
      <div className='w-[calc(100%-40px)] mx-auto py-[10px] flex justify-between items-center'>
        <div className='flex flex-col justify-start items-start gap-[13px]'>
          <h3 className='flex flex-col text-[18px] leading-[21.6px] font-[500]'>
            <span>Car Heroes</span>
            <span>Line-up Collection</span>
          </h3>
          <button
            className='flex justify-start items-center'
            onClick={() => navigate('/static/character')}
          >
            <span className='font-[500] text-[#555555]'>
              캐릭터 스토리 보기
            </span>
            <img src='/assets/images/v2/right-arrow.svg' alt='우측반쪽화살표' />
          </button>
        </div>
        <img
          className='h-full object-contain'
          src='/assets/images/v2/car-heroes.svg'
          alt='카사요 히어로즈 이미지'
        />
      </div>
      <section className='mb-[10px] w-[calc(100%-40px)] mx-auto p-4 bg-white rounded-[12px] shadow-[0px_10px_20px_#1111110d]'>
        <div className='w-full flex justify-between items-center mb-[20px]'>
          <div
            className='flex justify-start items-center gap-[6px]'
            onClick={() => navigate('/ask/carManagement/main')}
          >
            <h3 className='text-[20px] font-[600] leading-[20px]'>차량 관리</h3>
            <img
              src='/assets/images/v2/arrowInCircleDefault.svg'
              alt='원안의화살표이미지'
            />
          </div>
          <span>
            내 차량
            <strong className='ml-1'>
              {(carFullData && carFullData.length) ?? 0}
            </strong>
            대
          </span>
        </div>
        {carData ? (
          <>
            <div className='w-full pb-[20px] border-b-[1px] border-[#E5E5EC]'>
              <CarsayoSelectBox
                value={carData.carName}
                onValueChange={carDataChange}
                placeholder={`${carData}`}
                fullData={carFullData ?? []}
                selectedData={carData}
              />
            </div>
            <div className='w-full pt-[20px]'>
              <ul className='w-full flex flex-col gap-[14px]'>
                <li className='w-full flex justify-between items-center'>
                  <span>보험사</span>
                  <strong>
                    {/* {carFullData
                      .filter((item) => item.carNumber === carData)
                      .map((data) => data.insurance)} */}
                    {carData.insuranceCompany?.name ?? '보험사 없음'}
                  </strong>
                </li>
                <li className='w-full flex justify-between items-center'>
                  <span>긴급출동</span>
                  <strong>
                    {/* {carFullData
                      .filter((item) => item.carNumber === carData)
                      .map((data) => data.emergencyContact)} */}
                    {carData.insuranceCompany?.direct.emergency_contact ? (
                      carData.insuranceCompany?.direct.emergency_contact.replace(
                        /(\d{4})(\d+)/,
                        '$1-$2',
                      )
                    ) : (
                      <span className='text-[#CCCCCC]'>번호 없음</span>
                    )}
                  </strong>
                </li>
              </ul>
            </div>
          </>
        ) : (
          <button
            className='w-full border border-[#E5E5EC ] bg-[#F6F6FC] rounded-[10px] h-[142px] flex flex-col justify-center items-center'
            onClick={() => {
              setInsuranceDate(new Date());
              setInsuranceDrawerOpen(true);
            }}
          >
            <img
              src='/assets/images/v2/add-circle.svg'
              alt='추가아이콘'
              className='mb-[10px]'
            />
            <span className='text-[#888888]'>차량 정보를 등록해주세요</span>
          </button>
        )}
      </section>
      <section className='w-full flex flex-col justify-start items-center gap-[10px] py-[10px] px-[20px]'>
        <AskMainCardLayout
          bgColor='bg-gradient-to-r from-[#44A375] to-[#337053]'
          characterImg='/assets/images/v2/ask-character01.svg'
          listBg='bg-[#2A7450]'
          description={carkeyDescription}
          menuList={carkeyMenuList}
          rightPadding={'mr-[4px]'}
          redirectAction={() => {
            navigate('/ask/theme/carkey');
          }}
          title='카키에게 물어보기'
        />
        <AskMainCardLayout
          bgColor='bg-gradient-to-r from-[#8B755E] to-[#605041]'
          characterImg='/assets/images/v2/ask-character02.svg'
          listBg='bg-[#64503E]'
          description={carGongDescription}
          menuList={carGongMenuList}
          redirectAction={() => {
            navigate('/ask/theme/cargong');
          }}
          title='카공이에게 물어보기'
        />
        <AskMainCardLayout
          bgColor='bg-gradient-to-r from-[#6F8FEC] to-[#375ECD]'
          characterImg='/assets/images/v2/ask-character03.svg'
          listBg='bg-[#3A5ECB]'
          description={carGipsaDescription}
          menuList={carGipsaMenuList}
          rightPadding={'mr-[-8px]'}
          bottomPadding={'mb-[-8px]'}
          redirectAction={() => {
            navigate('/ask/theme/cargipsa');
          }}
          title='카집사에게 물어보기'
        />
      </section>
      <InsuranceAddBox />
      {/* 보험 등록일 선택하는 datepicker가 drawer안에서 동작을 안해서 밖으로 뺐습니다. 추후 수정이 필요합니다. */}
      <CarsayoBottomSheet
        isOpen={isDatePickerOpen}
        onClose={() => {
          setInsuranceDate(new Date());
          setIsDatePickerOpen(false);
          setInsuranceDrawerOpen(true);
        }}
      >
        <div className={`w-full relative min-h-[100px]`}>
          <DatePicker
            value={insuranceDate}
            isOpen={isDatePickerOpen}
            onChange={handleDateChange}
            showHeader={false}
            showFooter={false}
            min={minDate ?? undefined}
            max={maxDate ?? undefined}
          />
          <button
            onClick={() => {
              setIsDatePickerOpen(false);
              setInsuranceDrawerOpen(true);
            }}
            className='max-h-[50px] w-full py-[14px] px-4 bg-[#7273F9] text-white rounded-[6px] absolute bottom-0'
          >
            확인
          </button>
        </div>
      </CarsayoBottomSheet>
    </div>
  );
};
export default AskMain;
