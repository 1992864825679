/* eslint-disable prefer-const */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { CarOption } from '@carsayo/types';

type RequestData = {
  /** 변경할 옵션 리스트 */
  optionList: CarOption[];
  /** 선택한 옵션 */
  selectedOption: CarOption;
  /**
   * @true 선택 시
   * @false 선택 해제 시
   */
  isAdded: boolean;
  optionData: CarOption[];
  option: {
    /** 첫 실행시에 true, 재귀적 실행 시 false */
    isInit: boolean;
  };
};
type ExtendedRequestData = RequestData & {
  includeOrderIdData: { [key: number]: number[] };
};

type ValidationData = {
  error: boolean;
  errorMessage: string[];
};

type Suggestion = {
  optionList: CarOption[];
  message: string[];
};

/** NumberArray 중복 제거 */
function removeDuplicates(arr: number[]): number[] {
  return Array.from(new Set(arr));
}

function getOptionFromOptiondata(params: {
  optionId: number;
  data: CarOption[];
}): CarOption | undefined {
  return params.data.find((el) => {
    return el.id === params.optionId;
  });
}

/** 최상위 노드별 내부 모든 Include 노드 id 리스트 */
function parseWholeIncludeOptionId(params: {
  /** 검색할 옵션 아이디 리스트 */
  includedOptionIdList: number[];

  /** 옵션 원시데이터 */
  optionData: CarOption[];
  /** 함수 시작 시점 여부 */
  init: boolean;
  /** 내부 탐색 횟수입니다. */
  depth: number;
}): { [key: number]: number[] } {
  const data: { [key: number]: number[] } = {};

  let totalIncludedOrderIdList: number[] = params.includedOptionIdList;
  for (let i = 0; i < params.includedOptionIdList.length; i++) {
    const currentOptionId = params.includedOptionIdList[i];
    const targetOption = getOptionFromOptiondata({
      optionId: currentOptionId,
      data: params.optionData,
    });
    if (!targetOption || !targetOption.relation) continue;

    totalIncludedOrderIdList = totalIncludedOrderIdList.concat(
      getTotalIncludedOptionId({
        option: targetOption,
        data: params.optionData,
        depth: 0,
      }),
    );
  }
  totalIncludedOrderIdList = removeDuplicates(totalIncludedOrderIdList);

  for (let i = 0; i < params.includedOptionIdList.length; i++) {
    const currentOptionId = params.includedOptionIdList[i];
    const targetOption = getOptionFromOptiondata({
      optionId: currentOptionId,
      data: params.optionData,
    });

    if (
      !targetOption?.relation?.include ||
      targetOption?.relation?.include.length === 0
    )
      continue;

    if (
      isHighestNode({
        option: targetOption,
        totalIncludedOrderIdList: totalIncludedOrderIdList,
        data: params.optionData,
      })
    ) {
      /** 얘는 최상위 노드입니다. */
      data[targetOption.id] = getTotalIncludedOptionId({
        option: targetOption,
        data: params.optionData,
        depth: 0,
      });
    }
  }

  return data;
}

/** 옵션 내 모든 포함 옵션 리스트를 가져옵니다. */
function getTotalIncludedOptionId(params: {
  option: CarOption;
  data: CarOption[];
  depth: number;
}): number[] {
  let returnData: number[] = [];

  if (
    !params.option.relation?.include ||
    params.option.relation?.include.length === 0
  ) {
    return returnData;
  }

  returnData = params.option.relation.include;

  if (params.depth > 6) return returnData;

  for (let i = 0; i < params.option.relation.include.length; i++) {
    const outerOption = getOptionFromOptiondata({
      optionId: params.option.relation.include[i],
      data: params.data,
    });
    if (!outerOption) return [];

    const innerIncludeList = getTotalIncludedOptionId({
      option: outerOption,
      data: params.data,
      depth: params.depth + 1,
    });

    returnData = returnData.concat(innerIncludeList);
  }

  returnData = removeDuplicates(returnData);

  return returnData;
}

/** 옵션 내 모든 상위 옵션 리스트를 가져옵니다. */
function getTotalParentOptionId(params: {
  option: CarOption;
  data: CarOption[];
  depth: number;
}): number[] {
  let returnData: number[] = [];

  if (
    !params.option.relation?.parent ||
    params.option.relation?.parent.length === 0
  ) {
    return returnData;
  }

  returnData = params.option.relation.parent;

  if (params.depth > 6) return returnData;

  for (let i = 0; i < params.option.relation.parent.length; i++) {
    const outerOption = getOptionFromOptiondata({
      optionId: params.option.relation.parent[i],
      data: params.data,
    });
    if (!outerOption) return [];

    const innerIncludeList = getTotalParentOptionId({
      option: outerOption,
      data: params.data,
      depth: params.depth + 1,
    });

    returnData = returnData.concat(innerIncludeList);
  }

  returnData = removeDuplicates(returnData);

  return returnData;
}

/** 해당 옵션이 선택된 옵션중 최상위 옵션인지(parent가 선택되지 않았는지) 확인합니다. */
function isHighestNode(params: {
  option: CarOption;
  totalIncludedOrderIdList: number[];
  data: CarOption[];
}): boolean {
  if (
    !params.option.relation?.parent ||
    params.option.relation?.parent.length === 0
  )
    return true;

  for (let i = 0; i < params.option.relation.parent.length; i++) {
    if (
      params.totalIncludedOrderIdList.includes(params.option.relation.parent[i])
    )
      return false;
  }

  return true;
}

/** 하위 항목으로 상위 항목이 조합 가능한지 여부를 확인합니다. */
function isCombinable(params: {
  option: CarOption;
  totalIncludedOrderIdList: number[];
  data: CarOption[];

  depth: number;
}): boolean {
  if (
    !params.option.relation?.include ||
    params.option.relation.include.length === 0
  ) {
    if (params.totalIncludedOrderIdList.includes(params.option.id)) return true;
    else return false;
  }

  for (let i = 0; i < params.option.relation.include.length; i++) {
    const targetIncludeOptionId = params.option.relation.include[i];
    const targetOption = getOptionFromOptiondata({
      optionId: targetIncludeOptionId,
      data: params.data,
    });

    if (!targetOption) return false;
    if (params.depth > 6) {
      return false;
    }

    if (params.totalIncludedOrderIdList.includes(targetIncludeOptionId))
      continue;

    const isTargetOptionCombinable = isCombinable({
      option: targetOption,
      data: params.data,
      depth: params.depth + 1,
      totalIncludedOrderIdList: params.totalIncludedOrderIdList,
    });
    if (isTargetOptionCombinable === false) return false;
  }

  return true;
}

type CheckValidityReturn = {
  error: boolean;
  message: string[];
};
const checkValidity: {
  [key: string]: (params: ExtendedRequestData) => CheckValidityReturn;
} = {
  include: (params: ExtendedRequestData): CheckValidityReturn => {
    const returnData: CheckValidityReturn = {
      error: false,
      message: [],
    };

    for (let i = 0; i < Object.keys(params.includeOrderIdData).length; i++) {
      const targetOptionId = Number(Object.keys(params.includeOrderIdData)[i]);
      const targetOption = getOptionFromOptiondata({
        optionId: targetOptionId,
        data: params.optionData,
      });
      if (!targetOption) continue;

      for (let j = 0; j < params.optionList.length; j++) {
        if (
          params.includeOrderIdData[targetOptionId].includes(
            params.optionList[j].id,
          )
        ) {
          returnData.error = true;
          returnData.message = [
            ...returnData.message,
            `[${params.optionList[j].name}]옵션은 [${targetOption?.name}]옵션에 포함되어 있습니다.`,
          ];
        }
      }
    }

    return returnData;
  },
  incompatible: (params: ExtendedRequestData): CheckValidityReturn => {
    const returnData: CheckValidityReturn = {
      error: false,
      message: [],
    };

    let totalIncompatibleOrderList: number[] = [];
    params.optionList.forEach((el) => {
      if (el.relation?.incompatible && el.relation.incompatible.length > 0) {
        totalIncompatibleOrderList = totalIncompatibleOrderList.concat(
          el.relation.incompatible,
        );
      }
    });
    totalIncompatibleOrderList = removeDuplicates(totalIncompatibleOrderList);

    let totalIncludedOrderIdList: number[] = [
      ...params.optionList.map((el) => {
        return el.id;
      }),
    ];

    for (let i = 0; i < params.optionList.length; i++) {
      const currentOptionId = params.optionList[i].id;
      const targetOption = getOptionFromOptiondata({
        optionId: currentOptionId,
        data: params.optionData,
      });
      if (!targetOption || !targetOption.relation) continue;

      totalIncludedOrderIdList = totalIncludedOrderIdList.concat(
        getTotalIncludedOptionId({
          option: targetOption,
          data: params.optionData,
          depth: 0,
        }),
      );
    }

    for (let i = 0; i < totalIncompatibleOrderList.length; i++) {
      if (totalIncludedOrderIdList.includes(totalIncompatibleOrderList[i])) {
        returnData.error = true;

        const crashedOption = getOptionFromOptiondata({
          optionId: totalIncompatibleOrderList[i],
          data: params.optionData,
        });
        if (!crashedOption) continue;

        /** 에러 메시지 생성 */
        if (
          params.isAdded === false ||
          !params.selectedOption.relation?.incompatible.includes(
            crashedOption.id,
          )
        )
          continue;

        if (
          params.optionList
            .map((el) => {
              return el.id;
            })
            .includes(crashedOption.id)
        ) {
          returnData.message = [
            ...returnData.message,
            `[${crashedOption.name}]옵션과 동시 선택될 수 없습니다.`,
          ];
        } else {
          for (
            let h = 0;
            h < Object.keys(params.includeOrderIdData).length;
            h++
          ) {
            const targetOptionId = Number(
              Object.keys(params.includeOrderIdData)[h],
            );
            const targetOption = getOptionFromOptiondata({
              optionId: targetOptionId,
              data: params.optionData,
            });
            if (!targetOption) continue;
            if (
              params.includeOrderIdData[targetOptionId].includes(
                crashedOption.id,
              )
            ) {
              returnData.message = [
                ...returnData.message,
                `[${targetOption.name}]옵션과 동시 선택될 수 없습니다.`,
              ];
            }
          }
        }
      }
    }

    return returnData;
  },
  nessary: (params: ExtendedRequestData): CheckValidityReturn => {
    const returnData: CheckValidityReturn = {
      error: false,
      message: [],
    };

    let totalNessaryOrderList: number[] = [];
    params.optionList.forEach((el) => {
      if (el.relation?.nessary && el.relation.nessary.length > 0) {
        totalNessaryOrderList = totalNessaryOrderList.concat(
          el.relation.nessary,
        );
      }
    });
    totalNessaryOrderList = removeDuplicates(totalNessaryOrderList);
    totalNessaryOrderList = totalNessaryOrderList.filter((el1) => {
      if (
        params.optionData
          .map((el2) => {
            return el2.id;
          })
          .includes(el1)
      ) {
        return true;
      } else return false;
    });

    let totalIncludedOrderIdList: number[] = [
      ...params.optionList.map((el) => {
        return el.id;
      }),
    ];

    for (let i = 0; i < params.optionList.length; i++) {
      const currentOptionId = params.optionList[i].id;
      const targetOption = getOptionFromOptiondata({
        optionId: currentOptionId,
        data: params.optionData,
      });
      if (!targetOption || !targetOption.relation) continue;

      totalIncludedOrderIdList = totalIncludedOrderIdList.concat(
        getTotalIncludedOptionId({
          option: targetOption,
          data: params.optionData,
          depth: 0,
        }),
      );
    }

    const messageTargetList: number[] = [];

    label: for (let i = 0; i < totalNessaryOrderList.length; i++) {
      if (!totalIncludedOrderIdList.includes(totalNessaryOrderList[i])) {
        returnData.error = true;

        const crashedOption = getOptionFromOptiondata({
          optionId: totalNessaryOrderList[i],
          data: params.optionData,
        });
        if (!crashedOption) continue;

        if (
          !params.optionList
            .map((el) => {
              return el.id;
            })
            .includes(crashedOption.id)
        ) {
          for (let j = 0; j < params.optionList.length; j++) {
            const target = params.optionList[j];
            if (messageTargetList.includes(target.id)) continue label;
            if (
              target.relation?.nessary &&
              target.relation.nessary.includes(crashedOption.id)
            ) {
              if (params.isAdded === true) {
                returnData.message = [
                  ...returnData.message,
                  `[${crashedOption.name}]옵션이 선택되어야 선택할 수 있습니다.`,
                ];
              } else {
                returnData.message = [
                  ...returnData.message,
                  `[${target.name}]옵션이 선택 해제되어야 합니다.`,
                ];
              }
              messageTargetList.push(target.id);
              continue label;
            }
          }
        }

        if (params.isAdded === true) {
          returnData.message = [
            ...returnData.message,
            `[${crashedOption.name}]옵션이 선택되어야 선택할 수 있습니다.`,
          ];
        } else {
          returnData.message = [
            ...returnData.message,
            `[${crashedOption.name}]옵션이 선택 해제될 수 없습니다.`,
          ];
        }
      }
    }

    return returnData;
  },
};

export function optionValidation(params: RequestData): {
  validation: ValidationData;
  suggestion: Suggestion | null;
} {
  const validation = checkOptionValidity(params);
  const suggestion = createSuggestion(params, validation);

  return {
    validation: validation,
    suggestion: suggestion,
  };
}

/** 유효성 검사 */
export function checkOptionValidity(params: RequestData): ValidationData {
  const returnData: ValidationData = { error: false, errorMessage: [] };

  const totalIncludedOrderIdList: { [key: number]: number[] } =
    parseWholeIncludeOptionId({
      includedOptionIdList: params.optionList.map((el) => {
        return el.id;
      }),
      optionData: params.optionData,
      init: true,
      depth: 0,
    });

  const extendedRequestData: ExtendedRequestData = {
    ...params,
    includeOrderIdData: totalIncludedOrderIdList,
  };

  const validityResults = {
    include: checkValidity.include(extendedRequestData),
    incompatible: checkValidity.incompatible(extendedRequestData),
    nessary: checkValidity.nessary(extendedRequestData),
  };

  if (
    validityResults.include.error ||
    validityResults.incompatible.error ||
    validityResults.nessary.error
  ) {
    returnData.error = true;
    returnData.errorMessage = returnData.errorMessage
      .concat(validityResults.include.message)
      .concat(validityResults.incompatible.message)
      .concat(validityResults.nessary.message);
  }

  return returnData;
}
/** 대체 선택안 생성 */
export function createSuggestion(
  params: RequestData,
  validation: ValidationData,
): Suggestion | null {
  let returnData: Suggestion | null = null;

  /** 에러 존재 시 추가된 항목에 대하여 확인
   * @notice 추천 변경 사항을 제시합니다.
   */
  if (validation.error === true) {
    const suggestion: {
      optionList: CarOption[];
      message: string[];
    } = {
      optionList: [...params.optionList],
      message: [],
    };

    /** 옵션이 추가 선택 시도된 경우 */
    if (params.isAdded && params.selectedOption.relation !== null) {
      /** 추가되는 옵션의 모든 nessary를 추가합니다. */
      if (params.selectedOption.relation.nessary.length > 0) {
        params.selectedOption.relation.nessary.forEach((el) => {
          const addOption = getOptionFromOptiondata({
            optionId: el,
            data: params.optionData,
          });

          if (
            addOption &&
            !suggestion.optionList.find((el2) => {
              return el2.id === el;
            })
          ) {
            suggestion.optionList.push(addOption);
            suggestion.message.push(`[${addOption.name}]옵션이 선택됩니다.`);
          }

          return;
        });
      }
      /** 추가되는 옵션의 모든 incompatible를 선택 해제합니다. */
      if (params.selectedOption.relation.incompatible.length > 0) {
        params.selectedOption.relation.incompatible.forEach((el) => {
          /** incompatible된 항목이 현재 포함되어 있지 않을 경우 제외 */
          if (
            !suggestion.optionList.find((el2) => {
              return el2.id === el;
            })
          )
            return;
          const removeOption = getOptionFromOptiondata({
            optionId: el,
            data: params.optionData,
          });
          if (removeOption) {
            suggestion.optionList = suggestion.optionList.filter((el2) => {
              return el2.id !== el;
            });
            suggestion.message.push(
              `[${removeOption.name}]옵션이 선택 해제됩니다.`,
            );
          }
          return;
        });
      }
      /** 추가되는 옵션의 모든 include를 선택 해제합니다. */
      if (params.selectedOption.relation.include.length > 0) {
        const totalIncludedOptionIdList = getTotalIncludedOptionId({
          option: params.selectedOption,
          data: params.optionData,
          depth: 0,
        });

        let isSetMessage = false;

        totalIncludedOptionIdList.forEach((el) => {
          /** include된 항목이 현재 포함되어 있지 않을 경우 제외 */
          if (
            !suggestion.optionList.find((el2) => {
              return el2.id === el;
            })
          )
            return;
          const removeOption = getOptionFromOptiondata({
            optionId: el,
            data: params.optionData,
          });

          if (removeOption) {
            suggestion.optionList = suggestion.optionList.filter((el2) => {
              return el2.id !== el;
            });

            if (isSetMessage === false) {
              suggestion.message.push(
                `[${params.selectedOption.name}]옵션에 포함된 옵션들이 선택 해제됩니다.`,
              );
              isSetMessage = true;
            }
          }
          return;
        });
      }
      /** 추가되는 옵션의 모든 parent를 선택 해제합니다. */
      if (params.selectedOption.relation.parent.length > 0) {
        const totalParentOptionIdList = getTotalParentOptionId({
          option: params.selectedOption,
          data: params.optionData,
          depth: 0,
        });

        totalParentOptionIdList.forEach((el) => {
          /** parent 항목이 현재 포함되어 있지 않을 경우 제외 */
          if (
            !suggestion.optionList.find((el2) => {
              return el2.id === el;
            })
          )
            return;

          const removeOption = getOptionFromOptiondata({
            optionId: el,
            data: params.optionData,
          });
          if (removeOption) {
            suggestion.optionList = suggestion.optionList.filter((el2) => {
              return el2.id !== el;
            });
            suggestion.message.push(
              `[${removeOption.name}]옵션이 선택 해제됩니다.`,
            );
          }
          return;
        });
      }
    } else {
      /** 옵션이 선택 해제 시도된 경우
       * @notice nessary 조건만 같이 제외해 봅니다.
       */
      /** 제거되는 옵션의 모든 nessary를 체크 해제합니다. */
      if (params.optionList.length > 0) {
        params.optionList.forEach((el) => {
          if (!el.relation?.nessary) return;

          if (el.relation.nessary.includes(params.selectedOption.id)) {
            suggestion.optionList = suggestion.optionList.filter((el2) => {
              return el2.id !== el.id;
            });
            suggestion.message.push(`[${el.name}]옵션이 선택 해제됩니다.`);
          }
        });
      }
    }

    const isValidSuggestion = checkOptionValidity({
      ...params,
      optionList: suggestion.optionList,
    });
    if (isValidSuggestion.error === false) returnData = suggestion;
  } else if (
    params.selectedOption.relation !== null &&
    validation.error === false
  ) {
    const suggestion: {
      optionList: CarOption[];
      message: string[];
    } = {
      optionList: [...params.optionList],
      message: [],
    };
    let isModified = false;

    // /** 옵션이 추가 선택 시도된 경우, 상위 노드가 조합될 수 있는지 확인 */
    if (params.isAdded) {
      if (params.selectedOption.relation.parent.length > 0) {
        /** 선택된 옵션의 부모 옵션이 존재하는 경우 */
        for (let i = 0; i < params.selectedOption.relation.parent.length; i++) {
          const totalParentOptionIdList = getTotalParentOptionId({
            option: params.selectedOption,
            data: params.optionData,
            depth: 0,
          });

          for (let i = 0; i < totalParentOptionIdList.length; i++) {
            const targetOption = getOptionFromOptiondata({
              optionId: totalParentOptionIdList[i],
              data: params.optionData,
            });
            if (!targetOption) continue;

            const isCombinableSelect = isCombinable({
              option: targetOption,
              totalIncludedOrderIdList: params.optionList.map((el) => {
                return el.id;
              }),
              data: params.optionData,
              depth: 0,
            });

            if (isCombinableSelect === true) {
              const totalIncludedOptionIdList = getTotalIncludedOptionId({
                option: targetOption,
                data: params.optionData,
                depth: 0,
              });

              const deletedOptionList: CarOption[] = [];

              let generatedSuggestion: {
                optionList: CarOption[];
                message: string[];
              } = {
                optionList: [...params.optionList],
                message: [],
              };
              /** 자식 속성을 모두 선택 해제하고, 상위 옵션을 선택한 상태 */
              totalIncludedOptionIdList.forEach((el) => {
                generatedSuggestion.optionList =
                  generatedSuggestion.optionList.filter((el2) => {
                    if (el2.id !== el) {
                      return true;
                    } else {
                      deletedOptionList.push(el2);
                    }
                  });
              });

              generatedSuggestion.optionList.push(targetOption);
              generatedSuggestion.message.push(
                `[${targetOption.name}]옵션은 현재 선택된 총 ${deletedOptionList.length}개의 옵션을 모두 포함하고 있습니다. \n[${targetOption.name}]옵션으로 변경하시겠습니까?`,
              );

              /** 기존 선택안이 있을 경우, 더 짧은 변경안을 제시합니다.
               * @example "프리미엄 컬렉션", "뒷좌석 컴포트 2", "전동식 뒷좌석 듀얼 모니터" VS "프레스티지 컬렉션" 일 경우 "프레스티지 컬렉션"만 선택하는 안을 제시합니다.
               */
              if (
                generatedSuggestion.optionList.length <
                suggestion.optionList.length
              ) {
                suggestion.optionList = generatedSuggestion.optionList;
                suggestion.message = generatedSuggestion.message;
                isModified = true;
              }
            }
          }
        }
      }
    }

    if (isModified === true) {
      const isValidSuggestion = checkOptionValidity({
        ...params,
        optionList: suggestion.optionList,
      });
      if (isValidSuggestion.error === false) returnData = suggestion;
    }
  }

  return returnData;
}
