import { InputHTMLAttributes, useId } from 'react';
import { cn } from 'utils';

interface CarsayoRadioInterface
  extends Omit<
    InputHTMLAttributes<HTMLInputElement>,
    'type' | 'className' | 'id'
  > {
  label: string;
  className?: string;
  theme?: 'black';
}

const CarsayoRadio = ({
  label,
  className,
  theme,
  ...rest
}: CarsayoRadioInterface) => {
  const id = useId();
  return (
    <label className='flex flex-row gap-2' htmlFor={id}>
      <input
        id={id}
        type='radio'
        name='progressRadio'
        className={cn(
          className && className,
          `overflow-hidden appearance-none hover:cursor-pointer w-[22px] h-[22px] shrink-0 !border-[#E5E5EC] rounded-full !text-[10px] text-[#fff] focus:ring-transparent disabled:opacity-50 disabled:pointer-events-none`,
          `${theme === 'black' ? 'checked:!bg-radio-black-active' : 'checked:!bg-radio-active'} checked:!bg-[length:22px_22px]`,
          `${theme === 'black' ? '!bg-radio-black-default' : '!bg-radio-default'} !bg-[length:22px_22px] !ml-0`,
        )}
        {...rest}
      />
      {label}
    </label>
  );
};

export default CarsayoRadio;
