import { CarMakerData, CarMakerId } from '@carsayo/types';
import { useEffect, useLayoutEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import useSystemStore from 'store/useSystemStore';
import { cn } from 'utils';

const ServiceBrandChoicePage = () => {
  const { setTopTabbarState, setIsBottomTabbar } = useSystemStore();
  const navigate = useNavigate();
  const location = useLocation();
  const [selectedType, setSelectedType] = useState(
    location.state?.brandData ? location.state.brandData.type : 'domestic',
  );
  const [checkedBrand, setCheckedBrand] = useState<CarMakerId | null>(null);
  const handleBrandRadioChange = (brandId: CarMakerId) => {
    setCheckedBrand(brandId);
  };

  useLayoutEffect(() => {
    setTopTabbarState({
      state: 'fixed',
      mode: 'subpage',
      leftElement: (
        <div
          onClick={() => {
            navigate(-1);
          }}
        >
          <img src='/assets/images/v2/arrow-left.svg' alt='arrow left' />
        </div>
      ),
      title: '공식 서비스',
    });
    setIsBottomTabbar(false);
  }, []);
  useEffect(() => {
    if (checkedBrand) {
      navigate('/ask/service/list', {
        state: {
          brand: checkedBrand,
        },
      });
    }
  }, [checkedBrand]);
  return (
    <div className='w-full py-4 px-[20px]'>
      <div className='mb-4'>
        <h3 className='text-[18px] leading-[26px] font-[600]'>
          공식 서비스
          <br /> 브랜드를 선택해주세요
        </h3>
      </div>
      <div>
        <ul className='w-full flex justify-start items-center text-[16px] leading-[24px]'>
          <li
            className={`transition-all w-[50%] border-b-[1px] ${selectedType === 'domestic' ? 'border-[#111] font-[600]' : 'border-[#E5E5EC] font-[400]'}`}
          >
            <button
              onClick={() => setSelectedType('domestic')}
              className='w-full p-[14px]'
            >
              국산 브랜드
            </button>
          </li>
          <li
            className={`transition-all w-[50%] border-b-[1px] ${selectedType === 'imported' ? 'border-[#111] font-[600]' : 'border-[#E5E5EC] font-[400]'}`}
          >
            <button
              onClick={() => setSelectedType('imported')}
              className='w-full p-[14px]'
            >
              수입 브랜드
            </button>
          </li>
        </ul>
        <div className='relative pb-[30px]'>
          <div
            className={cn(
              'brandAllCheck transition-all',
              selectedType === 'domestic'
                ? 'mt-4 grid grid-cols-3 gap-x-[21.5px] gap-y-4 relative z-0 translate-y-0 opacity-100 h-auto'
                : 'absolute z-[-9999] translate-y-7 opacity-0 h-0',
            )}
          >
            {selectedType &&
              selectedType === 'domestic' &&
              CarMakerData.filter((item) => item.type === 'domestic').map(
                (brand) => (
                  <label
                    key={brand.name_en}
                    className='group relative h-[88px] rounded-xl border bg-[#F7F7FB] py-4 has-[:checked]:border-primary'
                  >
                    <input
                      type='radio'
                      name='brand'
                      className='absolute h-0 w-0 hidden'
                      defaultChecked={brand.id === checkedBrand}
                      onChange={() => handleBrandRadioChange(brand.id)}
                    />
                    <div className='mx-auto h-9 w-9'>
                      <img
                        src={`/assets/images/v2/brand/${brand.name_en}.png`}
                        alt={brand.name_en}
                      />
                    </div>
                    <div className='mt-2 text-center text-xs font-semibold'>
                      {brand.name}
                    </div>
                  </label>
                ),
              )}
          </div>
          <div
            className={cn(
              'brandAllCheck transition-all',
              selectedType === 'imported'
                ? 'mt-4 grid grid-cols-3 gap-x-[21.5px] gap-y-4 relative z-0 translate-y-0 opacity-100 h-auto'
                : 'absolute z-[-9999] translate-y-7 opacity-0 h-0',
            )}
          >
            {selectedType &&
              selectedType === 'imported' &&
              CarMakerData.filter((item) => item.type === 'imported').map(
                (brand) => (
                  <label
                    key={brand.name_en}
                    className='group relative h-[88px] rounded-xl border bg-[#F7F7FB] py-4 has-[:checked]:border-primary'
                  >
                    <input
                      type='radio'
                      name='brand'
                      className='absolute h-0 w-0 hidden'
                      defaultChecked={brand.id === checkedBrand}
                      onChange={() => handleBrandRadioChange(brand.id)}
                    />
                    <div className='mx-auto h-9 w-9'>
                      <img
                        src={`/assets/images/v2/brand/${brand.name_en}.png`}
                        alt={brand.name_en}
                      />
                    </div>
                    <div className='mt-2 text-center text-xs font-semibold'>
                      {brand.name}
                    </div>
                  </label>
                ),
              )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ServiceBrandChoicePage;
