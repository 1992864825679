import {
  AccidentRegistration,
  AccidentRegistrationCreateDTO,
  InsuranceCompany,
  RegisterCar,
  RegisterCarCreateDTO,
  RegisterCarIdDTO,
  RegisterCarUpdateDTO,
} from '@carsayo/types';
import { api } from 'apis';

export const getInsuranceDataApi = async (): Promise<InsuranceCompany[]> => {
  try {
    const response = await api.get('data/insurance/company/list');
    const data: InsuranceCompany[] = response.data;
    return data;
  } catch (error: any) {
    throw new Error(error);
  }
};

export const addRegisterCarApi = async (
  reqData: RegisterCarCreateDTO,
): Promise<RegisterCar> => {
  try {
    const response = await api.post(
      'member/customer/registerCar/create',
      reqData,
    );
    const data: RegisterCar = response.data;
    return data;
  } catch (error: any) {
    throw new Error(error);
  }
};

export const updateRegisterCarApi = async (
  reqData: RegisterCarUpdateDTO,
): Promise<RegisterCar> => {
  try {
    const response = await api.post(
      'member/customer/registerCar/update',
      reqData,
    );
    const data: RegisterCar = response.data;
    return data;
  } catch (error: any) {
    throw new Error(error);
  }
};
export const deleteRegisterCarApi = async (reqData: RegisterCarIdDTO) => {
  try {
    const response = await api.post(
      'member/customer/registerCar/delete',
      reqData,
    );
    return response;
  } catch (error: any) {
    throw new Error(error);
  }
};

export const accidentCreateApi = async (
  reqData: AccidentRegistrationCreateDTO,
): Promise<AccidentRegistration> => {
  try {
    const response = await api.post(
      'accidentRegistration/customer/create',
      reqData,
    );
    const data: AccidentRegistration = response.data;
    return data;
  } catch (error: any) {
    throw new Error(error);
  }
};
