/* eslint-disable @typescript-eslint/no-unused-vars */
import { Button } from 'components/ui/button';
import {
  Drawer,
  DrawerClose,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerTitle,
} from 'components/ui/drawer';
import { Checkbox } from 'components/ui/checkbox';
import { useEffect, useLayoutEffect, useReducer, useState } from 'react';
import { getTerm } from 'apis/setting';
import ReactHtmlParser from 'react-html-parser';
import { ChevronLeft } from 'lucide-react';
import { TermCategoryId, TermCategoryData, TermDetail } from '@carsayo/types';

interface actionType {
  [key: string]: boolean;
}

/** 회원가입 - 약관 동의 Drawer */
export function ConsentClause({
  isOpen,
  termData,
  setIsOpen,
  onConfirm,
}: {
  isOpen: boolean;
  termData: {
    category1?: {
      title: string;
      term: TermCategoryId[];
      isEssential: boolean;
    };
    category2?: {
      title: string;
      term: TermCategoryId[];
      isEssential: boolean;
    };
    category3?: {
      title: string;
      term: TermCategoryId[];
      isEssential: boolean;
    };
    category4?: {
      title: string;
      term: TermCategoryId[];
      isEssential: boolean;
    };
    category5?: {
      title: string;
      term: TermCategoryId[];
      isEssential: boolean;
    };
  };
  setIsOpen: (value: boolean) => void;
  onConfirm: (value: TermCategoryId[]) => void;
}) {
  const [terms, setTerms] = useReducer(
    (prev: actionType, next: actionType) => ({ ...prev, ...next }),
    {
      terms: false,
      terms1: termData.category1 ? false : true,
      terms2: termData.category2 ? false : true,
      terms3: termData.category3 ? false : true,
      terms4: termData.category4 ? false : true,
      terms5: termData.category5 ? false : true,
    },
  );

  // 이 값이 있으면 해당 약관 상세보기가 열립니다
  const [termDetail, setTermDetail] = useState<TermDetail | null>();

  const handleClickEvents = {
    openTermDetail: async (termCategoryId: TermCategoryId) => {
      setTermDetail(await getTerm({ termCategoryId: termCategoryId }));
    },
    closeTermDetail: () => {
      setTermDetail(null);
    },
  };

  useLayoutEffect(() => {
    if (termData.category1) {
      termData.category1.term.map((e1l) => {
        const data = TermCategoryData.find((el2) => {
          return (
            el2.id === e1l &&
            el2.isEssential !== termData.category1!.isEssential
          );
        });
        if (data)
          console.error(`isEssential - 약관 설정이 잘못되었습니다: ${e1l}`);
      });
    }
  }, []);

  useEffect(() => {
    if (terms.terms === false) {
      if (termData.category1 && terms.terms1 === false) return;
      if (termData.category2 && terms.terms2 === false) return;
      if (termData.category3 && terms.terms3 === false) return;
      if (termData.category4 && terms.terms4 === false) return;
      if (termData.category5 && terms.terms5 === false) return;
      setTerms({ ...terms, terms: true });
    } else if (terms.terms === true) {
      if (termData.category1 && terms.terms1 === false) {
        setTerms({ ...terms, terms: false });
        return;
      }
      if (termData.category2 && terms.terms2 === true) {
        setTerms({ ...terms, terms: false });
        return;
      }
      if (termData.category3 && terms.terms3 === true) {
        setTerms({ ...terms, terms: false });
        return;
      }
      if (termData.category4 && terms.terms4 === true) {
        setTerms({ ...terms, terms: false });
        return;
      }
      if (termData.category5 && terms.terms5 === true) {
        setTerms({ ...terms, terms: false });
        return;
      }
    }
  }, [terms.terms1, terms.terms2, terms.terms3, terms.terms4, terms.terms5]);

  return (
    <Drawer
      open={isOpen}
      onOpenChange={(open) => {
        if (open === true) setTermDetail(null);
        setIsOpen(open);
      }}
    >
      {!termDetail ? (
        <DrawerContent>
          <div className='mx-auto w-full max-w-sm overflow-y-auto'>
            <DrawerHeader className='relative'>
              <DrawerTitle>약관 동의</DrawerTitle>
              <DrawerClose asChild>
                <Button className='absolute right-5 top-[14px] h-7 w-7 border-0 bg-transparent p-0'>
                  <img src='/assets/images/v2/close.svg' alt='' />
                </Button>
              </DrawerClose>
            </DrawerHeader>
            <div className='p-4'>
              <div className='flex h-14 items-center gap-2 border-b border-[#111111]'>
                <Checkbox
                  id='terms'
                  checked={terms.terms}
                  onCheckedChange={(value: boolean) =>
                    setTerms({
                      terms1: value,
                      terms2: value,
                      terms3: value,
                      terms4: value,
                      terms5: value,
                    })
                  }
                />
                <label htmlFor='terms'>약관에 모두 동의</label>
              </div>
              <div className='pt-4'>
                {termData.category1 && (
                  <>
                    <div className='mb-2 mt-4 flex items-center gap-2'>
                      <Checkbox
                        id='terms1'
                        checked={terms.terms1}
                        onCheckedChange={(value: boolean) =>
                          setTerms({ terms1: value })
                        }
                      />
                      <label htmlFor='terms1'>
                        {termData.category1.isEssential ? '(필수)' : '(선택)'}{' '}
                        {termData.category1.title}
                      </label>
                    </div>

                    {termData.category1.term.map((termId, index) => {
                      const targetData = TermCategoryData.find((el) => {
                        return el.id === termId;
                      });
                      if (!targetData) return;
                      return (
                        <div
                          key={`terms_${index}`}
                          className='ml-[30px] flex h-9 items-center gap-2.5 pl-2 text-[#767676]'
                        >
                          <div>
                            {targetData.isEssential ? '(필수)' : '(선택)'}{' '}
                            {targetData.title}
                          </div>
                          {targetData.isContent && (
                            <span
                              onClick={() => {
                                handleClickEvents.openTermDetail(termId);
                              }}
                              className='ml-auto'
                            >
                              <img
                                className='p-3'
                                src='/assets/images/v2/arrowRight.svg'
                                alt=''
                              />
                            </span>
                          )}
                        </div>
                      );
                    })}
                  </>
                )}
                {termData.category2 && (
                  <>
                    <div className='mb-2 mt-4 flex items-center gap-2'>
                      <Checkbox
                        id='terms2'
                        checked={terms.terms2}
                        onCheckedChange={(value: boolean) =>
                          setTerms({ terms2: value })
                        }
                      />
                      <label htmlFor='terms2'>
                        {termData.category2.isEssential ? '(필수)' : '(선택)'}{' '}
                        {termData.category2.title}
                      </label>
                    </div>

                    {termData.category2.term.map((termId, index) => {
                      const targetData = TermCategoryData.find((el) => {
                        return el.id === termId;
                      });
                      if (!targetData) return;
                      return (
                        <div
                          key={`terms_${index}`}
                          className='ml-[30px] flex h-9 items-center gap-2.5 pl-2 text-[#767676]'
                        >
                          <div>
                            {targetData.isEssential ? '(필수)' : '(선택)'}{' '}
                            {targetData.title}
                          </div>
                          {targetData.isContent && (
                            <span
                              onClick={() => {
                                handleClickEvents.openTermDetail(termId);
                              }}
                              className='ml-auto'
                            >
                              <img
                                className='p-4'
                                src='/assets/images/v2/arrowRight.svg'
                                alt=''
                              />
                            </span>
                          )}
                        </div>
                      );
                    })}
                  </>
                )}
                {termData.category3 && (
                  <>
                    <div className='mb-2 mt-4 flex items-center gap-2'>
                      <Checkbox
                        id='terms3'
                        checked={terms.terms3}
                        onCheckedChange={(value: boolean) =>
                          setTerms({ terms3: value })
                        }
                      />
                      <label htmlFor='terms3'>
                        {termData.category3.isEssential ? '(필수)' : '(선택)'}{' '}
                        {termData.category3.title}
                      </label>
                    </div>

                    {termData.category3.term.map((termId, index) => {
                      const targetData = TermCategoryData.find((el) => {
                        return el.id === termId;
                      });
                      if (!targetData) return;
                      return (
                        <div
                          key={`terms_${index}`}
                          className='ml-[30px] flex h-9 items-center gap-2.5 pl-2 text-[#767676]'
                        >
                          <div>
                            {targetData.isEssential ? '(필수)' : '(선택)'}{' '}
                            {targetData.title}
                          </div>
                          {targetData.isContent && (
                            <span
                              onClick={() => {
                                handleClickEvents.openTermDetail(termId);
                              }}
                              className='ml-auto'
                            >
                              <img
                                className='p-4'
                                src='/assets/images/v2/arrowRight.svg'
                                alt=''
                              />
                            </span>
                          )}
                        </div>
                      );
                    })}
                  </>
                )}
                {termData.category4 && (
                  <>
                    <div className='mb-2 mt-4 flex items-center gap-2'>
                      <Checkbox
                        id='terms4'
                        checked={terms.terms4}
                        onCheckedChange={(value: boolean) =>
                          setTerms({ terms4: value })
                        }
                      />
                      <label htmlFor='terms4'>
                        {termData.category4.isEssential ? '(필수)' : '(선택)'}{' '}
                        {termData.category4.title}
                      </label>
                    </div>

                    {termData.category4.term.map((termId, index) => {
                      const targetData = TermCategoryData.find((el) => {
                        return el.id === termId;
                      });
                      if (!targetData) return;
                      return (
                        <div
                          key={`terms_${index}`}
                          className='ml-[30px] flex h-9 items-center gap-2.5 pl-2 text-[#767676]'
                        >
                          <div>
                            {targetData.isEssential ? '(필수)' : '(선택)'}{' '}
                            {targetData.title}
                          </div>
                          {targetData.isContent && (
                            <span
                              onClick={() => {
                                handleClickEvents.openTermDetail(termId);
                              }}
                              className='ml-auto'
                            >
                              <img
                                className='p-4'
                                src='/assets/images/v2/arrowRight.svg'
                                alt=''
                              />
                            </span>
                          )}
                        </div>
                      );
                    })}
                  </>
                )}{' '}
                {termData.category5 && (
                  <>
                    <div className='mb-2 mt-4 flex items-center gap-2'>
                      <Checkbox
                        id='terms5'
                        checked={terms.terms5}
                        onCheckedChange={(value: boolean) =>
                          setTerms({ terms5: value })
                        }
                      />
                      <label htmlFor='terms5'>
                        {termData.category5.isEssential ? '(필수)' : '(선택)'}{' '}
                        {termData.category5.title}
                      </label>
                    </div>

                    {termData.category5.term.map((termId, index) => {
                      const targetData = TermCategoryData.find((el) => {
                        return el.id === termId;
                      });
                      if (!targetData) return;
                      return (
                        <div
                          key={`terms_${index}`}
                          className='ml-[30px] flex h-9 items-center gap-2.5 pl-2 text-[#767676]'
                        >
                          <div>
                            {targetData.isEssential ? '(필수)' : '(선택)'}{' '}
                            {targetData.title}
                          </div>
                          {targetData.isContent && (
                            <span
                              onClick={() => {
                                handleClickEvents.openTermDetail(termId);
                              }}
                              className='ml-auto'
                            >
                              <img
                                className='p-4'
                                src='/assets/images/v2/arrowRight.svg'
                                alt=''
                              />
                            </span>
                          )}
                        </div>
                      );
                    })}
                  </>
                )}
              </div>
            </div>
            <DrawerFooter className='pb-10 pt-0'>
              <Button
                disabled={
                  (termData.category1?.isEssential && terms.terms1 === false) ||
                  (termData.category2?.isEssential && terms.terms2 === false) ||
                  (termData.category3?.isEssential && terms.terms3 === false) ||
                  (termData.category4?.isEssential && terms.terms4 === false) ||
                  (termData.category5?.isEssential && terms.terms5 === false)
                }
                onClick={() => {
                  const agreedTermId: TermCategoryId[] = [];

                  if (termData.category1 && terms.terms1) {
                    agreedTermId.push(...termData.category1.term);
                  }
                  if (termData.category2 && terms.terms2) {
                    agreedTermId.push(...termData.category2.term);
                  }
                  if (termData.category3 && terms.terms3) {
                    agreedTermId.push(...termData.category3.term);
                  }
                  if (termData.category4 && terms.terms4) {
                    agreedTermId.push(...termData.category4.term);
                  }
                  if (termData.category5 && terms.terms5) {
                    agreedTermId.push(...termData.category5.term);
                  }
                  setIsOpen(false);
                  onConfirm(agreedTermId);
                }}
              >
                확인
              </Button>
            </DrawerFooter>
          </div>
        </DrawerContent>
      ) : (
        <DrawerContent>
          <DrawerHeader className='relative'>
            <DrawerTitle>
              <div className='flex'>
                <ChevronLeft
                  onClick={handleClickEvents.closeTermDetail}
                  className='flex-none w-8 h-8'
                />
                <div className='flex-auto'>
                  {
                    TermCategoryData.find((el) => {
                      return el.id === termDetail.term_categoryId;
                    })?.title
                  }
                </div>
                <div className='flex-none w-8'></div>
              </div>
            </DrawerTitle>
          </DrawerHeader>
          <div className='p-6 overflow-y-scroll'>
            {termDetail.content_html
              ? ReactHtmlParser(termDetail.content_html)
              : ''}
          </div>
        </DrawerContent>
      )}
    </Drawer>
  );
}
