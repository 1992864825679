import {
  DealerOrderSearch,
  DealerOrderDetail,
  CustomerOrderListData,
  CustomerOrderDetail,
  SelectManagerDTO,
  OrderBidDTO,
  OrderSubmitContractDTO,
  OrderMeetingDateDTO,
  OrderFinishDTO,
  OrderHoldDTO,
  OrderCancelDTO,
  OrderBookmarkDTO,
  DealerOrderListData,
  OrderMemo,
  CreateOrderMemoDTO,
  DeleteOrderMemoDTO,
  DealerGetOrderListDTO,
  OrderIdDTO,
} from '@carsayo/types';
import { api } from 'apis';
import SentryErrorReturns from 'utils/sentryErrorReturns';

// 딜러 견적함 리스트 api
export const getManagerQuoteListApi = async (
  reqData: DealerGetOrderListDTO,
): Promise<DealerOrderSearch> => {
  try {
    const response = await api.post('order/data/dealer/list', reqData);
    const data: DealerOrderSearch = response.data;
    return data;
  } catch (error: any) {
    SentryErrorReturns(error.response);
    throw new Error(error);
  }
};
// 딜러 견적함 상세 api
export const getManagerQuoteDetailApi = async (orderId: string) => {
  try {
    const response = await api.get(
      `order/data/dealer/detail?orderId=${orderId}`,
    );
    const data: DealerOrderDetail = response.data;
    return data;
  } catch (error: any) {
    SentryErrorReturns(error.response);
    throw new Error(error);
  }
};

// 사용자 견적함 리스트 api
export const getCustomerQuoteListApi = async () => {
  try {
    const response = await api.get('order/data/customer/list');
    const data: CustomerOrderListData[] = response.data;
    return data;
  } catch (error: any) {
    SentryErrorReturns(error.response);
    throw new Error(error);
  }
};
// 사용자 견적함 상세 api
export const getCustomerQuoteDetailApi = async (orderId: string) => {
  try {
    const response = await api.get(
      `order/data/customer/detail?orderId=${orderId}`,
    );
    const data: CustomerOrderDetail = response.data;
    return data;
  } catch (error: any) {
    SentryErrorReturns(error.response);
    throw new Error(error);
  }
};

// 사용자가 낙찰 매니저 선택하는 api
export const selectManagerApi = async (reqData: SelectManagerDTO) => {
  try {
    const response = await api.post(
      'order/process/customer/selectManager',
      reqData,
    );
    const data: CustomerOrderListData = response.data;
    return data;
  } catch (error: any) {
    SentryErrorReturns(error.response);
    throw new Error(error);
  }
};

// 매니저가 견적 입찰하는 api
export const sendQuoteApi = async (reqData: OrderBidDTO) => {
  try {
    const response = await api.post('order/process/dealer/bid', reqData);
    const data: DealerOrderDetail = response.data;
    return data;
  } catch (error: any) {
    SentryErrorReturns(error.response);
    throw error.response?.data;
  }
};

// 매니저가 입찰 견적 삭제하는 api
export const deleteQuoteApi = async (orderId: string) => {
  try {
    const response = await api.post('order/process/dealer/deleteBid', {
      orderId: orderId,
    });
    const data: DealerOrderDetail = response.data;
    return data;
  } catch (error: any) {
    SentryErrorReturns(error.response);
    throw new Error(error);
  }
};

// 매니저가 입찰 견적 삭제하는 api (Modified)
export const deleteQuoteApi_V2 = async (
  params: OrderIdDTO,
): Promise<DealerOrderDetail> => {
  const { data } = await api
    .post('order/process/dealer/deleteBid', params)
    .catch((error: any) => {
      SentryErrorReturns(error.response);
      throw error.response?.data;
    });
  return data;
};

// 매니저가 계약서 작성하는 api
export const sendContractApi = async (reqData: OrderSubmitContractDTO) => {
  try {
    const response = await api.post(
      'order/process/dealer/submitContract',
      reqData,
    );
    const data: DealerOrderDetail = response.data;
    return data;
  } catch (error: any) {
    SentryErrorReturns(error.response);
    throw new Error(error);
  }
};

// 내차팔기 매니저가 미팅일정 정하는 api
export const selectedMeetingApi = async (reqData: OrderMeetingDateDTO) => {
  try {
    const response = await api.post(
      'order/process/dealer/meetingDate',
      reqData,
    );
    const data: DealerOrderDetail = response.data;
    return data;
  } catch (error: any) {
    SentryErrorReturns(error.response);
    throw new Error(error);
  }
};

// 매니저가 계약 완료하는 api
export const doneQuoteApi = async (reqData: OrderFinishDTO) => {
  try {
    const response = await api.post('order/process/dealer/finish', reqData);
    const data: DealerOrderDetail = response.data;
    return data;
  } catch (error: any) {
    SentryErrorReturns(error.response);
    throw new Error(error);
  }
};

// 매니저가 주문 보류하는 api
export const holdQuoteApi = async (reqData: OrderHoldDTO) => {
  try {
    const response = await api.post('order/process/dealer/hold', reqData);
    const data: DealerOrderDetail = response.data;
    return data;
  } catch (error: any) {
    SentryErrorReturns(error.response);
    throw new Error(error);
  }
};

// 매니저가 주문 재개하는 api
export const reStartQuoteApi = async (orderId: string) => {
  try {
    const response = await api.post('order/process/dealer/resume', {
      orderId: orderId,
    });
    const data: DealerOrderDetail = response.data;
    return data;
  } catch (error: any) {
    SentryErrorReturns(error.response);
    throw new Error(error);
  }
};

// 매니저가 주문 취소하는 api
export const cancelQuoteApi = async (reqData: OrderCancelDTO) => {
  try {
    const response = await api.post('order/process/dealer/cancel', reqData);
    const data: DealerOrderDetail = response.data;
    return data;
  } catch (error: any) {
    SentryErrorReturns(error.response);
    throw new Error(error);
  }
};

// 매니저가 입찰내역 북마크 설정/해제 하는 api
export const setDealerBookMark = async (reqData: OrderBookmarkDTO) => {
  try {
    const response = await api.post(`order/process/dealer/bookmark`, {
      orderId: reqData.orderId,
      isBookmarked: reqData.isBookmarked,
    });
    const data: DealerOrderListData = response.data;
    return data;
  } catch (error: any) {
    SentryErrorReturns(error.response);
    throw new Error(error);
  }
};

// 사용자가 주문 보류하는 api
export const customerHoldQuoteApi = async (reqData: OrderHoldDTO) => {
  try {
    const response = await api.post('order/process/customer/hold', reqData);
    const data: CustomerOrderListData = response.data;
    return data;
  } catch (error: any) {
    SentryErrorReturns(error.response);
    throw error.response?.data;
  }
};

// 사용자가 주문 재개하는 api
export const customerReStartQuoteApi = async (orderId: string) => {
  try {
    const response = await api.post('order/process/customer/resume', {
      orderId: orderId,
    });
    const data: CustomerOrderListData = response.data;
    return data;
  } catch (error: any) {
    SentryErrorReturns(error.response);
    throw error.response?.data;
  }
};

// 사용자가 주문 취소하는 api
export const customerCancelQuoteApi = async (reqData: OrderCancelDTO) => {
  try {
    const response = await api.post('order/process/customer/cancel', reqData);
    const data: CustomerOrderListData = response.data;
    return data;
  } catch (error: any) {
    SentryErrorReturns(error.response);
    throw error.response?.data;
  }
};

// 사용자가 입찰 견적 삭제하는 api
export const customerDeleteQuoteApi = async (orderId: string) => {
  try {
    const response = await api.post('order/process/customer/delete', {
      orderId: orderId,
    });
    const data: CustomerOrderListData = response.data;
    return data;
  } catch (error: any) {
    SentryErrorReturns(error.response);
    throw error.response?.data;
  }
};

/** 카매니저 - 본인이 해당 주문에 대하여 작성한 메모 검색 */
export const getDealerOrderMemoList = async (
  orderId: string,
): Promise<OrderMemo[]> => {
  try {
    const response = await api.get(`order/memo/dealer/list?orderId=${orderId}`);
    const data: OrderMemo[] = response.data;
    return data;
  } catch (error: any) {
    SentryErrorReturns(error.response);
    throw new Error(error);
  }
};

/** 카매니저 - 주문 메모 작성 */
export const createDealerOrderMemo = async (
  reqData: CreateOrderMemoDTO,
): Promise<OrderMemo> => {
  try {
    const response = await api.post(`order/memo/dealer/create`, reqData);
    const data: OrderMemo = response.data;
    return data;
  } catch (error: any) {
    SentryErrorReturns(error.response);
    throw new Error(error);
  }
};

/** 카매니저 - 주문 메모 제거 */
export const deleteDealerOrderMemo = async (
  reqData: DeleteOrderMemoDTO,
): Promise<void> => {
  try {
    await api.post(`order/memo/dealer/delete`, reqData);
    return;
  } catch (error: any) {
    SentryErrorReturns(error.response);
    throw new Error(error);
  }
};
