import { Button, buttonVariants } from 'components/ui/button';
import { useLayoutEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import useSystemStore from 'store/useSystemStore';
import signupStore from './store';
import NewSwiper from 'components/signin/NewSwiper';

export default function SignupMain() {
  const { setTopTabbarState } = useSystemStore();
  const { resetSignupStore } = signupStore();
  const navigate = useNavigate();

  useLayoutEffect(() => {
    setTopTabbarState({
      state: 'hidden',
      mode: 'subpage',
      leftElement: 'back',
    });
    resetSignupStore();
  }, []);

  return (
    <div className='flex h-full grow flex-col justify-start box-border'>
      <div className='relative w-full'>
        <NewSwiper />
      </div>
      <div className='p-[4.2vw] my-auto'>
        {process.env.REACT_APP_NODE_ENV === 'development' && (
          <div className='text-center pb-4 font-semibold text-[16px]'>
            - 카사요 앱 개발용 버전 -
          </div>
        )}
        <Link to='/auth/signup/customer/start' className={buttonVariants()}>
          간편 회원가입
        </Link>
        <Button
          variant='zinc'
          className='mt-[1.97vh]'
          onClick={() => {
            navigate('/auth/signup/dealer/start');
          }}
        >
          카 매니저 가입하기
        </Button>
        <div className='flex flex-row mt-[4.9vh] gap-[8px] justify-center items-center'>
          <div className='text-center text-[#767676]'>
            카사요 계정이 있으시다면?
          </div>
          <div className='text-center'>
            <Link
              to='/auth/login'
              className='text-primary underline underline-offset-4'
            >
              통합 로그인
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}
