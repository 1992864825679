import { RegisterCar } from '@carsayo/types';
import { CustomerActionInterface } from '../ask';
import AskCardLayout from '../component/AskCardLayout';
import ResetButton from '../component/ResetButton';
import ReactHtmlParser from 'react-html-parser';

interface EmergencyLinkInterface {
  customerAction: CustomerActionInterface | null;
  setCustomerAction: (data: CustomerActionInterface) => void;
  nowTime: Date;
  emergencyData: RegisterCar[];
}

const EmergencyLinkCard = ({
  customerAction,
  nowTime,
  setCustomerAction,
  emergencyData,
}: EmergencyLinkInterface) => {
  const emergency =
    emergencyData.find((item) => item.isRepresentative) ?? emergencyData[0];
  return (
    <div className='w-full flex flex-col justify-start items-start'>
      <AskCardLayout
        chatIcon='/assets/images/v2/chat-cargipsa-icon.svg'
        chatName='카집사'
        time={nowTime}
        title={`${emergency?.insuranceCompany?.name ?? '보험이름 없음'} 긴급출동으로 연결해 드리겠습니다.`}
      >
        <div className='w-full flex flex-col'>
          <div className='bg-[#F6F6FC] p-[12px] rounded-[10px] mb-[14px] mt-[14px]'>
            <div className='flex flex-row justify-start items-center gap-4 mb-4'>
              <div className='shrink-0'>
                <img
                  src={emergency?.insuranceCompany?.logo_url}
                  alt='보험로고'
                />
              </div>
              <div className='w-full'>
                <h5 className='text-[15px] font-[500] leading-[15px] text-[#111111] mb-[6px]'>
                  {emergency?.insuranceCompany?.name ?? '보험이름 없음'}
                </h5>
                <p className='text-[#666666] text-[12px] leading-[12px]'>
                  {emergency?.insuranceCompany?.direct.title
                    ? ReactHtmlParser(emergency.insuranceCompany.direct.title)
                    : '365일 24시간 서비스 운영'}
                </p>
              </div>
            </div>
            <div
              className='w-full py-[14px] px-4 flex justify-center items-center relative bg-[#DEE5FB] p-[10px] rounded-[6px] gap-[10px]'
              onClick={() => {
                window.location.href = `tel:${emergency?.insuranceCompany?.direct.emergency_contact}`;
              }}
            >
              <span className='text-[#3A5ECB] font-[600] text-[14px] leading-[20px]'>
                전화하기
              </span>
            </div>
          </div>
          <ResetButton
            clickEvent={() => {
              setCustomerAction({
                id: 'reset',
                textShow: false,
                text: '처음으로',
                actionReturn: 'reset',
                time: new Date(),
              });
            }}
          />
        </div>
      </AskCardLayout>
    </div>
  );
};

export default EmergencyLinkCard;
