import React, { useState } from 'react';
import { CarsayoToast } from 'components/common/v2/CarsayoToast';
import ChoiceConfirm from './ChoiceConfirm';
import { useNavigate } from 'react-router-dom';

const SearchListDataCardLayout = ({ type }: { type?: string }) => {
  const navigate = useNavigate();
  const [isCallBoxOpen, setCallBoxOpen] = useState(false);
  return (
    <>
      <div
        className='w-full border border-[#E5E5EC] rounded-[10px]'
        onClick={() =>
          type === 'repair'
            ? navigate('/ask/repair/detail/1')
            : navigate('/ask/hospital/detail/1')
        }
      >
        <div className='w-full relative overflow-hidden rounded-t-[10px]'>
          <img
            src={
              type === 'repair'
                ? '/assets/images/png/repair-ex-img01.png'
                : '/assets/images/png/hospital-ex-img01.png'
            }
            alt='공업사 예시 이미지'
            className='w-full h-full min-h-[160px] max-h-[250px] object-cover'
          />
          {type && type === 'repair' ? (
            <ul className='absolute bottom-[10px] left-[10px] flex justify-start items-center gap-[4px]'>
              <li className='inline-flex min-w-[60px] justify-center items-center gap-[4px] bg-[#00000080] py-[4px] px-[6px] border-[0.5px] border-[#E5E5EC33] rounded-[6px]'>
                <strong className='block w-[8px] h-[8px] bg-[#5DDF72] rounded-full'></strong>
                <span className='text-[13px] leading-[13px] font-[500] text-[#fff]'>
                  영업중
                </span>
              </li>
              {/* <li className='inline-flex min-w-[60px] justify-center items-center gap-[4px] bg-[#00000080] py-[4px] px-[6px] border-[0.5px] border-[#E5E5EC33] rounded-[6px]'>
                <strong className='block w-[8px] h-[8px] bg-[#CCCCCC] rounded-full'></strong>
                <span className='text-[13px] leading-[13px] font-[500] text-[#fff]'>
                  영업마감
                </span>
              </li> */}
              <li className='inline-flex min-w-[60px] justify-center items-center gap-[4px] bg-[#00000080] py-[4px] pr-[6px] pl-[4px] border-[0.5px] border-[#E5E5EC33] rounded-[6px]'>
                <strong className='block w-[10px] h-[10px]'>
                  <img
                    src='/assets/images/v2/tick-circle.svg'
                    alt='체크아이콘'
                    className='w-full object-contain'
                  />
                </strong>
                <span className='text-[13px] leading-[13px] font-[500] text-[#fff]'>
                  견인가능
                </span>
              </li>
              {/* <li className='inline-flex min-w-[60px] justify-center items-center gap-[2px] bg-[#00000080] py-[4px] pr-[6px] pl-[4px] border-[0.5px] border-[#E5E5EC33] rounded-[6px]'>
                <strong className='block w-[14px] h-[14px]'>
                  <img
                    src='/assets/images/v2/close-circle.svg'
                    alt='닫기아이콘'
                    className='w-full object-contain'
                  />
                </strong>
                <span className='text-[13px] leading-[13px] font-[500] text-[#fff]'>
                  견인불가
                </span>
              </li> */}
            </ul>
          ) : (
            <>
              <div className='absolute top-[10px] right-[10px] inline-flex min-w-[60px] justify-center items-center gap-[4px] bg-white py-[4px] px-[6px] border-[0.5px] border-[#E5E5EC] rounded-[100px]'>
                <strong className='block w-[8px] h-[8px] bg-[#5DDF72] rounded-full'></strong>
                <span className='text-[13px] leading-[13px] font-[500] text-[#555555]'>
                  진료중
                </span>
              </div>
              {/* <div className='absolute top-[10px] right-[10px] inline-flex min-w-[60px] justify-center items-center gap-[4px] bg-white py-[4px] px-[6px] border-[0.5px] border-[#E5E5EC] rounded-[100px]'>
                <strong className='block w-[8px] h-[8px] bg-[#CCCCCC] rounded-full'></strong>
                <span className='text-[13px] leading-[13px] font-[500] text-[#999999]'>
                  진료마감
                </span>
              </div> */}
            </>
          )}
        </div>
        <div className='p-4'>
          <h4 className='text-[16px] leading-[16px] font-[500] mb-[10px] whitespace-nowrap text-ellipsis overflow-hidden'>
            {type === 'repair' ? '카센터 정비소' : '광덕안정한방병원'}
          </h4>
          <p className='text-[#555555] text-[13px] leading-[13px] mb-[12px]'>
            서울시 강남구 강남대로 123-2 (영동대로)
          </p>
          <ul className='flex flex-wrap justify-start items-center gap-[6px]'>
            <li className='flex justify-center items-center gap-[4px] py-[4px] px-[6px] bg-[#F6F6FC] rounded-[6px]'>
              <img src='/assets/images/v2/location.svg' alt='위치표시아이콘' />
              <span className='text-[13px] leading-[13px] font-[500]'>6km</span>
            </li>
            <li className='flex justify-center items-center gap-[4px] py-[4px] px-[6px] bg-[#F6F6FC] rounded-[6px]'>
              <img
                src='/assets/images/v2/clock-gray.svg'
                alt='회색시계아이콘'
              />
              <span className='text-[13px] leading-[13px] font-[500]'>
                10:00~21:00
              </span>
            </li>
          </ul>
        </div>
        <button
          className='bg-[#F6F6FC] w-[calc(100%-32px)] mx-auto flex justify-center items-center gap-[6px] rounded-[6px] py-4 px-[10px] mb-4'
          onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
            e.stopPropagation();
            setCallBoxOpen(!isCallBoxOpen);
          }}
        >
          <img
            src='/assets/images/v2/call-blue.svg'
            alt='전화아이콘'
            className='w-4 object-contain'
          />
          <span className='text-[16px] font-[500] leading-[16px]'>
            02-584-0010
          </span>
        </button>
      </div>
      {/* 전화문의 Confirm */}
      <ChoiceConfirm
        isOpen={isCallBoxOpen}
        setIsOpen={() => setCallBoxOpen(!isCallBoxOpen)}
        onConfirm={() => {}}
        title={'02-584-0010'}
        isChoice={true}
        cancelText='취소'
        align='left'
        choiceList={[
          {
            text: '전화걸기',
            action: () => {
              window.location.href = `tel:025840010`;
            },
          },
          {
            text: '전화번호 복사하기',
            action: () => {
              navigator.clipboard
                .writeText('025840010')
                .then(() => {
                  CarsayoToast.success('복사했습니다.');
                })
                .catch((err) => {
                  console.error('Failed to copy: ', err);
                });
            },
          },
        ]}
      />
    </>
  );
};

export default SearchListDataCardLayout;
