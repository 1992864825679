import { create } from 'zustand';
import { devtools, persist } from 'zustand/middleware';
import { immer } from 'zustand/middleware/immer';

export interface InsuranceDataStore {
  carNumber: string;
  company: string | null;
  subscriptionDate: Date;
}
export interface InsuranceDataAction {
  setInsuranceData: (data: InsuranceDataStore) => void;
  setCarNumber: (num: string) => void;
  setCompany: (com: string | null) => void;
  setSubscriptionDate: (subDay: Date) => void;
  resetInsuranceData: () => void;
}
const useInsuranceDataStore = create<
  InsuranceDataStore & InsuranceDataAction
>()(
  devtools(
    persist(
      immer((set, get) => ({
        carNumber: '',
        company: null,
        subscriptionDate: new Date(),
        resetInsuranceData: () => {
          set((state) => {
            state.carNumber = '';
            state.company = null;
            state.subscriptionDate = new Date();
          });
        },
        setInsuranceData: (data: InsuranceDataStore) => {
          set((state) => {
            state.carNumber = data.carNumber;
            state.company = data.company;
            state.subscriptionDate = data.subscriptionDate;
          });
        },
        setCarNumber: (num: string) => {
          set((state) => {
            state.carNumber = num;
          });
        },
        setCompany: (com: string | null) => {
          set((state) => {
            state.company = com;
          });
        },
        setSubscriptionDate: (subDate: Date) => {
          set((state) => {
            state.subscriptionDate = subDate;
          });
        },
      })),
      {
        name: 'insuranceDataStore',
      },
    ),
  ),
);

export default useInsuranceDataStore;
