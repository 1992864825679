import { ChevronRight } from 'lucide-react';
import { useLayoutEffect } from 'react';
import { Link } from 'react-router-dom';
import useMemberStore from 'store/useMemberStore';
import useSystemStore from 'store/useSystemStore';

export default function TermList() {
  const { setTopTabbarState } = useSystemStore();
  const { loginMember } = useMemberStore();

  useLayoutEffect(() => {
    setTopTabbarState({
      state: 'fixed',
      mode: 'subpage',
      title: '약관 / 정책',
      rightElement: 'home',
      leftElement: 'back',
    });
  }, []);

  return (
    <div className='px-4 pb-10'>
      <Link
        to={
          loginMember?.type === 'customer'
            ? '/mypage/termDetail/10100'
            : '/mypage/termDetail/20100'
        }
        className='flex h-14 items-center border-b'
      >
        <div className='text-base font-medium'>서비스 이용약관</div>
        <ChevronRight className='ml-auto' strokeWidth='1.5' />
      </Link>
      <Link
        to={
          loginMember?.type === 'customer'
            ? '/mypage/termDetail/10200'
            : '/mypage/termDetail/20200'
        }
        className='flex h-14 items-center border-b'
      >
        <div className='text-base font-medium'>개인정보 처리방침</div>
        <ChevronRight className='ml-auto' strokeWidth='1.5' />
      </Link>
      {process.env.REACT_APP_NODE_ENV !== 'production' && (
        <Link
          to={'/mypage/termDetail/94100'}
          className='flex h-14 items-center border-b'
        >
          <div className='text-base font-medium'>위치기반서비스 이용약관</div>
          <ChevronRight className='ml-auto' strokeWidth='1.5' />
        </Link>
      )}
      <Link
        to={'/mypage/termFinanceConsumerCertificate'}
        className='flex h-14 items-center border-b'
      >
        <div className='text-base font-medium'>금융소비자보호법 등록증</div>
        <ChevronRight className='ml-auto' strokeWidth='1.5' />
      </Link>
      {loginMember?.type === 'dealer' && (
        <Link
          to={'/mypage/termDetail/warning'}
          className='flex h-14 items-center border-b'
        >
          <div className='text-base font-medium'>경고 및 계정 정지 정책</div>
          <ChevronRight className='ml-auto' strokeWidth='1.5' />
        </Link>
      )}
    </div>
  );
}
