import { AccidentRegistrationCreateDTO } from '@carsayo/types';
import { CustomerActionInterface } from '../../ask';
import AskCardLayout from '../../component/AskCardLayout';
import ResetButton from '../../component/ResetButton';
import dayjs from 'dayjs';

interface AccidentCompleteInterface {
  customerAction: CustomerActionInterface | null;
  setCustomerAction: (data: CustomerActionInterface) => void;
  data: AccidentRegistrationCreateDTO;
  time: Date;
}

const AccidentCompleteCard = ({
  customerAction,
  setCustomerAction,
  data,
  time,
}: AccidentCompleteInterface) => {
  return (
    <div>
      <AskCardLayout
        chatIcon='/assets/images/v2/chat-cargipsa-icon.svg'
        chatName='카집사'
        time={new Date()}
        title='사고 접수가 완료되었습니다.'
      >
        <div className='w-full flex flex-col'>
          <p className='mb-[14px] text-[15px] leading-[22.5px] text-[#555555] flex flex-col justify-start items-start'>
            <span>답변이 완료되면 알림을 통해 안내해 드리며, </span>
            <span>
              <strong className='text-[#3156C6] font-[700]'>
                마이페이지 &gt; 고객지원 &gt; 사고접수
              </strong>
              에서
            </span>
            <span>확인 가능합니다.</span>
          </p>
          <div className=' bg-[#DBE4FE] rounded-[10px] mb-[14px] min-h-[160px]'>
            <div className='py-[20px] px-[12px] relative min-h-[160px]'>
              <img
                src='/assets/images/v2/ask-carGipsa-accident02.svg'
                alt='사고접수완료이미지'
              />
              <img
                src='/assets/images/v2/ask-character-complete02.svg'
                alt='카집사 인사 이미지'
                className='absolute right-4 bottom-0'
              />
            </div>
            <div className='bg-[#F6F6FC] py-4 px-[12px] rounded-b-[10px]'>
              <ul className='mb-[8px]'>
                <li className='text-[15px] leading-[22.5px] font-[600] text-[#111]'>
                  이름 : {data.name_real ?? '이름없음'}
                </li>
                <li className='text-[15px] leading-[22.5px] font-[600] text-[#111]'>
                  차량번호 : {data.carName ?? '차량번호 없음'}
                </li>
              </ul>
              <p className='flex flex-col justify-start items-start text-[#555555] text-[13px] leading-[16.9px] mb-4'>
                <span>
                  {`${
                    time
                      ? dayjs(time).format('YYYY년 MM월 DD일')
                      : dayjs().format('YYYY년 MM월 DD일')
                  }(${time ? dayjs(time).format('dd') : dayjs().format('dd')}) 
                ${
                  time
                    ? dayjs(time).format('HH시 mm분')
                    : dayjs().format('HH시 mm분')
                }에 접수된`}
                </span>
                <span>요청사항에 맞추어 공업사 배정 및 진행 상황을</span>
                <span>확인할 수 있습니다.</span>
              </p>
              <button className='w-full p-[14px] flex justify-center items-center bg-[#DEE5FB] rounded-[6px] text-[#3A5ECB] leading-[20px] font-[600]'>
                접수 확인하기
              </button>
            </div>
          </div>

          <ResetButton
            clickEvent={() => {
              setCustomerAction({
                id: 'reset',
                textShow: false,
                text: '처음으로',
                actionReturn: 'reset',
                time: new Date(),
              });
            }}
          />
        </div>
      </AskCardLayout>
    </div>
  );
};

export default AccidentCompleteCard;
