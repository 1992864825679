/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { motion } from 'framer-motion';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import useSystemStore from 'store/useSystemStore';

import CommunityMenuIcon from './svg/CommunityMenuIcon';
import EventMenuIcon from './svg/EventMenuIcon';
import HomeMenuIcon from './svg/HomeMenuIcon';
import MyMenuIcon from './svg/MyMenuIcon';
import ApplicationMenuIcon from './svg/ApplicationMenuIcon';
import { height } from '@mui/system';
import useMemberStore from 'store/useMemberStore';
import { classNames } from 'primereact/utils';
import BuyNewCarIcon from './svg/BuyNewCarIcon';
import ChatIcon from './svg/ChatMenuIcon';
// import BottomBg from '../../../assets/images/v2/bottomBarBg.svg';

const BottomTabbar = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const { isBottomTabbar } = useSystemStore();
  const { loginMember } = useMemberStore();

  const handleClickEvents = {
    moveToHomePage: () => {
      navigate('/main');
    },
    moveToQuotePage: () => {
      if (!loginMember) {
        navigate('/auth/signup', { replace: true });
        return;
      } else {
        if (loginMember.type === 'customer') {
          navigate('/quote/customer');
        } else {
          navigate('/quote/manager');
        }
      }
    },
    moveToMypage: () => {
      if (loginMember) {
        navigate('/mypage');
      } else {
        navigate('/auth/signup', { replace: true });
      }
    },
  };

  return (
    <>
      {isBottomTabbar && (
        <div className='fixed bottom-0 left-0 w-full z-[40]'>
          <ul className='w-full flex h-[58px] items-end bg-bottom-bar bg-no-repeat bg-contain bg-top flex-none bg-transparent'>
            <li className='w-[19%] pt-[9px] pb-[7px] box-border bg-[#fff] h-[58px]'>
              <Link
                to={'/main'}
                className='w-full h-full flex flex-col gap-[4.21px] items-center '
              >
                <HomeMenuIcon />
                <span
                  className={`w-full text-center text-[11px] leading-[16px] ${location.pathname === '/main' ? 'font-[600] text-[#7273F9]' : 'text-[#555555]'}`}
                >
                  홈
                </span>
              </Link>
            </li>
            <li className='w-[19%] pt-[9px] pb-[7px] box-border bg-[#fff] h-[58px]'>
              {/* <Link
                to={'/community'}
                className={`w-full h-full flex flex-col gap-[4.21px] items-center`}
              >
                <CommunityMenuIcon width='20' height='20' />
                <span
                  className={`w-full text-center text-[11px] leading-[16px] ${location.pathname.includes('/community') ? 'font-[600] text-[#7273F9]' : 'text-[#555555]'}`}
                >
                  커뮤니티
                </span>
              </Link> */}
              <Link
                to='/purchase/brand'
                className={`w-full h-full flex flex-col gap-[4.21px] items-center`}
              >
                <BuyNewCarIcon width='20' height='20' />
                <span
                  className={`w-full text-center text-[11px] leading-[16px] ${location.pathname.includes('/community') ? 'font-[600] text-[#7273F9]' : 'text-[#555555]'}`}
                >
                  신차 구매
                </span>
              </Link>
            </li>
            <li className={classNames('w-[24%] grow relative h-[58px]')}>
              <div
                className='absolute top-[-28px] left-0 right-0 mx-auto w-[52px] h-[52px] rounded-full flex flex-col gap-[2px] justify-center items-center bg-[linear-gradient(180deg,rgb(114,115,249)_0%,rgb(113,9,216)_100%)] shadow-[0px_10px_20px_#9701da4c]'
                onClick={handleClickEvents.moveToQuotePage}
              >
                <ApplicationMenuIcon width='16' height='16' />
                <span
                  className={`w-full text-center text-[11px] leading-[16px] font-[400] text-[#fff]`}
                >
                  견적함
                </span>
              </div>
              <div
                className='bg-white w-full h-2 fixed'
                style={{
                  bottom: 'env(safe-area-inset-bottom, 0)',
                }}
              ></div>
            </li>
            <li className='w-[19%] pt-[9px] pb-[7px] box-border bg-[#fff] h-[58px]'>
              {process.env.REACT_APP_NODE_ENV === 'production' ? (
                <Link
                  to={'/event'}
                  preventScrollReset={true}
                  className='w-full h-full flex flex-col gap-[4.21px] items-center '
                >
                  <EventMenuIcon width='20' height='20' />
                  <span
                    className={`w-full text-center text-[11px] leading-[16px] text-[#555555] ${location.pathname.includes('/event') ? 'font-[600] text-[#7273F9]' : 'text-[#555555]'}`}
                  >
                    이벤트
                  </span>
                </Link>
              ) : (
                <Link
                  to={'/ask/main'}
                  preventScrollReset={true}
                  className='w-full h-full flex flex-col gap-[4.21px] items-center '
                >
                  {/* <EventMenuIcon width='20' height='20' /> */}
                  {/* <ChatIcon width='20' height='20' /> */}
                  <img
                    src='/assets/images/png/bottom-icon-chat.png'
                    alt='채팅아이콘'
                    className='w-[20xp] h-[20px] object-contain'
                  />
                  <span
                    className={`w-full text-center text-[11px] leading-[16px] text-[#555555] ${location.pathname.includes('/ask') ? 'font-[600] text-[#7273F9]' : 'text-[#555555]'}`}
                  >
                    물어보기
                  </span>
                </Link>
              )}
            </li>
            <li className='w-[19%] pt-[9px] pb-[7px] box-border bg-[#fff] h-[58px]'>
              <div
                onClick={handleClickEvents.moveToMypage}
                className='w-full h-full flex flex-col gap-[4.21px] items-center'
              >
                <MyMenuIcon width='18' height='20' />
                <span
                  className={`w-full text-center text-[11px] leading-[16px] ${location.pathname.includes('/mypage') ? 'font-[600] text-[#7273F9]' : 'text-[#555555]'}`}
                >
                  MY
                </span>
              </div>
            </li>
          </ul>
          <div
            className='bg-white flex-none'
            style={{
              height: 'env(safe-area-inset-bottom, 0)',
            }}
          ></div>
        </div>
      )}
    </>
  );
};

export default BottomTabbar;
