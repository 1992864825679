import { Button } from 'components/ui/button';
import {
  Drawer,
  DrawerClose,
  DrawerContent,
  DrawerHeader,
  DrawerTitle,
} from 'components/ui/drawer';
import { X } from 'lucide-react';
import { useNavigate } from 'react-router-dom';

export function CloseConfirm({
  isOpen,
  setIsOpen,
}: {
  isOpen: boolean;
  setIsOpen: (value: boolean) => void;
}) {
  const navigate = useNavigate();

  return (
    <Drawer open={isOpen} onOpenChange={(open) => setIsOpen(open)}>
      <DrawerContent className='mx-auto flex max-h-[90vh] w-full max-w-sm flex-col'>
        <DrawerHeader className='relative'>
          <DrawerTitle className='text-left text-lg font-semibold'>
            진행중인 상담을 중단하시겠습니까?
          </DrawerTitle>
          <DrawerClose asChild>
            <Button className='absolute right-5 top-4 h-7 w-7 border-0 bg-transparent p-0 text-[#111111]'>
              <X className='h-7 w-7' />
            </Button>
          </DrawerClose>
        </DrawerHeader>
        <div className='p-4 pb-8'>
          <div className='text-title'>
            상담을 중단하더라도 내용은 저장되며, 언제든지 희망 하시는 시간에
            이어서 작성 가능합니다.
          </div>
          <div className='mt-6'>
            <img src='/assets/images/v2/closeBaner.png' alt='' />
          </div>
          <div className='mt-6 grid grid-cols-2 bg-[#F7F7FB]'>
            <Button
              variant='outline'
              className='rounded-r-none bg-white'
              onClick={() => {
                navigate(-1);
              }}
            >
              종료하기
            </Button>
            <Button className='rounded-l-none' onClick={() => setIsOpen(false)}>
              계속하기
            </Button>
          </div>
        </div>
      </DrawerContent>
    </Drawer>
  );
}
