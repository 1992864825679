interface ResetButtonInterface {
  clickEvent: () => void;
}

const ResetButton = ({ clickEvent }: ResetButtonInterface) => {
  return (
    <button
      onClick={clickEvent}
      className='self-end flex justify-center items-center gap-[2px] py-[9px] px-4 rounded-[100px] bg-[#E5E5EC]'
    >
      <img src='/assets/images/v2/undo.svg' alt='리셋아이콘' />
      <span>처음으로</span>
    </button>
  );
};

export default ResetButton;
