import { OrderMemo } from '@carsayo/types';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { getDealerOrderMemoList } from 'apis/quote';

export const useDealerOrderMemo = ({ orderId }: { orderId: string }) => {
  return useQuery({
    queryKey: ['dealerOrderMemo', orderId],
    queryFn: async () => {
      const order: OrderMemo[] = await getDealerOrderMemoList(orderId);
      return order;
    },
    placeholderData: (previousData, previousQuery) => previousData,
    gcTime: 5 * 60 * 1000, // 5 min
    staleTime: 30 * 1000, // 30 sec
  });
};

/** Hook */
export const useUpdateDealerOrderMemo = ({ orderId }: { orderId: string }) => {
  const queryClient = useQueryClient();

  const updateDealerOrderMemo = ({
    orderId,
    data,
  }: {
    orderId: string;
    data: OrderMemo[];
  }) => {
    queryClient.setQueryData(['dealerOrderMemo', orderId], () => {
      return data;
    });
    queryClient.invalidateQueries({ queryKey: ['dealerOrderMemo', orderId] });
  };
  return updateDealerOrderMemo;
};
