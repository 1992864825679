/* eslint-disable @typescript-eslint/no-unused-vars */
import { Button } from 'components/ui/button';
import { Switch } from 'components/ui/switch';
import { cn } from 'utils';
import { ChevronRight } from 'lucide-react';
import { Link, useNavigate } from 'react-router-dom';
import useSystemStore from 'store/useSystemStore';
import { ChangeEvent, useLayoutEffect, useRef, useState } from 'react';
import useMemberStore from 'store/useMemberStore';
import {
  parseStringPhoneNumber,
  addDotToYYYYMMDD,
  parseDealerType,
} from 'utils/textConvert';
import dayjs from 'dayjs';
import {
  updateMessageReceiveSetting,
  updateProfileImage,
  updateTerm,
} from 'apis/setting';
import { CarsayoImageCropper } from 'components/common/v2/CarsayoImageCropper';
import { uploadFileToS33 } from 'apis/authentication';
import { CarsayoToast } from 'components/common/v2/CarsayoToast';
import useDeviceStore from 'store/useDeviceStore';
import EditIcon from 'assets/images/v2/edit.svg';
import DiscriptionEditor from '../mainPage/components/discriptionChanger';
import ReactHtmlParser from 'react-html-parser';
import getTermToastMessage from 'utils/getTermToastMessage';
import useUpdateMarketingAlert from 'hooks/useUpdateMarketingAlert';

const Status = {
  pending: {
    status: '승인대기',
    color: 'border-[#111111] text-[#111111]',
  },
  success: {
    status: '승인완료',
    color: 'border-[#107700] text-[#107700]',
  },
  failure: {
    status: '승인거부',
    color: 'border-[#DC0000] text-[#DC0000]',
  },
};

export default function MyInfo() {
  const navigate = useNavigate();

  const { setTopTabbarState, setLoadingDim } = useSystemStore();
  const { loginMember, setLoginMember } = useMemberStore();
  const { isAndroid } = useDeviceStore();

  const { updateMarketingAlert } = useUpdateMarketingAlert();

  const [isCropperOpen, setIsCropperOpen] = useState(false);
  const [imageFile, setImageFile] = useState<File | undefined>(undefined);

  const [isDiscriptionEditorOpen, setIsDiscriptionEditorOpen] = useState(false);

  const fileInputRef = useRef<HTMLInputElement>(null);

  useLayoutEffect(() => {
    setTopTabbarState({
      state: 'fixed',
      mode: 'subpage',
      title: '내 정보',
      rightElement: 'home',
      leftElement: 'back',
    });
  }, []);

  return (
    <div className='bg-[#F1F1F5]'>
      <CarsayoImageCropper
        isOpen={isCropperOpen}
        setIsOpen={setIsCropperOpen}
        imageFile={imageFile}
        title='프로필 사진 설정'
        buttonText='확인'
        onCrop={async (file: File) => {
          if (!imageFile) return;
          setLoadingDim(true);

          try {
            const fileUploadData = await uploadFileToS33({
              file: file,
              fileType: 'profileImage',
              isPublic: true,
            });

            setLoginMember(
              await updateProfileImage({
                fileId: fileUploadData.fileId,
              }),
            );

            setLoadingDim(false);
          } catch (e) {
            CarsayoToast.error(`${e}`);
            setLoadingDim(false);
          }

          if (fileInputRef?.current) fileInputRef.current.value = '';
          setLoadingDim(false);
          setImageFile(undefined);
        }}
      />

      <DiscriptionEditor
        isOpen={isDiscriptionEditorOpen}
        setIsOpen={setIsDiscriptionEditorOpen}
      ></DiscriptionEditor>

      <div className='bg-white p-4'>
        <div className='text-lg font-semibold'>기본 정보</div>
        <div className='mt-4 p-4'>
          <div className='mx-auto flex h-20 w-20 items-center justify-center overflow-hidden rounded-full'>
            <img
              className='w-full h-full'
              src={loginMember?.profileImage_url}
              alt=''
            />
          </div>
          <div className='mt-4 flex'>
            <input
              id='file'
              type='file'
              accept='image/png, image/jpg, image/jpeg'
              capture={isAndroid ? true : false}
              ref={fileInputRef}
              className='absolute w-0 hidden'
              onChange={(data: ChangeEvent<HTMLInputElement>) => {
                const file = data.target.files && data.target.files[0];
                if (!file) return;

                setImageFile(file);
                setIsCropperOpen(true);
              }}
            />
            <Button
              variant='outline'
              className='mx-auto h-9 w-[125px] rounded border-[#111111] px-3 text-desc font-normal'
            >
              <label htmlFor='file' className='flex gap-0.5'>
                <img src='/assets/images/v2/camera.svg' alt='' />
                프로필 사진 수정
              </label>
            </Button>
          </div>
        </div>
        <div className='mt-4 border-t pt-4'>
          {loginMember?.name_real && (
            <div className='flex h-9 items-center'>
              <div className='w-[100px] shrink-0'>이름</div>
              <div className='flex-1 text-[#767676]'>
                {loginMember.name_real}
              </div>
            </div>
          )}
          {loginMember?.name_nick && (
            <div className='flex h-9 items-center'>
              <div className='w-[100px] shrink-0'>닉네임</div>
              <div className='flex-1 text-[#767676]'>
                {loginMember.name_nick}
              </div>
            </div>
          )}
          {loginMember?.birth && (
            <div className='flex h-9 items-center'>
              <div className='w-[100px] shrink-0'>생년월일</div>
              <div className='flex-1 text-[#767676]'>
                {addDotToYYYYMMDD(loginMember.birth)}
              </div>
            </div>
          )}
          {loginMember?.gender && (
            <div className='flex h-9 items-center'>
              <div className='w-[100px] shrink-0'>성별</div>
              <div className='flex-1 text-[#767676]'>
                {loginMember.gender === 'male' ? '남성' : '여성'}
              </div>
            </div>
          )}
          {loginMember?.phoneNumber && (
            <div className='flex h-9 items-center'>
              <div className='w-[100px] shrink-0'>휴대폰번호</div>
              <div className='flex-1 text-[#767676]'>
                {parseStringPhoneNumber(loginMember.phoneNumber)}
              </div>
            </div>
          )}
          {loginMember?.email && (
            <div className='flex h-9 items-center'>
              <div className='w-[100px] shrink-0'>이메일</div>
              <div className='flex-1 text-[#767676]'>{loginMember.email}</div>
            </div>
          )}
          <div className='flex my-2 items-baseline'>
            <div
              onClick={() => {
                setIsDiscriptionEditorOpen(true);
              }}
              className='flex items-center gap-0.5 w-[100px] shrink-0 underline underline-offset-1'
            >
              자기소개
              <img src={EditIcon} alt='' className='object-contain h-4' />
            </div>
            <div className='flex-1 text-[#767676] whitespace-pre-wrap'>
              {loginMember?.description
                ? ReactHtmlParser(loginMember.description)
                : '미설정'}
            </div>
          </div>
          <div className='flex h-9 justify-end'>
            <Link className='flex items-center' to='/mypage/signout'>
              회원 탈퇴
              <ChevronRight
                className='h-4 w-4 gtext-[#767676]'
                strokeWidth='1'
              />
            </Link>
          </div>
        </div>
        {loginMember?.type === 'dealer' && loginMember?.dealerInfo && (
          <>
            <div className='mt-2 border-t bg-white pt-6'>
              <div className='text-lg font-semibold'>소속정보</div>
              <div className='mt-4'>
                <div className='flex h-9 items-center'>
                  <div className='w-[100px] shrink-0'>유형</div>
                  <div className='flex-1 text-[#767676]'>
                    {parseDealerType(loginMember.dealerInfo.type)}
                  </div>
                </div>
                {loginMember.region && (
                  <div className='flex h-9 items-center'>
                    <div className='w-[100px] shrink-0'>근무지역</div>
                    <div className='flex-1 text-[#767676]'>
                      {`${loginMember.region.sido} ${loginMember.region.sigungu}`}
                      {/* {loginMember.region.sigunguId
                        ? `${parseSigunguId(loginMember.region.sigunguId)?.sido.name} ${parseSigunguId(loginMember.region.sigunguId)?.name}`
                        : '없음'} */}
                    </div>
                  </div>
                )}
                {/* <div className='flex h-9 items-center'>
                  <div className='w-[100px] shrink-0'>구분</div>
                  <div className='flex-1 text-[#767676]'>
                    {loginMember.dealerInfo.isCarsayoEmployee ? '내부' : '외부'}
                  </div>
                </div> */}
                {loginMember.dealerInfo.organization && (
                  <div className='flex h-9 items-center'>
                    <div className='w-[100px] shrink-0'>소속</div>
                    <div className='flex-1 text-[#767676]'>
                      {loginMember.dealerInfo.organization}
                    </div>
                  </div>
                )}
                {loginMember.dealerInfo.experienceYears && (
                  <div className='flex h-9 items-center'>
                    <div className='w-[100px] shrink-0'>경력</div>
                    <div className='flex-1 text-[#767676]'>
                      {loginMember.dealerInfo.experienceYears}년
                    </div>
                  </div>
                )}

                {loginMember.dealerInfo.type === 'leaseRent' && (
                  <div className='flex h-9 items-center'>
                    <div className='w-[100px] shrink-0'>취급유형</div>
                    <div className='flex-1 text-[#767676]'>
                      {loginMember.dealerInfo.isLease &&
                      loginMember.dealerInfo.isRent
                        ? '리스 / 장기렌트'
                        : loginMember.dealerInfo.isLease
                          ? '리스'
                          : loginMember.dealerInfo.isRent
                            ? '장기렌트'
                            : '미설정'}
                    </div>
                  </div>
                )}

                {loginMember.dealerInfo.tradingCertificate_term && (
                  <div className='flex h-9 items-center'>
                    <div className='w-[100px] shrink-0'>매매사업 기간</div>
                    <div className='flex-1 text-[#767676]'>
                      {addDotToYYYYMMDD(
                        loginMember.dealerInfo.tradingCertificate_term.start,
                      )}{' '}
                      ~{' '}
                      {addDotToYYYYMMDD(
                        loginMember.dealerInfo.tradingCertificate_term.finish,
                      )}
                    </div>
                  </div>
                )}
              </div>
            </div>

            <div className='mb-2 mt-6 bg-[#F7F7FB] p-4'>
              <div className='flex'>
                {loginMember.dealerInfo.signupState === 'accepted' && (
                  <img
                    className='px-2'
                    src='/assets/images/v2/authManager.svg'
                    alt=''
                  />
                )}
                <div className='font-semibold'>
                  카 매니저 승인
                  {loginMember.dealerInfo.signupState !== 'accepted' && '상태'}
                </div>
              </div>

              <Link
                to={
                  loginMember.dealerInfo.signupState === 'accepted'
                    ? ''
                    : '/mypage/dealerSignupForm'
                }
                className={cn(
                  'mx-auto mt-6 block w-[182px] space-y-2.5 border bg-white p-4 text-center',
                  loginMember.dealerInfo.signupState === 'accepted'
                    ? Status.success.color
                    : loginMember.dealerInfo.signupState === 'submitted'
                      ? Status.pending.color
                      : Status.failure.color,
                )}
              >
                <div className='font-semibold'>
                  {loginMember.dealerInfo.signupState === 'accepted'
                    ? Status.success.status
                    : loginMember.dealerInfo.signupState === 'submitted'
                      ? Status.pending.status
                      : Status.failure.status}
                </div>

                {loginMember.dealerInfo.signupState === 'accepted' &&
                  loginMember.dealerInfo.accepted_at && (
                    <div className='text-desc text-[#555555]'>
                      {dayjs(loginMember.dealerInfo.accepted_at).format(
                        'YYYY.MM.DD (HH:mm)',
                      )}
                    </div>
                  )}
                {loginMember.dealerInfo.signupState === 'submitted' && (
                  <div className='text-desc text-[#555555]'>
                    {dayjs(loginMember.dealerInfo.submitted_at).format(
                      'YYYY.MM.DD (HH:mm)',
                    )}
                  </div>
                )}
                {loginMember.dealerInfo.signupState === 'rejected' &&
                  loginMember.dealerInfo.rejected_at && (
                    <div className='text-desc text-[#555555]'>
                      {dayjs(loginMember.dealerInfo.rejected_at).format(
                        'YYYY.MM.DD (HH:mm)',
                      )}
                    </div>
                  )}

                {loginMember.dealerInfo.signupState === 'rejected' && (
                  <div className='text-desc text-[#111111]'>거부 사유 보기</div>
                )}
              </Link>
            </div>
          </>
        )}
      </div>
      <div className='mt-4 space-y-4 bg-white p-4 pb-10'>
        <div className='h-9 font-semibold leading-9'>
          광고 · 마케팅 정보 수신
        </div>
        <div className='flex h-9 items-center justify-between'>
          <div
            className='underline'
            onClick={() => {
              navigate('/mypage/termDetail/95100');
            }}
          >
            앱 푸시
          </div>
          <Switch
            checked={loginMember?.setting.marketing_push}
            onClick={async () => {
              await updateMarketingAlert({
                target: 'push',
              });

              return;
            }}
          />
        </div>
        <div className='flex h-9 items-center justify-between'>
          <div
            className='underline'
            onClick={() => {
              navigate('/mypage/termDetail/95200');
            }}
          >
            문자 메시지
          </div>
          <Switch
            checked={loginMember?.setting.marketing_sms}
            onClick={async () => {
              await updateMarketingAlert({
                target: 'sms',
              });
              return;
            }}
          />
        </div>
        <div className='flex h-9 items-center justify-between'>
          <div
            className='underline'
            onClick={() => {
              navigate('/mypage/termDetail/95300');
            }}
          >
            이메일
          </div>
          <Switch
            checked={loginMember?.setting.marketing_email}
            onClick={async () => {
              await updateMarketingAlert({
                target: 'email',
              });
            }}
          />
        </div>
      </div>
    </div>
  );
}
