import axios, {
  AxiosError,
  AxiosResponse,
  InternalAxiosRequestConfig,
} from 'axios';
import * as Sentry from '@sentry/react';
import { CarsayoErrorResponse } from '@carsayo/types';

export const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  withCredentials: true,
});

function getAxiosErrorMsg(url?: string): string {
  if (!url) {
    return 'Unknown error occurred';
  }
  if (url.includes('/login')) {
    return 'Failed to log in. Please check your credentials and try again.';
  } else if (url.includes('/register')) {
    return 'Failed to register. Please check the information provided.';
  } else if (url.includes('/data')) {
    return 'Data retrieval failed. Please try again later.';
  }

  return 'An unexpected error occurred while processing your request.';
}

interface ErrorResponseData {
  error?: string;
  message?: string;
  path?: string;
  statusCode?: number;
  timestamp?: string;
}

export function logAxiosError(error: AxiosError) {
  console.info('에러 확인', error);
  const errorMessage =
    '[Axios] ' + getAxiosErrorMsg(error.response?.config.url);

  const errorResponseData: ErrorResponseData = error.response?.data || {};
  Sentry.withScope((scope: Sentry.Scope) => {
    scope.setTags({
      api: error.response?.config.url,
      'api.request.method': error.config?.method?.toUpperCase(),
      'api.request.data': error.config?.data || '',
      'api.response.status': (error.response?.status || '').toString(),
      'api.response.statusText': error.response?.statusText || '',
      'api.response.data': errorResponseData.error || '',
      'api.response.message': errorResponseData.message
        ? errorResponseData.message
        : '',
    });
    scope.setContext('axiosArror', {
      method: error.config?.method?.toUpperCase(),
      path: errorResponseData.path || '',
      status: (error.response?.status || '').toString(),
      data: errorResponseData.error || '',
      message: errorResponseData.message || '',
    });
    scope.setLevel('error');
    error.message = errorMessage;
    Sentry.captureException(error, { fingerprint: [errorMessage] });
  });
}

const captureError = (error: AxiosError<CarsayoErrorResponse>) => {
  process.env.REACT_APP_NODE_ENV === 'development' && logAxiosError(error);

  return 0;
};

api.interceptors.request.use(
  (config: InternalAxiosRequestConfig) => {
    // config.headers['Cache-Control'] = 'no-cache';
    return config;
  },
  (error: AxiosError<CarsayoErrorResponse>) => {
    captureError(error);
    return Promise.reject(error);
  },
);

api.interceptors.response.use(
  (response: AxiosResponse<any, any>) => {
    return response;
  },
  (error: AxiosError<CarsayoErrorResponse>) => {
    captureError(error);
    return Promise.reject(error);
  },
);
