import Confirm from 'components/ui/confirm';

export interface ChoiceListInterface {
  text: string;
  action: () => void;
}

interface ChoiceConfirmInterface {
  isOpen: boolean;
  setIsOpen: () => void;
  onConfirm: () => void;
  title: string;
  desc?: string;
  confirmText?: string;
  cancelText?: string;
  isChoice?: boolean;
  choiceList?: ChoiceListInterface[];
  align?: 'center' | 'left';
}

// eslint-disable-next-line no-empty-pattern
const ChoiceConfirm = ({
  isOpen,
  onConfirm,
  setIsOpen,
  title,
  cancelText,
  confirmText,
  desc,
  isChoice,
  choiceList,
  align,
}: ChoiceConfirmInterface) => {
  return (
    <Confirm
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      onConfirm={onConfirm}
      title={title}
      confirmText={confirmText}
      cancelText={cancelText}
      desc={desc ?? ''}
      isChoice={isChoice}
      choiceList={choiceList}
      isCloseButton={true}
      align={align}
    ></Confirm>
  );
};
export default ChoiceConfirm;
